import { CompressService, useProfileAPI, useResizeObserver } from '@innowise-group/core';
import React from 'react';
import * as Styled from './events-progress.styles';
import { AppRoutes, RolesValues } from '@constants';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@innowise-group/mui-kit';

const EventsProgress: React.FC = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isDesktopView = useResizeObserver(theme.breakpoints.values.sm);
  const { profile, currentStatusesCount } = useProfileAPI();

  const handleNavigate = () => {
    if (isDesktopView && profile) {
      const newState = CompressService.compressObject({
        dateMin: new Date(),
        dateMax: new Date(),
        statusAuthors: [profile.id.toString()],
      });
      navigate(
        `${
          profile?.role?.name === RolesValues.Admin ? AppRoutes.AnalyticsLettersPage : AppRoutes.AnalyticsEmployeePage
        }?d=${newState}`,
      );
    }
  };

  if (!profile.requiredNumberOfEvents) return null;
  const percent =
    currentStatusesCount >= profile.requiredNumberOfEvents
      ? 100
      : (currentStatusesCount * 100) / profile.requiredNumberOfEvents;
  return (
    <Styled.Wrapper onClick={handleNavigate}>
      <Styled.CircularProgress value={percent} thickness={4} variant="determinate" />
      <Styled.CircularProgressBG value={100} thickness={4} variant="determinate" />
      <Styled.ProgressValue>{currentStatusesCount}</Styled.ProgressValue>
    </Styled.Wrapper>
  );
};

export default EventsProgress;
