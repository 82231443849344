import { AutocompleteProps } from '@mui/material';
import React, { Ref, useCallback, useMemo } from 'react';
import * as Styled from './select.styles';
import { Checkbox } from '../checkbox';
import { Divider } from '../divider';
import { useTranslation } from 'react-i18next';
import { SelectOption } from '@innowise-group/core';

export interface SelectProps
  extends Omit<AutocompleteProps<SelectOption, boolean, boolean, boolean>, 'onChange' | 'value'> {
  onChange: (value: string | SelectOption[], reason?: string) => void;
  disabledChips?: string[];
  value: string | SelectOption[];
  disableSortByAlphabet?: boolean;
  lastElementRef?: Ref<HTMLLIElement>;
  loadingAdditionalOptions?: boolean;
}

interface GroupedSelectOption extends SelectOption {
  selected: boolean;
}

const Select: React.FC<SelectProps> = ({
  onChange,
  options = [],
  disablePortal = true,
  multiple = false,
  groupBy,
  disableClearable,
  value,
  disabledChips = [],
  disableSortByAlphabet = true,
  placeholder,
  disabled,
  lastElementRef,
  loadingAdditionalOptions,
  ...props
}) => {
  const { t } = useTranslation();

  const sortedOptions = useMemo(() => {
    if (!multiple) {
      if (disableSortByAlphabet) return options;
      return [...options].sort((a, b) => a.title?.localeCompare(b.title));
    }
    if (!Array.isArray(value)) return options;
    const groupedOptions = options.reduce<{ [key: string]: GroupedSelectOption[] }>(
      (acc, curr) => {
        const selected = !!value.find((item) => item.value === curr.value);
        return selected
          ? {
              ...acc,
              selected: [...acc.selected, { ...curr, selected }],
            }
          : {
              ...acc,
              unselected: [...acc.unselected, { ...curr, selected }],
            };
      },
      { selected: [], unselected: [] },
    );
    if (disableSortByAlphabet) {
      return [...(groupedOptions.selected || []), ...(groupedOptions.unselected || [])];
    }
    return [
      ...(groupedOptions.selected?.sort((a, b) => -b.title?.localeCompare(a.title)) || []),
      ...(groupedOptions.unselected?.sort((a, b) => -b.title?.localeCompare(a.title)) || []),
    ];
  }, [options, value]);

  const handleChange = useCallback(
    (event: React.SyntheticEvent<Element, Event>, newValue, reason, details) => {
      if (multiple && Array.isArray(newValue)) {
        if (reason === 'removeOption') {
          if (disabledChips.includes(details.option.value)) {
            newValue.pop();
            onChange([...newValue, details.option]);
            return;
          }
        }
        if (reason === 'clear') {
          onChange(options.filter((item) => disabledChips.includes(item.value)));
          return;
        }

        if (newValue.filter((item) => item.value === details.option.value).length > 1) {
          onChange(
            newValue.filter((item) => {
              return item.value !== details.option.value;
            }),
          );
          return;
        }
        onChange(newValue);
        return;
      }
      onChange(newValue?.value || '', reason);
    },
    [onChange, multiple],
  );

  const autoCompleteValue = useMemo(() => {
    return multiple ? value : options.find((item) => item.value === value) || '';
  }, [multiple, value, options]);

  return (
    <Styled.Autocomplete
      {...props}
      // TODO: change value type to SelectOption | SelectOption[]
      value={autoCompleteValue}
      placeholder={disabled ? '' : placeholder}
      disabled={disabled}
      onChange={handleChange}
      multiple={multiple}
      options={sortedOptions}
      disableClearable={disableClearable || !value}
      disablePortal={disablePortal}
      isOptionEqualToValue={(option: SelectOption, value: SelectOption) => {
        return option.value === value.value;
      }}
      getOptionLabel={(option: SelectOption) => option?.title || ''}
      noOptionsText={t('buttons.noOptions')}
      groupBy={
        groupBy ||
        (multiple
          ? (option) => {
              return Array.isArray(value)
                ? (!!value.find((item) => item.value === (option as SelectOption).value)).toString()
                : null;
            }
          : null)
      }
      renderOption={
        props.renderOption
          ? props.renderOption
          : (props, option: SelectOption, state, ownerState) => {
              return multiple && Array.isArray(value) ? (
                <Styled.CheckboxOptionItem ref={lastElementRef} {...props} key={option.value}>
                  <Styled.Label>{ownerState.getOptionLabel(option)}</Styled.Label>
                  <Checkbox checked={!!value.find((item) => item.value === option.value)} />
                </Styled.CheckboxOptionItem>
              ) : (
                <Styled.OptionItem
                  {...props}
                  ref={lastElementRef}
                  key={option.value}
                  selected={option.value === value}
                  autoFocus={option.value === value}
                >
                  {ownerState.getOptionLabel(option)}
                </Styled.OptionItem>
              );
            }
      }
      renderTags={(value: SelectOption[], getTagProps, ownerState) => {
        return (
          <React.Fragment>
            {value.map((option: SelectOption, index: number) => {
              const tagProps = {
                ...getTagProps({ index }),
                disabled: disabledChips.includes(option.value) || disabled,
              };
              return index < ownerState.limitTags ? (
                <Styled.Chip key={option.value} label={ownerState.getOptionLabel(option)} {...tagProps} />
              ) : null;
            })}
            {ownerState.limitTags && value.length > ownerState.limitTags && (
              <Styled.LimitTagsChip label={`+${value.length - ownerState.limitTags}`} />
            )}
          </React.Fragment>
        );
      }}
      renderGroup={(params) => {
        return (
          <React.Fragment key={params.key}>
            {params.group === 'false' && <Divider />}
            {params.children}
            {loadingAdditionalOptions && (
              <Styled.LoaderContainer>
                <Styled.Loader size={20} />
              </Styled.LoaderContainer>
            )}
          </React.Fragment>
        );
      }}
    />
  );
};

export default React.memo(Select);
