import { styled } from '@innowise-group/mui-kit';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 11px;
  width: 100%;
`;

export const ClueWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: start;
  flex: 1;
  gap: 4px;
  padding: 2px 4px;
  border-radius: 0.5rem;
  width: 100%;
  background-color: ${({ theme }) => theme.palette.info.backgroundSecondary};
  color: ${({ theme }) => theme.palette.general.darkGrey[90]};
  ${({ theme }) => theme.fonts.primary[50]};
`;
