import {
  EmployeesService,
  RequestItem,
  SelectOption,
  VacanciesService,
  VacancyItemResponse,
  useCurrentRequestById,
  useOptionsApi,
  useResizeObserver,
  useSelectOptionsApi,
  useUnitsAPI,
  useVacanciesOptionsApi,
} from '@innowise-group/core';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@innowise-group/mui-kit';
import { requestFormDefaultValues } from '@innowise-group/modals';
import { DesktopView } from './components/desktop-view';
import { MobileView } from './components/mobile-view';
import { localizedNameObject } from '@innowise-group/utilities';

interface RequestFormProps {
  isEdit?: boolean;
  onSubmit: (data: RequestItem) => void;
  handleClose: () => void;
  selectedAuthorsOptions: string[];
}

const RequestForm: React.FC<RequestFormProps> = ({ isEdit, onSubmit, handleClose, selectedAuthorsOptions }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isDesktopView = useResizeObserver(theme.breakpoints.values.sm);
  const { setValue, watch, reset } = useFormContext<RequestItem>();
  const currentRequestEdition = useCurrentRequestById();
  const { getActualUnits, clearUnitsState } = useUnitsAPI();
  const { locationOptions, countryOptions, officeOptions, statusOptions } = useOptionsApi();
  const { searchVacancyOptions, getVacanciesByIds } = useVacanciesOptionsApi();
  const [vacancy, setVacancy] = useState<VacancyItemResponse | null>(null);
  const officeId = watch('offices');
  const countries = watch('countries');
  const cities = watch('cities');
  const vacancyId = watch('vacancyId');

  const groupBy = useCallback(
    (value) => (option) => {
      return Array.isArray(value)
        ? (!!value.find((item) => item.value === (option as SelectOption).value)).toString()
        : null;
    },
    [],
  );

  const handleChange = useCallback(
    (onChange: (value: string) => void) => (value: string) => {
      onChange(value);
      reset((prev) => ({
        ...prev,
        vacancyId: value,
        countries: requestFormDefaultValues.countries,
        cities: requestFormDefaultValues.cities,
        offices: requestFormDefaultValues.offices,
      }));
    },
    [vacancy],
  );

  const searchVacancyHandler: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> = useCallback((e) => {
    return searchVacancyOptions(e.currentTarget.value);
  }, []);

  const locationCountryOptions = useMemo(() => {
    return countryOptions.filter(
      (item) =>
        locationOptions.find((curr) => curr.parent.toString() === item.value.toString()) &&
        (vacancy?.countries.length
          ? vacancy.countries.find((curr) => curr.id.toString() === item.value.toString())
          : true),
    );
  }, [locationOptions, countryOptions, vacancy?.countries]);

  const locationCityOptions = useMemo(() => {
    return locationOptions.filter(
      (item) =>
        countries?.find((curr) => curr.value === item.parent) &&
        (vacancy?.cities.length ? vacancy.cities.find((curr) => curr.id.toString() === item.value) : true),
    );
  }, [locationOptions, vacancy?.cities, countries]);

  const { isStatusDisabled, requestStatusOptions } = useMemo(() => {
    if (!statusOptions) return { requestStatusOptions: [] };
    const isStatusDisabled = !statusOptions?.some((option) => option?.value === currentRequestEdition?.priority);
    return {
      isStatusDisabled,
      requestStatusOptions:
        isStatusDisabled && isEdit
          ? [
              ...statusOptions,
              {
                value: currentRequestEdition?.priority,
                title: t(`options.requestStatusOptions_${currentRequestEdition?.priority}`),
              },
            ]
          : statusOptions,
    };
  }, [statusOptions, currentRequestEdition?.vacancyRequestStatus]);

  const offices = useMemo(() => {
    return officeOptions?.filter((item) => cities.find((curr) => curr.value === item.parent));
  }, [officeOptions, cities]);

  const disabled = useMemo(() => !!currentRequestEdition?.candidatesFound && isEdit, [isEdit, currentRequestEdition]);

  const isVacancyDisabled = useMemo(() => {
    return isEdit && !!currentRequestEdition?.linkedCandidatesIds?.length;
  }, [isEdit, currentRequestEdition]);

  const getEmployeeById = async (id: string) => {
    const { data } = await EmployeesService.getEmployeeById(Number(id));
    const { localizedFullName } = localizedNameObject(data);
    return {
      title: localizedFullName,
      value: data.id.toString(),
    };
  };

  const getInitialEmployeeOptions = async (partOfName: string) => {
    const { data } = await EmployeesService.searchEmployees({
      partOfName,
      page: 1,
      size: 10,
    });

    return data.content.map(({ employeeId, ...data }) => {
      return {
        title: localizedNameObject(data).localizedFullName,
        value: employeeId.toString(),
      };
    });
  };

  const { selectOptions: requestAuthorOptions, searchOptions: searchRequestAuthorOptions } = useSelectOptionsApi({
    getOptionsByName: getInitialEmployeeOptions,
    getOptionsById: getEmployeeById,
    selectedOptions: selectedAuthorsOptions,
  });

  useEffect(() => {
    if (!countries?.length) {
      setValue('cities', []);
      return;
    }
    const filteredCities = cities.filter((item) => countries.find((curr) => curr.value === item.parent));
    setValue('cities', filteredCities);
  }, [countries]);

  useEffect(() => {
    if (!cities?.length) {
      setValue('offices', []);
      return;
    }
    const filteredOffices = officeId.filter((item) => cities.find((curr) => curr.value === item.parent));
    setValue('offices', filteredOffices);
  }, [cities]);

  useEffect(() => {
    if (vacancyId) {
      VacanciesService.getVacancyById(vacancyId).then((response) => setVacancy(response.data));
    } else {
      setVacancy(null);
    }
  }, [vacancyId]);

  useEffect(() => {
    if (vacancyId) getVacanciesByIds([vacancyId]);
  }, []);

  useEffect(() => {
    getActualUnits();
    return () => {
      clearUnitsState();
    };
  }, []);

  const Component = isDesktopView ? DesktopView : MobileView;
  return (
    <Component
      requestStatusOptions={requestStatusOptions}
      isStatusDisabled={isStatusDisabled}
      isEdit={isEdit}
      locationCountryOptions={locationCountryOptions}
      disabled={disabled}
      locationCityOptions={locationCityOptions}
      offices={offices}
      currentRequestEdition={currentRequestEdition}
      onSubmit={onSubmit}
      handleClose={handleClose}
      searchVacancyHandler={searchVacancyHandler}
      groupBy={groupBy}
      handleChange={handleChange}
      requestAuthorOptions={requestAuthorOptions}
      searchRequestAuthorOptions={searchRequestAuthorOptions}
      isVacancyDisabled={isVacancyDisabled}
    />
  );
};

export default RequestForm;
