import { useMemo } from 'react';
import { LocalizedCandidateItemResponse, CandidateStatusListItem, StatusItem } from '../store';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import {
  CandidateStatusGroupEnum,
  ClaimTypes,
  FormItemTypes,
  MaxInterestedStatusPerson,
  MaxRefsCount,
} from '@constants';

const MAX_COMMENT_CHARACTERS = 5000;
const MAX_LINK_CHARACTERS = 2000;

export const useValidationSchemaCandidateStatus = (
  statusConfig: CandidateStatusListItem,
  currentCandidate: LocalizedCandidateItemResponse,
  isEdit?: boolean,
  currentStatus?: StatusItem,
) => {
  const { t } = useTranslation();

  const validationSchema = useMemo(() => {
    return Yup.object().shape({
      link: Yup.object()
        .shape(
          {
            url: Yup.string()
              .trim()
              .max(MAX_LINK_CHARACTERS, t('errors.characterLimit'))
              .url(t('errors.invalidFormat'))
              .when('name', {
                is: () => {
                  return statusConfig?.formItems.find((item) => item.item === FormItemTypes.LINK)?.isMandatory;
                },
                then: (schema) => schema.required(t('errors.requiredField')),
                otherwise: (schema) => schema.nullable(),
              })
              .test('test', t('errors.alreadyAdded'), function (url) {
                if (!currentCandidate) return false;
                if (!currentCandidate.candidateLinks.find((item) => item.url === url)) {
                  return true;
                }
                if (currentStatus && currentStatus.link?.url === url) {
                  return true;
                }
                return false;
              })
              .test('test', t('errors.candidateStatusLinksCount'), function (url) {
                if (!url) return true;
                return currentCandidate.candidateLinks.length < MaxRefsCount;
              }),
            name: Yup.string()
              .trim()
              .when('url', {
                is: (url: string) =>
                  !!url || statusConfig?.formItems.find((item) => item.item === FormItemTypes.LINK)?.isMandatory,
                then: (schema) => schema.required(t('errors.requiredField')),
                otherwise: (schema) => schema.nullable(),
              }),
          },
          [['url', 'name']],
        )
        .nullable(),
      hasReminder: Yup.boolean().required(),
      hrmProfile: Yup.string().when('profile', {
        is: () => statusConfig?.group === CandidateStatusGroupEnum.JOB_ENTRY,
        then: (schema) => schema.required(t('errors.requiredField')),
        otherwise: (schema) => schema.nullable(),
      }),
      hrmChecked: Yup.boolean().when('profile', {
        is: () => !currentCandidate.hrmId && statusConfig?.group === CandidateStatusGroupEnum.JOB_ENTRY,
        then: (schema) =>
          schema.test('required', t('errors.requiredField'), function (hrmChecked) {
            return hrmChecked;
          }),
        otherwise: (schema) => schema.nullable(),
      }),
      offerChecked: Yup.boolean().when('profile', {
        is: () => statusConfig?.group === CandidateStatusGroupEnum.OFFER,
        then: (schema) =>
          schema.test('required', t('errors.requiredField'), function (offerChecked) {
            return offerChecked;
          }),
        otherwise: (schema) => schema.nullable(),
      }),
      file: Yup.object().shape(
        {
          id: Yup.number().when('id', {
            is: () => statusConfig?.formItems.find((item) => item.item === FormItemTypes.FILE)?.isMandatory,
            then: (schema) => schema.required(t('errors.requiredField')),
          }),
        },
        [['id', 'id']],
      ),
      periodFrom: Yup.date().when('root', {
        is: () => statusConfig?.formItems.find((item) => item.item === FormItemTypes.PERIOD)?.isMandatory,
        then: (schema) =>
          schema
            .min(new Date(new Date().setHours(0, 0, 0, 0)), t('errors.invalidDate'))
            .max(
              new Date(new Date().getFullYear() + 1, new Date().getMonth(), new Date().getDate()),
              t('errors.invalidDate'),
            )
            .required(t('errors.requiredField')),
        otherwise: (schema) => schema.nullable(),
      }),
      periodTo: Yup.date().when('root', {
        is: () => statusConfig?.formItems.find((item) => item.item === FormItemTypes.PERIOD)?.isMandatory,
        then: (schema) =>
          schema
            .min(new Date(new Date().setHours(0, 0, 0, 0)), t('errors.invalidDate'))
            .max(
              new Date(new Date().getFullYear() + 1, new Date().getMonth(), new Date().getDate()),
              t('errors.invalidDate'),
            )
            .required(t('errors.requiredField'))
            .test('test', t('errors.invalidDate'), function (periodTo) {
              if (!this.parent.periodFrom || !periodTo) return true;
              return this.parent.periodFrom.getTime() < periodTo.getTime();
            }),
        otherwise: (schema) => schema.nullable(),
      }),
      reminderDate: Yup.date()
        .nullable()
        .min(new Date(), t('errors.invalidDate'))
        .max(
          new Date(new Date().getFullYear() + 10, new Date().getMonth(), new Date().getDate()),
          t('errors.invalidDate'),
        )
        .when('root', {
          is: () => statusConfig?.formItems.find((item) => item.item === FormItemTypes.REMINDER)?.isMandatory,
          then: (schema) => schema.required(t('errors.requiredField')),
        }),
      eventDate: Yup.date()
        .nullable()
        .when('root', {
          is: () => !isEdit,
          then: (schema) => schema.min(new Date(), t('errors.invalidDate')),
        })
        .max(
          new Date(new Date().getFullYear() + 10, new Date().getMonth(), new Date().getDate()),
          t('errors.invalidDate'),
        )
        .when('root', {
          is: () => statusConfig?.formItems.find((item) => item.item === FormItemTypes.CALENDAR)?.isMandatory,
          then: (schema) => schema.required(t('errors.requiredField')),
        })
        .when('agreements', {
          is: (agreements) => {
            const calendarClaim = statusConfig?.formItems.find((item) => item.item === FormItemTypes.CALENDAR);
            if (!calendarClaim) return false;
            return agreements?.find((item) => item.isActive);
          },
          then: (schema) => schema.required(t('errors.requiredField')),
        }),
      subStatusId: Yup.string()
        .trim()
        .when('root', {
          is: () => statusConfig?.formItems.find((item) => item.item === FormItemTypes.SUB_STATUS)?.isMandatory,
          then: (schema) => schema.required(t('errors.requiredField')),
          otherwise: (schema) => schema.nullable(),
        }),
      statusId: Yup.string().trim().required(t('errors.requiredField')),
      vacancyId: Yup.string()
        .trim()
        .when('root', {
          is: () => statusConfig?.formItems.find((item) => item.item === FormItemTypes.VACANCY)?.isMandatory,
          then: (schema) => schema.required(t('errors.requiredField')),
          otherwise: (schema) => schema.nullable(),
        }),
      vacancyRequestId: Yup.string()
        .trim()
        .when('root', {
          is: () => statusConfig?.formItems.find((item) => item.item === FormItemTypes.VACANCY_REQUEST)?.isMandatory,
          then: (schema) => schema.required(t('errors.requiredField')),
          otherwise: (schema) => schema.nullable(),
        }),
      claims: Yup.array().of(
        Yup.object()
          .nullable()
          .shape(
            {
              claimType: Yup.string()
                .trim()
                .when(['employeeId', 'deadline', 'isActive'], {
                  is: (employeeId, deadline, isActive) => deadline || employeeId || isActive,
                  then: (schema) => schema.required(t('errors.requiredField')),
                }),
              employeeId: Yup.string()
                .trim()
                .when(['deadline', 'isActive', 'claimType'], {
                  is: (deadline: Date, isActive: boolean, claimType: ClaimTypes) => {
                    return (
                      deadline ||
                      isActive ||
                      statusConfig?.formItems.find((item) => item.item === claimType)?.isMandatory
                    );
                  },
                  then: (schema) => schema.required(t('errors.requiredField')),
                  otherwise: (schema) => schema.nullable(),
                }),
              employeeFullName: Yup.string().trim(),
              isActive: Yup.boolean(),
              deadline: Yup.date()
                .nullable()
                .min(new Date(new Date().setHours(0, 0, 0, 0)), t('errors.invalidDate'))
                .max(
                  new Date(new Date().getFullYear() + 10, new Date().getMonth(), new Date().getDate()),
                  t('errors.invalidDate'),
                )
                .test('test', t('errors.invalidDate'), function (deadline) {
                  if (!this.parent.isActive) return true;
                  const claimType = this.parent.claimType;
                  const eventDate = this.from[1].value.eventDate;
                  if (claimType === ClaimTypes.FEEDBACK) {
                    if (!(eventDate && deadline)) return true;
                    const minDeadline = new Date(eventDate);
                    minDeadline.setHours(0, 0, 0, 0);
                    return deadline.getTime() >= minDeadline.getTime();
                  }
                  return true;
                }),
            },
            [
              ['claimType', 'employeeId'],
              ['claimType', 'eventDate'],
            ],
          ),
      ),
      agreements: Yup.array()
        .of(
          Yup.object()
            .nullable()
            .shape(
              {
                claimType: Yup.string()
                  .trim()
                  .when(['employeeId', 'deadline', 'isActive'], {
                    is: (employeeId, deadline, isActive) => deadline || employeeId || isActive,
                    then: (schema) => schema.required(t('errors.requiredField')),
                  }),
                employeeId: Yup.string()
                  .trim()
                  .when(['deadline', 'isActive'], {
                    is: (deadline: Date, isActive: boolean) => {
                      return deadline || isActive;
                    },
                    then: (schema) => schema.required(t('errors.requiredField')),
                    otherwise: (schema) => schema.nullable(),
                  }),
                employeeFullName: Yup.string().trim(),
                isActive: Yup.boolean(),
                deadline: Yup.date()
                  .nullable()
                  .min(new Date(new Date().setHours(0, 0, 0, 0)), t('errors.invalidDate'))
                  .max(
                    new Date(new Date().getFullYear() + 10, new Date().getMonth(), new Date().getDate()),
                    t('errors.invalidDate'),
                  )
                  .test('test', t('errors.invalidDate'), function (deadline) {
                    if (!this.parent.isActive) return true;
                    const eventDate = this.from[1].value.eventDate;
                    if (!eventDate || !deadline) return true;
                    const agreementDeadline = new Date(eventDate);
                    const currentDeadline = new Date(deadline);
                    agreementDeadline.setDate(agreementDeadline.getDate() - 1);
                    agreementDeadline.setHours(0, 0, 0, 0);
                    currentDeadline.setHours(0, 0, 0, 0);
                    return agreementDeadline.getTime() >= currentDeadline.getTime();
                  }),
              },
              [
                ['claimType', 'employeeId'],
                ['claimType', 'eventDate'],
              ],
            )
            .test('test', t('errors.employeeAlreadyExist'), function (item) {
              const index = parseInt(this.path.replace(/[^\d]/g, ''));
              const duplicates: number[] = [];
              this.parent.map((curr, idx) => {
                if (curr.employeeId === item.employeeId && item.employeeId) {
                  duplicates.push(idx);
                }
              });
              return duplicates.length <= 1 || duplicates.findIndex((curr) => curr === index) === 0;
            })
            .test('test', t('errors.maxInterestedPerson', { count: MaxInterestedStatusPerson }), function () {
              return this.parent.length <= MaxInterestedStatusPerson;
            }),
        )
        .test('test', t('errors.requiredField'), function (agreements) {
          const isRequired = statusConfig?.formItems.find((item) => item.item === ClaimTypes.AGREEMENT)?.isMandatory;
          if (!isRequired) return true;
          return agreements?.some((item) => !!item.employeeId);
        }),
      comment: Yup.object().shape({
        isProtected: Yup.boolean().required(t('errors.requiredField')),
        comment: Yup.string().trim().max(MAX_COMMENT_CHARACTERS, t('errors.characterLimit')),
      }),
    });
  }, [t, statusConfig, currentCandidate, isEdit, currentStatus]);

  return { validationSchema };
};
