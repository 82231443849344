import {
  CandidateLifecycleStatuses,
  CandidateStatusGroupEnum,
  ChangeActions,
  ClaimStatusTypes,
  ClaimTypes,
  CurrencyEnum,
  LifecycleStatuses,
  SortingValue,
  StatusLifecycleStatuses,
} from '@constants';
import {
  LocalizedShortEmployeeItemResponse,
  LocalizedVacancyItemResponse,
  RequestItemResponse,
  ShortEmployeeItemResponse,
  VacancyItemResponse,
} from '../';
import { DictionaryValue, FileIdResponse } from '../common';
import { ListItemsResponse } from '@innowise-group/core';
import { SelectOption } from '@innowise-group/core';

export enum CandidateChangesStatuses {
  Created = 'created',
  Updated = 'updated',
  Unchanged = 'unchanged',
  Deleted = 'deleted',
  Resolved = 'resolved',
}

export enum CandidateContacts {
  Github = 'GITHUB',
  Linkedin = 'LINKEDIN',
  Phone = 'PHONE',
  AdditionalPhone = 'ADDITIONAL_PHONE',
  Telegram = 'TELEGRAM',
  Email = 'EMAIL',
  BEHANCE = 'BEHANCE',
  SKYPE = 'SKYPE',
}

export enum CandidatesDuplicatesStatuses {
  NotDuplicate = 'NOT_DUPLICATE',
  Unresolved = 'UNRESOLVED',
}

export enum DiffMergeStrategiesModes {
  ConsolidationDiffsFromEdition = 'CONSOLIDATION_DIFFS_FROM_EDITION',
  ConsolidationDiffsFromCreation = 'CONSOLIDATION_DIFFS_FROM_CREATION',
  ConsolidationDiffsFromDuplicatesReport = 'CONSOLIDATION_DIFFS_FROM_DUPLICATES_REPORT',
  ConsolidationDiffsFromWorkInParallelDetection = 'CONSOLIDATION_DIFFS_FROM_WORK_IN_PARALLEL_DETECTION',
}

export interface CandidatesState {
  candidates: CandidatesItemsState;
  candidatesConsolidation: CandidatesConsolidation;
  filesToAttach: CandidateFilesIdsState;
  files: CandidateFilesState;
  history: CandidateHistoryState;
  report: CandidateReportState;
  allStatuses: CandidateAllStatusesState;
  currentStatus: CurrentCandidateStatusState;
  events: CandidateEventsState;
  recruiterMode: RecruiterModeState;
  offers: CandidateOffersState;
  claim: CandidateClaimState;
}

export interface CandidateClaimState {
  isLoading: boolean;
  withRequestClaim?: RequestItemResponse;
}

export interface CandidateOffersState {
  isLoading: boolean;
  isFormLoading: boolean;
  ids: string[];
  items: { [key: string]: CandidateOfferItemResponse };
}

export interface CandidateOfferItemResponse {
  city?: CitiesResponse;
  country?: CountriesResponse;
  createdBy: string;
  createdDate: string;
  estimatedReleaseDate?: string | Date;
  file: FileIdResponse;
  id: number;
  lastModifiedBy: string;
  lastModifiedDate: string;
  lifecycleStatus: string;
  offerDate: string | Date;
  offerType: string;
  office?: DictionaryValue;
  position: {
    translation: string;
    valueId: string;
  };
  probationPeriod?: string;
  project?: string;
  responseDate?: string | Date;
  salaryGross?: number;
  salaryProbationGross?: number;
  salaryProjectGross?: number;
  specialWorkingConditions?: string;
  structuralUnit?: {
    id: number;
    isActual: boolean;
    nameEn: string;
    nameRu: string;
  };
  currency: CurrencyEnum;
}

export interface CandidateOfferItem {
  id?: string;
  offerType: string;
  offerDate: string | Date;
  structuralUnitId: string;
  position: string;
  salaryGross: string;
  salaryProbationGross: string;
  salaryProjectGross: string;
  estimatedReleaseDate: string | Date;
  probationPeriod: string;
  responseDate: string | Date;
  city: string;
  country: string;
  office: string;
  project: string;
  specialWorkingConditions: string;
  fileId: number;
  candidateId: number;
  currency: CurrencyEnum;
}

export interface LocalizedCurrentCandidateStatusState {
  form: StatusItem;
  response?: LocalizedStatusItemResponse;
}

export interface CurrentCandidateStatusState {
  form: StatusItem;
  response?: StatusItemResponse;
}

export interface RecruiterModeState {
  vacancyId: number;
  vacancyName: string;
  requestId: number;
  isActive: boolean;
}

export interface LocalizedCandidateEventsState extends Omit<CandidateEventsState, ''> {
  items: { [key: string]: LocalizedStatusItemResponse };
}

export interface CandidateEventsState {
  isLoading: boolean;
  items: { [key: string]: StatusItemResponse };
  ids: number[];
  deletedIds: number[];
  deletedThreadIds: number[];
  totalPages: number;
  isStatusAlone: boolean;
}

export interface CandidateAllStatusesState {
  isLoading: boolean;
  items: { [key: string]: CandidateStatusListItem };
  ids: string[];
}

export interface CandidateReportState {
  isLoading: boolean;
  items: ListItemsResponse<CandidateReportItem>;
  pagable: {
    currentPage: number;
    pageSizes: [{ title: '10'; value: '10' }, { title: '20'; value: '20' }, { title: '50'; value: '50' }];
    pageSize: 10;
  };
}

export interface LocalizedCandidateReportItem {
  id: number;
  candidate: LocalizedCandidateItemResponse;
  possibleDuplicate: LocalizedCandidateItemResponse;
}

export interface CandidateReportItem {
  id: number;
  candidate: Omit<CandidateItemResponse, 'candidatePrioritizedVacancies'>;
  possibleDuplicate: Omit<CandidateItemResponse, 'candidatePrioritizedVacancies'>;
}

export interface LocalizedCandidateHistoryState extends Omit<CandidateHistoryState, 'items'> {
  items: { [key: string]: LocalizedCandidateHistoryItemResponse };
}

export interface CandidateHistoryState {
  isLoading: boolean;
  items: { [key: string]: CandidateHistoryItemResponse };
  idsWithComments: number[];
  ids: number[];
  totalPages: number;
}

export interface CandidateHistoryResponse {
  content: CandidateHistoryItemResponse[];
  empty: boolean;
  first: boolean;
  last: boolean;
  number: number;
  numberOfElements: number;
  totalElements: number;
  totalPages: number;
  size: number;
}

export interface LocalizedCandidateHistoryItemResponse
  extends Omit<CandidateHistoryItemResponse, 'modifier' | 'comments'> {
  modifier: LocalizedShortEmployeeItemResponse;
  comments: LocalizedHistoryCommentsState;
}

export interface CandidateHistoryItemResponse {
  id: number;
  modified: string;
  modifier: ShortEmployeeItemResponse;
  ownUpdateStory: CandidateStoryItem[];
  comments: HistoryCommentsState;
  hasComments: boolean;
}

export interface LocalizedHistoryCommentsState extends Omit<HistoryCommentsState, 'items'> {
  items: { [key: string]: LocalizedCandidateHistoryCommentItemResponse };
}

export interface HistoryCommentsState {
  items: { [key: string]: CandidateHistoryCommentItemResponse };
  ids: number[];
  deletedIds: number[];
  isLoading: boolean;
}

export interface CandidateStoryItem {
  entityId: number;
  entityName: string;
  id: number;
  order?: string[];
  changedFields: CandidateChangeStoryItem;
}

export interface CandidateChangeStoryItem {
  [key: string]: {
    previousValue: any;
    currentValue: any;
    action?: ChangeActions;
    parent?: { value: string; translation: string };
  };
}

export interface CandidatesConsolidation {
  targetCandidate: null | CandidateItemResponse;
  updatesCandidate: null | CandidateItemResponse;
  candidatesDiffs: null | FormattedCandidateDiffMerge;
  candidateDuplicatesIds: number[];
  candidateDuplicates: { [key: string]: CandidateItemResponse };
  isCheckedDuplicates: boolean;
  unsavedCandidate: null | CandidateItemResponse;
}

export interface CandidateFilesState {
  isLoading: boolean;
  isCurrentFileLoading: boolean;
  items: { [key: string]: CandidateFileItemResponse };
  ids: number[];
  currentFile: string;
  deletedIds: number[];
}

export interface CandidateFilesIdsState {
  isLoading: boolean;
  items: { [key: string]: FileIdResponse };
  ids: number[];
}

export interface CandidateCommentItem {
  id?: number;
  candidateId: number;
  comment: string;
  isProtected: boolean;
  linkedVacancyId: string;
  parentId?: number;
}

export interface HistoryCommentItem {
  id?: number;
  comment: string;
  isProtected: boolean;
  changeStoryGroupId: number;
}

interface LocalizedCandidateCommentCommon extends Omit<CandidateCommentCommon, 'author'> {
  author: LocalizedShortEmployeeItemResponse;
}

interface CandidateCommentCommon {
  id: number;
  comment: string;
  isProtected: boolean;
  editAllowed: boolean;
  deleteAllowed: boolean;
  isModified: boolean;
  lifecycleStatus: LifecycleStatuses;
  createdDate: Date;
  author: ShortEmployeeItemResponse;
}

export interface LocalizedCandidateEventCommentResponse extends LocalizedCandidateCommentCommon {
  commentThread: LocalizedCandidateEventCommentResponse[];
  eventId: number;
  isLoading: boolean;
  parentCommentId?: number;
}

export interface CandidateEventCommentResponse extends CandidateCommentCommon {
  commentThread: CandidateEventCommentResponse[];
  eventId: number;
  isLoading: boolean;
  parentCommentId?: number;
}

export interface LocalizedCandidateHistoryCommentItemResponse extends LocalizedCandidateCommentCommon {
  changeStoryGroupId: number;
}

export interface CandidateHistoryCommentItemResponse extends CandidateCommentCommon {
  changeStoryGroupId: number;
}

export interface CandidatesItemsState {
  isLoading: boolean;
  isNotPrioritizeLoading: boolean;
  isPrioritizeLoading: boolean;
  isExportLoading: boolean;
  items: { [key: string]: ShortCandidateItemResponse };
  ids: number[];
  currentCandidate: null | CandidateItemResponse;
  currentAvatarFile: null | FileIdResponse;
  totalElements: number;
  totalPages: number;
}

export interface CandidatesItemsArrayState {
  isLoading: boolean;
  isExportLoading: boolean;
  items: { [key: string]: ShortCandidateItemResponse };
  ids: number[];
  currentCandidate: null | CandidateItemResponse;
}

export interface CandidateDiffElem<T = any> {
  id?: number;
  target: T;
  updates: T;
  preselected: T;
  status: CandidateChangesStatuses;
}

export interface SelectedProfession {
  id?: number;
  grade?: DictionaryValue;
  profession: DictionaryValue;
}

export type SelectedTechnologyAndDomainUnion = { dictionaryValue: { valueId: string } } | string;

export interface FormattedLanguages {
  target: CandidateLanguage[];
  updates: CandidateLanguage[];
}

export enum EditCandidateMode {
  Add = 'add',
  Delete = 'delete',
}

type FormattedCandidateMapData<T extends object> = Record<
  keyof Pick<CandidateDiffElem, 'preselected'>,
  Record<keyof T, CandidateDiffElem<T[keyof T]>>[]
> &
  Record<keyof Omit<CandidateDiffElem, 'preselected' | 'status' | 'id'>, T[]>;

export interface CandidateLocationDiffMerge {
  currentLocationCity: CountryDiff;
  currentLocationCountry: CityDiff;
  locationCountry: CityDiff;
  locationCity: CountryDiff;
}

interface CityDiff {
  id: CandidateDiffElem<string>;
  isoCode: CandidateDiffElem<CountriesResponse['isoCode']>;
  name: CandidateDiffElem<CountriesResponse['name']>;
  status?: CandidateChangesStatuses;
}

interface CountryDiff {
  id: CandidateDiffElem<string>;
  countryId: CandidateDiffElem<CitiesResponse['countryId']>;
  name: CandidateDiffElem<CitiesResponse['name']>;
  status?: CandidateChangesStatuses;
}

interface CandidateRelocationLocationDiffMerge
  extends Record<keyof Pick<CandidateLocationResponse, 'currentLocationCity' | 'locationCity'>, CityDiff>,
    Record<keyof Pick<CandidateLocationResponse, 'currentLocationCountry' | 'locationCountry'>, CountryDiff> {}

export interface CandidateDiffBase {
  firstNameRu: CandidateDiffElem<string>;
  lastNameRu: CandidateDiffElem<string>;
  firstNameEn?: CandidateDiffElem<string>;
  lastNameEn?: CandidateDiffElem<string>;
  gender: CandidateDiffElem<string>;
  aboutMe: CandidateDiffElem<string>;
  relocationAvailability: CandidateDiffElem<boolean>;
  visaAvailability: CandidateDiffElem<boolean>;
  // TODO: update after package consolidation
  readyForBusinessTrip: CandidateDiffElem<boolean>;
  sourceDetails: CandidateDiffElem<string>;
  isBlocked: CandidateDiffElem<boolean>;
  isBlockedReason: CandidateDiffElem<string>;
  email: CandidateDiffElem<string>;
  candidateSkills: CandidateDiffElem<string>;
  photo: { id: number; url: string };
  candidateLinks: CandidateDiffElem<CandidateLinkItemResponse[]>;
}

export interface CandidateDuffMerge extends CandidateDiffBase {
  id: CandidateDiffElem<number>;
  photoId: CandidateDiffElem<number>;
  candidateStatus: { valueId: CandidateDiffElem<string> };
  responsibleEmployee: { employeeId: CandidateDiffElem<number> };
  source: { valueId: CandidateDiffElem<string> };
  birthDate?: CandidateDiffElem<Date | string>;
  // TODO: update after package consolidation
  citizenshipId: CityDiff[];

  filesMeta: Pick<CandidateDiffElem<CandidateFileItemResponse[]>, 'target' | 'updates'>;

  candidateTechnologies: Pick<CandidateDiffElem<CandidateDictionaryResponse[]>, 'target' | 'updates'>;
  candidateDomains: Pick<CandidateDiffElem<CandidateDictionaryResponse[]>, 'target' | 'updates'>;

  candidateWorkFormats: Pick<CandidateDiffElem<string[]>, 'target' | 'updates'>;
  candidateWorkloads: Pick<CandidateDiffElem<string[]>, 'target' | 'updates'>;
  employmentForms: Pick<CandidateDiffElem<string[]>, 'target' | 'updates'>;

  candidateRelocationCountries: CandidateRelocationLocationDiffMerge;
  candidateLocation: CandidateLocationDiffMerge;

  candidateContacts: Pick<CandidateDiffElem<CandidateContactResponse[]>, 'target' | 'updates'>;
  candidateLanguages: Pick<CandidateDiffElem<CandidateLanguageResponse[]>, 'target' | 'updates'>;
  candidateProfessions: Pick<CandidateDiffElem<SelectedProfession[]>, 'target' | 'updates'>;
  candidateVisas: CandidateDiffElem<CandidateVisaResponse[]>;

  candidateExperiences: CandidateDiffElem<CandidateExperience[]>;
  candidateCourses: CandidateDiffElem<CandidateCourseElement[]>;
  candidateEducations: CandidateDiffElem<CandidateEducationElement[]>;

  candidatePrioritizedVacancies: CandidateDiffElem<{ id: number; name: string }[]>;

  candidateSalary: {
    expectedSalaryMin?: CandidateDiffElem<number>;
    expectedSalaryMax?: CandidateDiffElem<number>;
    currency: CandidateDiffElem<string>;
    expectedSalaryComment?: CandidateDiffElem<string>;
  };
}

export interface FormattedCandidateDiffMerge extends CandidateDiffBase {
  id: number;
  photoId: number;
  responsibleEmployee: CandidateDiffElem<number>;
  source: CandidateDiffElem<string>;
  birthDate?: CandidateDiffElem<string>;
  // TODO: update after package consolidation
  citizenshipId: CandidateDiffElem<SelectOption[]>;

  filesMeta: CandidateFileItemResponse[];

  candidateWorkloads: CandidateDiffElem<string[]>;
  candidateWorkFormats: CandidateDiffElem<string[]>;
  employmentForms: CandidateDiffElem<string[]>;

  candidateDomains: CandidateDiffElem<SelectOption[]>;
  candidateTechnologies: CandidateDiffElem<SelectOption[]>;
  candidateRelocationCountries: CandidateDiffElem<SelectOption[]>;
  candidatePrioritizedVacancies: CandidateDiffElem<SelectOption[]>;

  candidateLocation: Record<keyof Omit<CandidateLocationResponse, 'id'>, CandidateDiffElem<string>>;

  candidateLanguages: CandidateDiffElem<CandidateLanguage[]>;
  candidateProfessions: CandidateDiffElem<CandidateProfession[]>;

  candidateExperiences: FormattedCandidateMapData<CandidateExperience>;
  candidateCourses: FormattedCandidateMapData<CandidateCourseElement>;
  candidateEducations: FormattedCandidateMapData<CandidateEducationElement>;

  // TODO: update after package consolidation
  candidateVisas: CandidateDiffElem<
    Omit<Record<keyof CandidateVisaResponse, string>, 'isFullVisaDate'> & { isFullVisaDate: boolean }
  >;
  candidateSalary: Omit<CandidateDuffMerge['candidateSalary'], 'currency'> & { currency: CandidateDiffElem<string> };

  candidateContacts: {
    [key in CandidateContacts]?: CandidateDiffElem<string>;
  };
}

export type PrimitiveValuesUnionInCandidatesDiff =
  | keyof Omit<CandidateDiffBase, 'photo' | 'candidateLinks'>
  // TODO: update after package consolidation
  | keyof Pick<FormattedCandidateDiffMerge, 'responsibleEmployee' | 'source' | 'birthDate' | 'candidateSkills'>;

export type StringArrDataFields = keyof Pick<
  FormattedCandidateDiffMerge,
  | 'candidateDomains'
  | 'candidateTechnologies'
  | 'candidateWorkFormats'
  | 'candidateWorkloads'
  | 'candidateProfessions'
  | 'candidateLanguages'
  | 'employmentForms'
>;

export interface CandidateItem {
  id?: number;
  firstNameRu?: string;
  firstNameEn?: string;
  lastNameRu?: string;
  lastNameEn?: string;
  aboutMe?: string;
  gender?: string;
  birthDate?: Date;
  email?: string;
  // TODO: update after package consolidation
  readyForBusinessTrip: boolean;
  citizenshipId?: SelectOption[];
  isBlocked: boolean;
  isBlockedReason?: string;
  sourceDetails?: string;
  relocationAvailability: boolean;
  vacancy?: SelectOption[];
  filesMeta: FileIdResponse[];
  sourceId: string;
  candidateReferral: {
    recommenderEmail: string;
    reward: string;
    referralComment: string;
    startGrade: string;
  };
  photoId?: number;
  visaAvailability: boolean;
  candidateContacts: CandidateContact[];
  candidateExperiences: CandidateExperience[];
  candidateProfessions: CandidateProfession[];
  candidateLanguages: CandidateLanguage[];
  responsibleEmployee: number | string;
  candidateVisas: CandidateVisa[];
  candidateSalary: CandidateSalary;
  candidateLocation: CandidateLocation;
  candidateDomains: { title: string; value: string; id?: string }[];
  candidateTechnologies: { title: string; value: string; id?: string }[];
  candidateWorkFormats: CandidateWorkFormatsElement[];
  candidateWorkloads: CandidateWorkFormatsElement[];
  employmentForms: CandidateWorkFormatsElement[];
  candidateRelocationCountries: { title: string; value: string; id?: string }[];
  candidateSkills: string;
  candidateLinks: CandidateLinkItem[];
  candidateEducations: CandidateEducationElement[];
  candidateCourses: CandidateCourseElement[];
  vacancyIdWithRequestAttached: string;
  lastModifiedDate?: string;
}

export interface CandidateItemDiff {
  id?: number;
  aboutMe?: string;
  firstNameRu?: string;
  firstNameEn?: string;
  lastNameRu?: string;
  lastNameEn?: string;
  birthDate?: Date;
  email?: string;
  citizenshipId?: SelectOption[];
  isBlocked: boolean;
  isBlockedReason?: string;
  sourceDetails?: string;
  relocationAvailability: boolean;
  vacancy?: SelectOption[];
  filesMeta: CandidateFileItemResponse[];
  sourceId: string;
  photoId?: number;
  visaAvailability: boolean;
  candidateContacts: CandidateContact[];
  candidateExperiences: CandidateExperience[];
  candidateProfessions: CandidateProfession[];
  candidateLanguages: CandidateLanguage[];
  responsibleEmployee: number | string;
  candidateVisas: CandidateVisa[];
  candidateSalary: CandidateSalary;
  candidateLocation: CandidateLocation;
  candidateDomains: SelectOption[];
  candidateTechnologies: SelectOption[];
  candidateWorkFormats: CandidateWorkFormatsElement[];
  candidateWorkloads: CandidateWorkFormatsElement[];
  candidateRelocationCountries: SelectOption[];
  candidateSkills: string;
  candidateLinks: CandidateLinkItem[];
  candidateEducations: CandidateEducationElement[];
  candidateCourses: CandidateCourseElement[];
  employmentForms: CandidateWorkFormatsElement[];
}

export interface CandidateLinkItem {
  name: string;
  url: string;
  id?: number;
}

export interface CandidateLinkItemResponse {
  id: number;
  name: string;
  url: string;
}

export interface CandidateComment {
  candidateCommentType: string;
  comment: string;
  field: string;
}

export interface CandidateContact {
  id?: number | string;
  contactType: string;
  contact?: {
    value: string;
    country?: string;
  };
}

export interface CandidateContactResponse {
  id?: string | number;
  contactType: string;
  contact?: string;
}

export interface CandidateCourseElement<TDate = Date> {
  studyPlace?: string;
  courseName?: string;
  studiedFrom?: TDate;
  studiedTo?: TDate;
  comment?: string;
  id?: number;
}

export interface CandidateEducationElement<TDate = Date> {
  studyPlace?: string;
  profession?: string;
  studiedFrom?: TDate;
  studiedTo?: TDate;
  comment?: string;
  id?: number;
  withoutPeriod?: boolean;
}

export interface CandidateDomainElement {
  dictionaryValueId: string | boolean;
}

export interface CandidateExperience<TDate = Date> {
  id?: number;
  company?: string;
  position?: string;
  workedFrom: TDate;
  workedTo?: TDate;
  stillWorking?: boolean;
  comment?: string;
  itSphere?: boolean;
  experienceYears?: number;
}

export interface CandidateLanguage {
  id?: number | string;
  languageId: string;
  languageLevelId?: string;
}

export interface CandidateLanguageResponse {
  id: number | string;
  language: string;
  languageLevel?: string;
}

export interface CandidateLocation {
  locationCountryId?: string;
  currentLocationCityId?: string;
  currentLocationCountryId?: string;
  locationCityId?: string;
}

export interface CandidateLocationResponse {
  locationCountry?: CountriesResponse;
  currentLocationCity?: CitiesResponse;
  currentLocationCountry?: CountriesResponse;
  locationCity?: CitiesResponse;
}

export interface CandidateProfession {
  id?: number | string;
  professionId: string;
  gradeId?: string;
}

export interface CandidateProfessionResponse {
  id: number | string;
  profession: DictionaryValue;
  grade: string;
}

export interface CandidateWorkFormatsElement {
  id?: number;
  dictionaryValueId: string;
  checked: boolean;
}

export interface CandidateResponsible {
  employeeId: number;
}

export interface CandidateSalary {
  id?: number;
  expectedSalaryMin?: string | number;
  expectedSalaryMax?: string | number;
  currencyId: string;
  expectedSalaryComment?: string;
}

export interface CandidateSalaryResponse {
  id: number;
  expectedSalaryMin?: number | string;
  expectedSalaryMax?: number | string;
  currency: string;
  expectedSalaryComment?: string;
}

export interface CandidateVisa {
  visaTypeId: string;
  validTo: Date | string;
  id?: number | string;
  isFullVisaDate: boolean;
}

export interface CandidateVisaResponse<TDate = Date> {
  visaType: DictionaryValue;
  validTo: TDate;
  id: number | string;
  isFullVisaDate: boolean;
}

export interface CandidateDictionaryResponse {
  id?: number;
  dictionaryValue: DictionaryValue;
}

export interface LocalizedShortCandidateItemResponse extends Omit<ShortCandidateItemResponse, 'responsibleEmployee'> {
  localizedFullName: string;
  localizedFirstName: string;
  localizedLastName: string;
  responsibleEmployee: LocalizedShortEmployeeItemResponse;
}

export interface ShortStatusItemResponse {
  id: number;
  createdDate?: string;
  status: {
    id: string;
    localizedName: string;
    group: CandidateStatusGroupEnum;
  };
  eventDate: string;
  subStatus: {
    id: string;
    localizedName: string;
    group: CandidateStatusGroupEnum;
  };
  claims: [
    {
      id: number;
      employeeId: number;
      claimType: string;
      deadline: string;
      active: true;
      status: string;
    },
  ];
  deadline: string;
}

export interface ShortCandidateItemResponse {
  id: number;
  location: CandidateLocationResponse;
  candidatePrioritizedVacancies: VacancyItemResponse[];
  candidateDomains: CandidateDictionaryResponse[];
  candidateLanguages: CandidateLanguageResponse[];
  candidateProfessions: CandidateProfessionResponse[];
  candidateTechnologies: CandidateDictionaryResponse[];
  firstNameEn: string;
  lastNameEn: string;
  firstNameRu: string;
  lastNameRu: string;
  age: number;
  itExperience: number;
  overallExperience: number;
  lastWork: CandidateExperience;
  photoId?: number;
  status: ShortStatusItemResponse;
  responsibleEmployee: ShortEmployeeItemResponse;
  lifecycleStatus: CandidateLifecycleStatuses;
  isBoosted?: boolean;
  vacancyIdWithRequestAttached: string | null;
  hasRequestHistory?: RequestItemResponse;
  priority?: {
    profession: number;
    language: number;
    technology: number;
    domain: number;
    location: number;
    isManuallyPrioritized: boolean;
    total: number;
  };
}

export interface IntervalFiltersValues {
  min: string;
  max: string;
}

export type GetAllCandidates = ListItemsResponse<ShortCandidateItemResponse>;

export interface CreateCandidatesDuplicatesResponse {
  candidateId: string | number;
  duplicateIds: (string | number)[];
  duplicateStatus: CandidatesDuplicatesStatuses;
}
export interface LocalizedCandidateItemResponse
  extends Omit<CandidateItemResponse, 'candidatePrioritizedVacancies' | 'responsibleEmployee'> {
  localizedFullName: string;
  localizedFirstName: string;
  localizedLastName: string;
  responsibleEmployee: LocalizedShortEmployeeItemResponse;
  candidatePrioritizedVacancies?: LocalizedVacancyItemResponse[];
}

export interface CountriesResponse {
  id: string;
  name: string;
  isoCode?: string;
  phoneCode?: string;
  phoneCodeSuffixes?: string[];
}

export interface CitiesResponse {
  id: string;
  name: string;
  countryId: string;
}

export interface CandidateItemResponse {
  id?: number;
  photoId?: number;
  firstNameRu: string;
  firstNameEn: string;
  lastNameRu: string;
  lastNameEn: string;
  gender?: string;
  aboutMe?: string;
  readyForBusinessTrip: boolean;
  birthDate: string | null;
  email: string;
  citizenships: CountriesResponse[];
  isBlocked: boolean;
  isBlockedReason?: string;
  canReadSecuredInfo?: boolean;
  photo?: {
    id: number;
    url?: string;
  };
  candidateReferral?: CandidateReferralResponse;
  sourceDetails?: string;
  visaAvailability: boolean;
  relocationAvailability: boolean;
  source: DictionaryValue;
  filesMeta: FileIdResponse[];
  candidateContacts: CandidateContactResponse[];
  candidateExperiences: CandidateExperience<string>[];
  candidateProfessions: CandidateProfessionResponse[];
  candidateLanguages: CandidateLanguageResponse[];
  responsibleEmployee: ShortEmployeeItemResponse;
  candidateVisas: CandidateVisaResponse<string>[];
  candidateSalary: CandidateSalaryResponse;
  candidateLocation: CandidateLocationResponse;
  candidateDomains: CandidateDictionaryResponse[];
  candidateTechnologies: CandidateDictionaryResponse[];
  candidateWorkFormats: string[];
  candidateWorkloads: string[];
  employmentForms: string[];
  candidateRelocationCountries: { id: number; country: CountriesResponse }[];
  candidateSkills: string;
  candidatePrioritizedVacancies?: VacancyItemResponse[];
  candidateLinks: CandidateLinkItemResponse[];
  candidateEducations: CandidateEducationElement<string>[];
  candidateCourses: CandidateCourseElement<string>[];
  candidateOverallExperienceMonths: number;
  candidateItSphereExperienceMonths: number;
  lastModifiedDate: string;
  lifecycleStatus: CandidateLifecycleStatuses;
  vacancyIdWithRequestAttached: string;
  hrmEmail?: string;
  hrmId?: number;
}

export interface CandidateReferralResponse {
  editable?: boolean;
  recommender: {
    id: string;
    firstNameEn: string;
    lastNameEn: string;
    firstNameRu: string;
    lastNameRu: string;
    photoHrmUrl?: string;
    miniPhotoHrmUrl?: string;
    email: string;
    localizedFullName?: string;
    localizedFirstName?: string;
    localizedLastName?: string;
  };
  startGrade: string;
  reward: string;
  referralComment: string;
}

export interface CandidateFileItemResponse {
  externalId: string;
  fileStatus: string;
  fileType: string;
  filename: string;
  id: number;
  secured: boolean;
}

export interface AllCandidateRequestParams {
  page: string;
  size: string;
  sort: SortingValue;
}

export interface CandidateParsedPdfInfo {
  email: string | null;
  firstNameEn: string | null;
  firstNameRu: string | null;
  gitHub: string | null;
  lastNameEn: string | null;
  lastNameRu: string | null;
  linkedIn: string | null;
  phone: string | null;
  telegram: string | null;
  fullResume: 'string';
}

export interface CandidateFiltersRequest {
  birthDateMin: Date;
  birthDateMax: Date;
  ageMin: number;
  ageMax: number;
  salaryMin: number;
  salaryMax: number;
  currency: string;
  overallJobExperienceMin: number;
  overallJobExperienceMax: number;
  itSphereJobExperienceMin: number;
  itSphereJobExperienceMax: number;
  citizenship: string[];
  countries: string[];
  cities: string[];
  relocationCountries: string[];
  visaTypes: string[];
  statuses: string[];
  responsible: number[];
  sourceFilter: {
    source: [];
    sourceDetails: [];
  }[];
  professionFilter: {
    profession: string;
    grades: string[];
  }[];
  languageFilter: {
    language: string;
    grades: string[];
  }[];
  technologies: string[];
  domains: string[];
  workFormats: string[];
  workLoads: string[];
  relocationAvailability: boolean;
  visaAvailability: boolean;
  isBlocked: boolean;
  courseFilter: {
    place: string;
    profession: string;
    from: Date;
    to: Date;
  };
  experienceFilter: {
    place: string;
    profession: string;
    from: Date;
    to: Date;
  };
  educationFilter: {
    place: string;
    profession: string;
    from: Date;
    to: Date;
  };
  lifecycleStatus: string;
  selectedCandidates: number[];
  searchBarRequest: string;
}

export interface RecruiterModeItem {
  vacancyId: string;
  vacancyName?: string;
  requestId?: string;
}

export interface CandidateStatusListItem {
  id: string;
  localizedName: string;
  group: CandidateStatusGroupEnum;
  formItems: { item: string; isMandatory: boolean }[];
  subStatus: { id: string; localizedName: string; lifecycleStatus: StatusLifecycleStatuses }[];
  lifecycleStatus: StatusLifecycleStatuses;
  orderValue: number | null;
}

export interface StatusItem {
  hrmProfile?: string;
  hrmChecked?: boolean;
  offerChecked?: boolean;
  eventId?: number;
  candidateId?: number;
  file?: FileIdResponse;
  link?: {
    id?: number;
    url: string;
    name: string;
  };
  statusId?: string;
  subStatusId?: string;
  vacancyId?: number;
  vacancyName?: string;
  vacancyRequestId?: string;
  eventDate?: Date | string;
  hasReminder: boolean;
  reminderDate?: Date | string;
  periodFrom?: Date | string;
  periodTo?: Date | string;
  claims?: {
    claimId?: number;
    claimType: ClaimTypes;
    isActive: boolean;
    deadline: string;
    employeeId: number | string;
    employeeFullName?: string;
    status?: ClaimStatusTypes;
  }[];
  agreements?: {
    claimId?: number;
    claimType: ClaimTypes;
    isActive: boolean;
    deadline: string;
    employeeId: number | string;
    employeeFullName?: string;
    status?: ClaimStatusTypes;
  }[];
  comment: {
    comment: string;
    isProtected: boolean;
    id?: number;
    parentId?: number;
  };
}

export interface LocalizedStatusItemResponse extends Omit<StatusItemResponse, 'author' | 'claims' | 'comment'> {
  author: LocalizedShortEmployeeItemResponse;
  claims: LocalizedClaimItemType[];
  comment: LocalizedCandidateEventCommentResponse;
}

export interface StatusItemResponse {
  id: number;
  hrmProfile: {
    email: string;
    urlToProfile: string;
  };
  author: ShortEmployeeItemResponse;
  file?: FileIdResponse;
  isVisible: boolean;
  link?: { id: number; name: string; url: string };
  status: CandidateStatusListItem;
  subStatus: { id: string; localizedName: string };
  vacancy?: {
    id: number;
    name: string;
    status: { translation: string; valueId: string };
  };
  eventDate?: Date;
  hasReminder: boolean;
  reminderDate?: Date;
  periodFrom?: Date;
  periodTo?: Date;
  vacancyClaim: RequestItemResponse;
  claims: ClaimItemType[];
  lifecycleStatus: LifecycleStatuses;
  createdDate: Date;
  comment: CandidateEventCommentResponse;
}

export interface LocalizedClaimItemType extends Omit<ClaimItemType, 'employee'> {
  employee: LocalizedShortEmployeeItemResponse;
}

export interface ClaimItemType {
  id: number;
  claimType: ClaimTypes;
  deadline: string;
  employee: ShortEmployeeItemResponse;
  active: boolean;
  status: ClaimStatusTypes;
}
