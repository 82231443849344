import { FC, useCallback, useEffect } from 'react';
import * as Styled from './report-candidates-sidebar.styles';
import { AvatarItem, Icon, IconButton, Loader } from '@innowise-group/mui-kit';
import { useTranslation } from 'react-i18next';
import { TinyState } from '@modules';
import {
  useCompressedStateInQuery,
  useInfiniteScroll,
  useStatisticsAPI,
  useStatisticsCandidatesListAPI,
} from '@innowise-group/core';
import { AppRoutes } from '@constants';

interface ReportCandidatesSidebarProps {
  openSidebar: { isOpen: boolean; statusId: string; vacancyId?: string };
  handleCloseSidebar: () => void;
}

const ReportCandidatesSidebar: FC<ReportCandidatesSidebarProps> = ({ openSidebar, handleCloseSidebar }) => {
  const { t } = useTranslation();
  const { isExportLoading } = useStatisticsAPI();
  const {
    isLoading,
    totalPages,
    items,
    clearCandidatesInStatusAndVacancy,
    fetchCandidatesInStatusAndVacancy,
    exportCandidatesInReport,
  } = useStatisticsCandidatesListAPI();

  const [state] = useCompressedStateInQuery<TinyState>();
  const { lastElementRef, pageNum, resetPageNum } = useInfiniteScroll(isLoading, totalPages);

  const handleClose = useCallback(() => {
    handleCloseSidebar();
    resetPageNum();
    clearCandidatesInStatusAndVacancy();
  }, []);

  const handleExport = useCallback(() => {
    if (!isExportLoading) {
      exportCandidatesInReport(state, openSidebar.statusId, openSidebar.vacancyId);
    }
  }, [openSidebar.vacancyId, openSidebar.statusId, isExportLoading, state]);

  useEffect(() => {
    if (openSidebar.isOpen) {
      fetchCandidatesInStatusAndVacancy(state, pageNum, openSidebar.statusId, openSidebar.vacancyId);
    }
  }, [state, pageNum, openSidebar.isOpen]);

  return (
    <Styled.Drawer anchor="right" open={openSidebar.isOpen} onClose={handleClose}>
      <Styled.DrawerHeader>
        <Styled.DrawerTitle>{t('pages.candidates.pageName')}</Styled.DrawerTitle>
        <IconButton onClick={handleExport} disabled={isExportLoading}>
          <Icon type="u_export" size={24} />
        </IconButton>
        <IconButton onClick={handleClose}>
          <Icon type="u_multiply" size={24} />
        </IconButton>
      </Styled.DrawerHeader>
      <Styled.DrawerContent>
        {isLoading && pageNum === 1 ? (
          <Styled.LoaderContainer>
            <Loader />
          </Styled.LoaderContainer>
        ) : (
          <>
            {items.map((item, index) => (
              <Styled.CandidateItem key={`${item.id}_${index}`} ref={lastElementRef}>
                <AvatarItem photoId={item.photoId.toString()} />
                <Styled.CandidateInfo>
                  <Styled.CandidateName to={`${AppRoutes.Candidates}/${item.id}`}>
                    {item.firstNameRu && item.firstNameEn
                      ? `${item.firstNameRu} ${item.lastNameRu} | ${item.firstNameEn} ${item.lastNameEn}`
                      : `${item.firstNameRu} ${item.lastNameRu} ${item.firstNameEn} ${item.lastNameEn}`}
                  </Styled.CandidateName>
                  <Styled.CandidateContent>
                    <Styled.CandidateIdChip>ID {item.id}</Styled.CandidateIdChip>
                    <Styled.CandidateProfession>
                      {item.candidateProfessions[0]?.profession?.translation || ''}
                    </Styled.CandidateProfession>
                  </Styled.CandidateContent>
                </Styled.CandidateInfo>
              </Styled.CandidateItem>
            ))}
            {isLoading && pageNum !== 1 && (
              <Styled.LoaderContainer>
                <Loader />
              </Styled.LoaderContainer>
            )}
          </>
        )}
      </Styled.DrawerContent>
    </Styled.Drawer>
  );
};

export default ReportCandidatesSidebar;
