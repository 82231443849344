import { styled } from '@innowise-group/mui-kit';

export const CheckBoxWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  grid-column: span 2;

  & .MuiFormControlLabel-label {
    white-space: break-spaces;
  }
`;

export const EmailTooltip = styled.span`
  padding: 2px 8px;
  background-color: ${({ theme }) => theme.palette.info.background};
  border: 1px solid ${({ theme }) => theme.palette.info.backgroundSecondary};
  border-radius: 0.75rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  text-wrap: nowrap;
  ${({ theme }) => theme.fonts.primary[50]};
  color: ${({ theme }) => theme.palette.info.default};

  & .icon {
    padding: 0;
  }
`;
