import {
  useCurrentCandidate,
  useDebounce,
  useEmployeesAdministrationAPI,
  useEmployeesEmailsOptions,
} from '@innowise-group/core';
import { Input, Select, Icon } from '@innowise-group/mui-kit';
import React, { useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as Styled from './hrm-profile.styles';
import { LabeledCheckbox } from '@shared-mui-components';
import { InfoTooltip } from '@shared-components';

const HRMProfile: React.FC = () => {
  const { t } = useTranslation();
  const { control, watch } = useFormContext();
  const [email, setEmail] = useState<string>('');
  const currentCandidate = useCurrentCandidate();
  const [debouncedEmailValue] = useDebounce<string>(email, 500);
  const employeesEmailsList = useEmployeesEmailsOptions();
  const { searchEmailByEmailPart, employeesEmailsListLoading } = useEmployeesAdministrationAPI();
  const hrmProfile = watch('hrmProfile');
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  useEffect(() => {
    searchEmailByEmailPart({ email: debouncedEmailValue, limit: 10, includeRtEmployees: true });
  }, [debouncedEmailValue]);

  useEffect(() => {
    searchEmailByEmailPart({ email: hrmProfile, limit: 10, includeRtEmployees: true });
  }, [hrmProfile]);

  const isCandidateAlreadyLinked = !!currentCandidate?.hrmId;

  return (
    <React.Fragment>
      <div>
        {isCandidateAlreadyLinked && (
          <Styled.EmailTooltip>
            <Icon type="u_binary_tree" className="icon" viewBox="0 0 20 20" />
            {t('pages.candidates.statuses.hrmLink_tooltip')}
          </Styled.EmailTooltip>
        )}
        <Controller
          name="hrmProfile"
          control={control}
          render={({ field: { onChange, value }, formState: { errors } }) => {
            return (
              <Select
                value={value}
                onChange={onChange}
                disableClearable={!value}
                options={employeesEmailsList || []}
                loading={employeesEmailsListLoading}
                disableSortByAlphabet={false}
                disabled={isCandidateAlreadyLinked}
                renderInput={(params) => {
                  return (
                    <Input
                      {...params}
                      required
                      onChange={handleInputChange}
                      label={t('pages.candidates.statuses.hrmLink')}
                      placeholder={t('pages.candidates.candidateCreation.enterTheEmail')}
                      error={!!errors?.hrmProfile?.message}
                      helperText={errors?.hrmProfile?.message?.toString()}
                    />
                  );
                }}
              />
            );
          }}
        />
      </div>
      {!isCandidateAlreadyLinked && (
        <React.Fragment>
          <Styled.CheckBoxWrapper>
            <Controller
              name="hrmChecked"
              control={control}
              render={({ field: { onChange, value }, formState: { errors } }) => {
                return (
                  <LabeledCheckbox
                    onChange={onChange}
                    checked={value}
                    label={t('pages.candidates.statuses.sourceChecked')}
                    error={!!errors?.hrmChecked?.message}
                  />
                );
              }}
            />
            <InfoTooltip placement="top-end" title={t('tooltips.hrmProfileInfo')} />
          </Styled.CheckBoxWrapper>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default HRMProfile;
