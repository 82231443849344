import { Modal, ModalProps, modalHoc } from '@innowise-group/mui-kit';
import { Loader } from '@innowise-group/mui-kit';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import * as Styled from './change-candidate-status-modal.styles';
import {
  StatusItem,
  useCandidateStatusAPI,
  useCandidatesAPI,
  useCurrentCandidate,
  useCurrentStatus,
  useEmployeesAPI,
  useResizeObserver,
  useStatusConfigById,
  useValidationSchemaCandidateStatus,
} from '@innowise-group/core';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { CandidateStatusForm } from '@shared-components';
import { CandidateStatusFormDefaultValues } from '@constants';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button } from '@innowise-group/mui-kit';
import { LabeledCheckbox } from '@shared-mui-components';
import { useTheme } from '@innowise-group/mui-kit';

interface ChangeCandidateStatusModalProps {
  photoId?: number;
  localizedFullName: string;
  candidateId: number;
  eventId: number;
  isDeleted?: boolean;
}

const ChangeCandidateStatusModal: React.FC<ChangeCandidateStatusModalProps & ModalProps> = ({
  close,
  candidateId,
  eventId,
  ...props
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isDesktopView = useResizeObserver(theme.breakpoints.values.sm);
  const { fetchCandidateById } = useCandidatesAPI();
  const { updateStatus, setCurrentStatusItem, getActualStatus, isLoading } = useCandidateStatusAPI();
  const { fetchMentionedEmployees, mentionedIsLoading: isEmployeeLoading } = useEmployeesAPI();
  const currentStatus = useCurrentStatus()?.form;

  const currentCandidate = useCurrentCandidate();

  const statusId = currentStatus.statusId;
  const statusConfig = useStatusConfigById(statusId);

  const { validationSchema } = useValidationSchemaCandidateStatus(statusConfig, currentCandidate, true, currentStatus);
  const methods = useForm<StatusItem>({
    mode: 'all',
    reValidateMode: 'onBlur',
    resolver: yupResolver(validationSchema),
    defaultValues: {
      ...CandidateStatusFormDefaultValues,
      hrmProfile: currentCandidate.hrmEmail || CandidateStatusFormDefaultValues.hrmProfile,
    },
  });

  const {
    reset,
    handleSubmit,
    watch,
    control,
    formState: { dirtyFields },
  } = methods;
  const file = watch('file');

  const onSubmit = async (data: StatusItem) => {
    const response = await updateStatus(data);
    if (response.meta.requestStatus !== 'rejected') {
      await fetchMentionedEmployees(candidateId);
      fetchCandidateById(candidateId);
      handleClose();
    }
  };

  const handleClose = () => {
    close();
    getActualStatus(candidateId);
  };

  useEffect(() => {
    reset({ ...currentStatus, candidateId });
  }, [reset, currentStatus?.eventId, candidateId]);

  useEffect(() => {
    setCurrentStatusItem(eventId);
  }, [setCurrentStatusItem, eventId]);

  const disabledSubmitButton = !Object.keys(dirtyFields).length && file?.id === currentStatus.file?.id;

  if (!statusConfig) return null;
  return (
    <Styled.Modal size="standard">
      <Modal.Header onClose={handleClose}>
        <Styled.ModalTitle>
          <span>{t('pages.candidates.statuses.editStatus')}</span>
        </Styled.ModalTitle>
      </Modal.Header>
      {isLoading || isEmployeeLoading ? (
        <Loader />
      ) : (
        <React.Fragment>
          <Styled.ModalBody>
            <FormProvider {...methods}>
              <CandidateStatusForm
                {...props}
                eventId={eventId}
                isEdit
                candidateId={Number(candidateId)}
                handleClose={handleClose}
                onSubmit={onSubmit}
              />
            </FormProvider>
          </Styled.ModalBody>
          {isDesktopView && (
            <Styled.Footer>
              <Controller
                name={`comment.isProtected`}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <LabeledCheckbox
                    onChange={onChange}
                    checked={value}
                    label={t('pages.candidates.comments.restrictAccess')}
                  />
                )}
              />
              <Styled.ModalActions>
                <Button onClick={handleClose} variant="outlined">
                  {t('buttons.cancel')}
                </Button>
                <Button onClick={handleSubmit(onSubmit)} disabled={disabledSubmitButton}>
                  {t('buttons.save')}
                </Button>
              </Styled.ModalActions>
            </Styled.Footer>
          )}
        </React.Fragment>
      )}
    </Styled.Modal>
  );
};

export default modalHoc(ChangeCandidateStatusModal, 'change-candidate-status-modal');
