import React from 'react';
import { useTranslation } from 'react-i18next';
import { useProfileAPI, useReferralAPI, useResizeObserver } from '@innowise-group/core';
import { AppRoutes, SupportLink, RolesValues } from '@constants';
import * as Styled from './navigation-bar-container.styles';
import { NavigationBar, Icon, useTheme } from '@innowise-group/mui-kit';

interface NavigationBarContainerProps {
  isCollapsed: boolean;
  setCollapsed: React.Dispatch<React.SetStateAction<boolean>>;
}

const NavigationBarContainer: React.FC<NavigationBarContainerProps> = ({ isCollapsed, setCollapsed }) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const isDesktopView = useResizeObserver(theme.breakpoints.values.sm);

  const { profile } = useProfileAPI();
  const { allowedToAgree } = useReferralAPI();
  return (
    <NavigationBar
      isCollapsed={isCollapsed}
      toggleCollapsed={setCollapsed}
      routes={[
        {
          name: t('pages.candidates.pageName'),
          path: AppRoutes.Candidates,
          icon: 'u_users',
          activeWhen: (route) => route !== AppRoutes.CandidatesDuplicatesReport && route.includes(AppRoutes.Candidates),
          allowed: [
            RolesValues.Admin,
            RolesValues.Sourcer,
            RolesValues.Recruiter,
            RolesValues.Supervisor,
            RolesValues.ManagerFirstLevel,
            RolesValues.ManagerSecondLevel,
          ].includes(profile?.role?.name),
        },
        {
          name: t('pages.vacancies.pageName'),
          path: AppRoutes.Vacancies,
          icon: 'u_file',
          allowed: true,
          viewBox: '2 2 20 21',
        },
        {
          name: t('pages.requests.pageName'),
          path: AppRoutes.Requests,
          icon: 'u_request',
          allowed: true,
          viewBox: '-1 0 20 20',
          activeWhen: (route) => route.includes(AppRoutes.Requests) && !route.includes(AppRoutes.Analytics),
        },
        {
          name: t('pages.analytics.pageName'),
          path: AppRoutes.AnalyticsDefaultPage,
          icon: 'u_analytics',
          allowed: true,
          activeWhen: (route) => route.includes(AppRoutes.Analytics),
          onlyWeb: true,
        },
        {
          name: t('pages.administration.pageName'),
          path:
            allowedToAgree && profile?.role?.name !== RolesValues.Admin
              ? AppRoutes.AdministrationReferral
              : AppRoutes.AdministrationEmployees,
          icon: 'u_administration',
          allowed: [RolesValues.Admin].includes(profile?.role?.name) || allowedToAgree,
        },
      ]}
    >
      {isDesktopView && (
        <React.Fragment>
          <Styled.HelpLink href={SupportLink} target="_blank" rel="noopener noreferrer">
            <Styled.IconContainer>
              <Icon type="u_globe" size={24} viewBox="0 0 22 22" />
            </Styled.IconContainer>
            {!isCollapsed && <Styled.HelpText>{t('buttons.technicalSupport')}</Styled.HelpText>}
          </Styled.HelpLink>
          <Styled.FaqLink to={AppRoutes.FaqView}>
            <Styled.IconContainer>
              <Icon type="u_question-circle" size={24} viewBox="0 0 22 22" />
            </Styled.IconContainer>
            {!isCollapsed && <Styled.HelpText>{t('buttons.faq')}</Styled.HelpText>}
          </Styled.FaqLink>
          <Styled.VersionText>
            {t('pages.version')}: {VERSION}
          </Styled.VersionText>
        </React.Fragment>
      )}
    </NavigationBar>
  );
};

export default NavigationBarContainer;
