export enum AppRoutes {
  Home = '/',
  Auth = '/sign-in',
  FaqView = '/faq',
  Search = '/search',
  SearchCandidates = '/search/candidate',
  SearchVacancies = '/search/vacancy',
  SearchRequests = '/search/request',
  AdministrationDepartments = '/administration/departments',
  FaqEdit = '/faq/edit',
  Candidates = '/candidates',
  CandidateDetails = '/candidates/:id',
  CandidateCreation = '/candidates/creation',
  CandidateDuplicates = '/candidates/duplicates',
  Vacancies = '/vacancies',
  Edition = 'edition',
  Creation = 'creation',
  Requests = '/requests',
  Calendar = '/calendar',
  Administration = '/administration',
  Employees = '/employees',
  Referral = '/referral',
  AdministrationEmployees = '/administration/employees',
  AdministrationLocations = '/administration/locations',
  AdministrationStatuses = '/administration/statuses',
  AdministrationReferral = '/administration/referral',
  AdministrationSourcing = '/administration/sourcing',
  Profile = '/profile',
  Notifications = '/notifications',
  Analytics = '/reports',
  Locations = '/locations',
  AnalyticsDefaultPage = '/reports/requests',
  AnalyticsStatusesPage = '/reports/statuses',
  AnalyticsStatusPage = '/reports/status',
  AnalyticsTeamWorkingPage = '/reports/team-working',
  AnalyticsLettersPage = '/reports/letters',
  AnalyticsEmployeePage = '/reports/employees',
  AnalyticsExitsPage = '/reports/exits',
  AnalyticsSummaryPage = '/reports/summary',
  AnalyticsRequestsPage = '/reports/requests',
  AnalyticsDuplicatesReport = 'duplicates-report',
  CandidatesCreationConflict = '/candidates/consolidation/candidates-creation-conflict',
  CandidatesEditionConflict = '/candidates/consolidation/candidates-edition-conflict',
  CandidatesDiffsMerge = '/candidates/consolidation/candidates-diffs-merge',
  ConsolidationViewCandidate = '/candidates/consolidation/view-candidate',
  ConsolidationEditCandidate = '/candidates/consolidation/edit-candidate',
  CandidatesDuplicatesReport = '/candidates/consolidation/duplicates-report',
  BatchConsolidation = '/candidates/batch-consolidation/:id',
  BatchConsolidationCreation = '/candidates/batch-consolidation/creation',
  BatchConsolidationParallelEdit = '/candidates/batch-consolidation/edit-parallel',
}
