import { CurrencyEnum, EmploymentFormsEnum, WorkFormatsEnum, WorkLoadsEnum } from '@constants';
import { RequestItem } from '@innowise-group/core';

export const requestDefaultWorkFormats = [
  { dictionaryValueId: WorkFormatsEnum.Office, checked: false },
  { dictionaryValueId: WorkFormatsEnum.Mixed, checked: false },
  { dictionaryValueId: WorkFormatsEnum.Remote, checked: false },
];

export const requestDefaultWorkloads = [
  { dictionaryValueId: WorkLoadsEnum.PartTime, checked: false },
  { dictionaryValueId: WorkLoadsEnum.FullTime, checked: false },
];

export const requestDefaultEmploymentForms = [
  { dictionaryValueId: EmploymentFormsEnum.Employee, checked: false },
  { dictionaryValueId: EmploymentFormsEnum.Contractor, checked: false },
  { dictionaryValueId: EmploymentFormsEnum.Entrepreneur, checked: false },
  { dictionaryValueId: EmploymentFormsEnum.Internship, checked: false },
];

export const requestFormDefaultValues: RequestItem = {
  authorId: [],
  currency: CurrencyEnum.USD,
  vacancyId: '',
  priority: '',
  requiredCount: 1,
  cities: [],
  countries: [],
  comment: '',
  offices: [],
  employmentForms: requestDefaultEmploymentForms,
  workFormats: requestDefaultWorkFormats,
  workloads: requestDefaultWorkloads,
  hiringDeadline: null,
  isCommentSecured: false,
  structuralUnit: '',
  asEmployee: false,
  onProject: false,
  expectedSalaryMin: '',
  expectedSalaryMax: '',
};
