import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../store';
import i18n from '@localization';
import { VacancyItem } from './vacancies.types';
import { SelectOption } from '@innowise-group/core';
import { PurposeEnum, VacancyStatusesWithoutRequests } from '@constants';
import { localizedNameObject } from '@innowise-group/utilities';

export const getVacanciesState = (state: RootState) => state.vacancies;

export const getVacanciesItemsState = createSelector(getVacanciesState, (state) => {
  const items = state.vacancies.ids.reduce((accum, curr) => {
    accum[curr] = {
      ...state.vacancies.items[curr],
      ...(state.vacancies.items[curr].author && {
        author: { ...state.vacancies.items[curr].author, ...localizedNameObject(state.vacancies.items[curr].author) },
      }),
    };
    return accum;
  }, {});
  const currentVacancy = state.vacancies.currentVacancy
    ? {
        ...state.vacancies.currentVacancy,
        author: {
          ...state.vacancies.currentVacancy.author,
          ...localizedNameObject(state.vacancies.currentVacancy.author),
        },
      }
    : null;
  const currentCandidateVacancies = state.vacancies.currentCandidateVacancies?.map((item) => {
    return { ...item, ...(item.author && { author: { ...item.author, ...localizedNameObject(item.author) } }) };
  });
  return { ...state.vacancies, items, currentVacancy, currentCandidateVacancies };
});

export const getVacanciesOptions = (isRequired: boolean, withoutClosed?: boolean) =>
  createSelector(getVacanciesState, (state) => {
    let options: SelectOption[] = [];
    if (!isRequired) {
      options = [{ value: '0', title: i18n.t('pages.candidates.comments.jobOpening') }];
    }
    const items = Object.values(state.vacancies.items);
    options = [
      ...options,
      ...Object.values(
        withoutClosed
          ? items.filter((item) => !VacancyStatusesWithoutRequests.includes(item.vacancyStatus.valueId))
          : items,
      ).map((item) => {
        return { value: item.id.toString(), title: item.name };
      }),
    ];
    return options;
  });

export const getVacancyItemById = (id?: string) =>
  createSelector(getVacanciesItemsState, (state) => {
    if (!id) return null;
    return state.items[id] || null;
  });

export const getCurrentVacancy = createSelector(getVacanciesState, (state) => {
  return state.vacancies.currentVacancy
    ? {
        ...state.vacancies.currentVacancy,
        author: {
          ...state.vacancies.currentVacancy.author,
          ...localizedNameObject(state.vacancies.currentVacancy.author),
        },
      }
    : null;
});

export const getDefaultVacancyItemById = createSelector(getVacanciesState, (state) => {
  const vacancy = state.vacancies.currentVacancy || null;
  if (!vacancy) return null;
  const newVacancy: VacancyItem = {
    name: vacancy.name,
    vacancyStatusId: vacancy.vacancyStatus.valueId,
    published: vacancy.published,
    hiringPurpose: vacancy.hiringPurpose || PurposeEnum.Employment,
    cities: vacancy.cities.map((item) => ({
      title: item.name,
      value: item.id.toString(),
      parent: item.countryId.toString(),
    })),
    structuralUnit: vacancy.structuralUnit?.id.toString() || '',
    countries: vacancy.countries.map((item) => ({ title: item.name, value: item.id.toString() })),
    comment: vacancy.comment,
    vacancyDomains: vacancy.vacancyDomains.map((item) => {
      return { value: item.dictionaryValue.valueId, title: item.dictionaryValue.translation };
    }),
    vacancyLanguages: vacancy.vacancyLanguages.length
      ? vacancy.vacancyLanguages.map((item) => {
          return {
            id: item.id,
            language: item.language || '',
            languageLevel: item.languageLevel || '',
          };
        })
      : [{ language: '', languageLevel: '' }],
    vacancyProfessions: vacancy.vacancyProfessions.length
      ? vacancy.vacancyProfessions.map((item) => {
          return { id: item.id, profession: item.profession.valueId || '', grade: item.grade || '' };
        })
      : [{ profession: '', grade: '' }],
    vacancyTechnologies: vacancy.vacancyTechnologies.map((item) => {
      return { value: item.technology.valueId, title: item.technology.translation };
    }),
  };
  return newVacancy;
});
