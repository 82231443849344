import { FiltersEntity, FiltersState, useAppDispatch, useAppSelector, useProfileAPI } from '@innowise-group/core';
import { useCallback, useMemo } from 'react';
import {
  addAndOpenFilterAction,
  addFilterAction,
  clearFiltersAction,
  closeFilterAction,
  onFilterChangeAction,
  removeFilterAction,
  resetSelectedAction,
  setCandidateFiltersOptionsAction,
  setCurrentTabAction,
  setPagableAction,
  setPageSortAction,
  setRequestFiltersOptionsAction,
  setSelectedAction,
  setVacancyFiltersOptionsAction,
  toggleFilterAction,
} from './fiters.actions';
import { getFiltersStateSelector } from './filters.selectors';
import {
  candidateLifecycleStatusSerializer,
  candidateResponsibleEmployeeDataSerializer,
  candidateStringDataSerializer,
  lifecycleStatusSerializer,
  serializeCandidateWorkFormatsData,
  serializeNestedSelectData,
  serializeRequestAuthorEmploye,
  serializeRequestDate,
  serializeRequestWorkLoads,
  serializeSelectData,
  serializeSourse,
  serializeSingleSelectOptionUnit,
  serializerAgeRangeData,
  serializerBooleanData,
  serializerDateRangeData,
  serializerMoneyData,
  serializerNumberRangeData,
  serializerPlaceDataFilter,
  serializeOverdueData,
} from './filters.mappers';
import { preloadedState } from './filters.preloaded-state';
import { getCandidatesStatusesSelectOptionsThunk, getInitialCandidatesFilterThunk } from './filters.thunks';
import { SortingValue } from '@constants';

export const useFiltersData = () => {
  return useAppSelector(getFiltersStateSelector);
};

export const usePagable = () => {
  const { pagable } = useFiltersData();
  const { setPagable } = useFilterMethods();
  return { ...pagable, setPagable };
};

export const useFilterMethods = (entity: keyof FiltersEntity = 'candidate') => {
  const dispatch = useAppDispatch();

  const resetFiltersState = useCallback(
    (blackListFields?: Parameters<typeof clearFiltersAction>[0]) => dispatch(clearFiltersAction(blackListFields)),
    [dispatch],
  );

  const getCandidatesStatusesSelectOptions = useCallback(() => {
    return dispatch(getCandidatesStatusesSelectOptionsThunk());
  }, [dispatch]);

  const getInitialCandidatesFilter = useCallback(
    (...args: Parameters<typeof getInitialCandidatesFilterThunk>) => {
      return dispatch(getInitialCandidatesFilterThunk(...args));
    },
    [dispatch],
  );

  const setCandidateFiltersOptions = useCallback(
    (args: Parameters<typeof setCandidateFiltersOptionsAction>[0]) => {
      dispatch(setCandidateFiltersOptionsAction(args));
    },
    [dispatch],
  );

  const setVacancyFiltersOptions = useCallback(
    (args: Parameters<typeof setVacancyFiltersOptionsAction>[0]) => {
      dispatch(setVacancyFiltersOptionsAction(args));
    },
    [dispatch],
  );

  const setRequestFiltersOptions = useCallback(
    (args: Parameters<typeof setRequestFiltersOptionsAction>[0]) => {
      dispatch(setRequestFiltersOptionsAction(args));
    },
    [dispatch],
  );

  const addAndOpenFilter = useCallback(
    (filter: Pick<Parameters<typeof addAndOpenFilterAction>[0], 'filter'>['filter']) =>
      dispatch(addAndOpenFilterAction({ filter, entity })),
    [dispatch],
  );

  const addFilter = useCallback(
    (filter: Pick<Parameters<typeof addFilterAction>[0], 'filter'>['filter']) =>
      dispatch(addFilterAction({ filter, entity })),
    [dispatch],
  );

  const closeFilter = useCallback(
    (filter: Pick<Parameters<typeof closeFilterAction>[0], 'filter'>['filter']) =>
      dispatch(closeFilterAction({ filter, entity })),
    [dispatch],
  );

  const removeFilter = useCallback(
    (args: Omit<Parameters<typeof removeFilterAction>[0], 'entity'>) =>
      dispatch(removeFilterAction({ ...args, entity })),
    [dispatch],
  );

  const toggleFilter = useCallback(
    (filter: Pick<Parameters<typeof toggleFilterAction>[0], 'filter'>['filter']) =>
      dispatch(toggleFilterAction({ filter, entity })),
    [dispatch],
  );

  const onFilterChange = useCallback(
    (args: Omit<Parameters<typeof onFilterChangeAction>[0], 'entity'>) =>
      dispatch(onFilterChangeAction({ entity, ...args })),
    [dispatch],
  );

  const setPagable = useCallback(
    (args: Parameters<typeof setPagableAction>[0]) => dispatch(setPagableAction(args)),
    [dispatch],
  );

  const setSelected = useCallback(
    (args: Parameters<typeof setSelectedAction>[0]) => dispatch(setSelectedAction(args)),
    [dispatch],
  );

  const resetSelected = useCallback(() => dispatch(resetSelectedAction()), [dispatch]);

  const setPageTab = useCallback(
    (args: Parameters<typeof setCurrentTabAction>[0]) => dispatch(setCurrentTabAction(args)),
    [dispatch],
  );

  const setPageSort = useCallback(
    (value: Parameters<typeof setPageSortAction>[0]['value']) => dispatch(setPageSortAction({ entity, value })),
    [dispatch],
  );

  return {
    addFilter,
    addAndOpenFilter,
    closeFilter,
    removeFilter,
    toggleFilter,
    onFilterChange,
    setPageSort,
    setPagable,
    setSelected,
    resetSelected,
    setPageTab,
    resetFiltersState,

    setCandidateFiltersOptions,
    setVacancyFiltersOptions,
    setRequestFiltersOptions,
    getInitialCandidatesFilter,
    getCandidatesStatusesSelectOptions,
  };
};

export const useCandidateFilters = () => {
  const { candidate, responsible, currentTab, pagable, candidateOptions, sort, ...rest } = useFiltersData();
  const { ...filterMethods } = useFilterMethods('candidate');
  const { profile } = useProfileAPI();

  const reducedCandidateFilters = useMemo(() => {
    const currentRole = profile?.role?.name;

    return Object.keys(candidate).reduce((acc: FiltersState['candidate'], filterKey) => {
      if (
        currentRole &&
        Array.isArray(candidate[filterKey]?.availableForRoles) &&
        candidate[filterKey].availableForRoles.length > 0
      ) {
        if (!candidate[filterKey].availableForRoles.includes(currentRole)) {
          return acc;
        }
      }

      acc[filterKey] = candidate[filterKey];

      return acc;
    }, {} as FiltersState['candidate']) as FiltersState['candidate'];
  }, [candidate, profile?.role?.name]);

  const serialieFilters = (entity: FiltersState['candidate']) => ({
    ...serializeSourse('sourceFilter', entity),
    ...serializerMoneyData('salary', 'salaryMin', 'salaryMax', entity),
    ...serializeSelectData('citizenship', entity),
    ...serializeSelectData('countries', entity),
    ...serializeSelectData('cities', entity),
    ...serializeSelectData('relocationCountries', entity),
    ...serializeSelectData('visaTypes', entity),
    ...serializeSelectData('statuses', entity),
    ...serializeSelectData('technologies', entity),
    ...serializeSelectData('domains', entity),
    ...serializerBooleanData('isBlocked', entity),
    ...serializerBooleanData('visaAvailability', entity),
    ...serializerBooleanData('relocationAvailability', entity),
    ...serializerBooleanData('readyForBusinessTrip', entity),
    ...serializerAgeRangeData('ageMin', 'ageMax', 'age', entity),
    ...serializerDateRangeData('birthDateMin', 'birthDateMax', 'birthDate', entity),
    ...serializerDateRangeData('statusDateMin', 'statusDateMax', 'candidateStatusDate', entity),
    ...serializerPlaceDataFilter('experienceFilter', entity),
    ...serializerPlaceDataFilter('courseFilter', entity),
    ...serializerPlaceDataFilter('educationFilter', entity),
    ...serializeNestedSelectData('statuses', 'status', 'substatuses', entity),
    ...serializeNestedSelectData('professionFilter', 'profession', 'grades', entity),
    ...serializeNestedSelectData('languageFilter', 'language', 'grades', entity),
    ...serializerNumberRangeData('overallJobExperienceMin', 'overallJobExperienceMax', 'overallJobExperience', entity),
    ...serializerNumberRangeData(
      'itSphereJobExperienceMin',
      'itSphereJobExperienceMax',
      'itSphereJobExperience',
      entity,
    ),
    ...serializeSingleSelectOptionUnit('gender', entity),
    ...candidateStringDataSerializer('name', 'searchBarRequest', entity),
    ...candidateLifecycleStatusSerializer('lifecycleStatus', currentTab),
    ...serializeCandidateWorkFormatsData('workFormats', entity, candidateOptions),
    ...candidateResponsibleEmployeeDataSerializer('responsible', responsible, currentTab),
    ...serializeRequestAuthorEmploye('employe', 'responsible', entity),
    ...(sort.candidate === SortingValue.Checked && { selectedCandidates: rest.selected }),
  });

  const getSerializedCandidateFilters = () => serialieFilters(reducedCandidateFilters);
  const getInitialCandidateFilters = () => serialieFilters(preloadedState.candidate);

  const serializedCandidateFilters = useMemo(getSerializedCandidateFilters, [
    responsible,
    currentTab,
    candidate.name.state.value,
    pagable,
    sort,
  ]);

  return {
    getSerializedCandidateFilters,
    getInitialCandidateFilters,
    filtersConfig: reducedCandidateFilters,
    serializedCandidateFilters,
    responsible,
    currentTab,
    pagable,
    sort,
    ...filterMethods,
    ...rest,
  };
};

export const useRequestsFilters = () => {
  const { ...filterMethods } = useFilterMethods('request');
  const { request, responsible, currentTab, pagable, sort, ...rest } = useFiltersData();

  const serialieFilters = (entity: FiltersState['request']) => ({
    ...lifecycleStatusSerializer('lifecycleStatus', currentTab),

    ...serializeSelectData('countries', entity),
    ...serializeSelectData('officeIds', entity),
    ...serializeSelectData('workFormats', entity),
    ...serializeSelectData('employmentForms', entity),
    ...serializeSelectData('statuses', entity),
    ...serializeSelectData('priority', entity),

    ...serializeRequestDate('hiringDeadline', entity),

    ...serializeOverdueData('isOverdue', entity),

    ...serializeRequestWorkLoads('workload', entity),
    ...serializeSingleSelectOptionUnit('structuralUnitId', entity),
    ...serializeSingleSelectOptionUnit('hiringPurpose', entity),

    ...candidateStringDataSerializer('searchBarValue', 'searchBarValue', entity),
    ...candidateResponsibleEmployeeDataSerializer('authorIds', responsible, currentTab),
    ...serializeRequestAuthorEmploye('employe', 'authorIds', entity),
    ...serializeRequestAuthorEmploye('vacancyIds', 'vacancyIds', entity),
  });

  const getSerializedRequestFilters = () => serialieFilters(request);
  const getInitialRequestFilters = () => serialieFilters(preloadedState.request);

  const serializedRequestFilters = useMemo(getSerializedRequestFilters, [
    responsible,
    currentTab,
    request.searchBarValue.state.value,
    pagable,
    sort,
  ]);

  return {
    getSerializedRequestFilters,
    getInitialRequestFilters,
    serializedRequestFilters,
    filtersConfig: request,
    request,
    responsible,
    currentTab,
    pagable,
    sort,
    ...filterMethods,
    ...rest,
  };
};

export const useVacancyFilters = () => {
  const { ...filterMethods } = useFilterMethods('vacancy');
  const { vacancy, responsible, currentTab, pagable, sort, ...rest } = useFiltersData();

  const serialieFilters = (entity: FiltersState['vacancy']) => ({
    ...lifecycleStatusSerializer('lifecycleStatus', currentTab),
    ...serializeSelectData('countries', entity),
    ...serializeSelectData('statusIds', entity),
    ...serializeSelectData('technologyIds', entity),
    ...serializeSelectData('domainIds', entity),
    ...serializerBooleanData('published', entity),
    ...serializeNestedSelectData('languageFilter', 'language', 'grades', entity),
    ...serializeNestedSelectData('professionFilter', 'professionId', 'grades', entity),
    ...candidateStringDataSerializer('searchBarRequest', 'searchBarRequest', entity),
    ...candidateResponsibleEmployeeDataSerializer('authorIds', responsible, currentTab),
    ...serializeRequestAuthorEmploye('employe', 'authorIds', entity),
  });

  const getSerializedVacancyFilters = () => serialieFilters(vacancy);
  const getInitialVacancyFilters = () => serialieFilters(preloadedState.vacancy);

  const serializedRequestFilters = useMemo(getSerializedVacancyFilters, [
    responsible,
    currentTab,
    vacancy.searchBarRequest.state.value,
    pagable,
    sort,
  ]);

  return {
    getSerializedVacancyFilters,
    getInitialVacancyFilters,
    serializedRequestFilters,
    filtersConfig: vacancy,
    vacancy,
    responsible,
    currentTab,
    pagable,
    sort,
    ...filterMethods,
    ...rest,
  };
};
