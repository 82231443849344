import { AllRolesArray, AppRoutes, RolesValues } from '@constants';

export enum Reports {
  Exits = 'EXITS',
  Statuses = 'STATUSES',
  Status = 'STATUS',
  TeamWork = 'TEAM_WORK',
  Letters = 'LETTERS',
  Employee = 'EMPLOYEE',
  Requests = 'REQUESTS',
  Duplicates = 'DUPLICATES',
  Summary = 'SUMMARY',
}

interface Report {
  title: string;
  value: Reports;
  path: string;
  roles: RolesValues[];
}

export const reports: Report[] = [
  {
    title: 'buttons.statuses',
    value: Reports.Statuses,
    path: AppRoutes.AnalyticsStatusesPage,
    roles: [RolesValues.Admin, RolesValues.Recruiter, RolesValues.Supervisor],
  },
  {
    title: 'buttons.status',
    value: Reports.Status,
    path: AppRoutes.AnalyticsStatusPage,
    roles: [RolesValues.Admin, RolesValues.Supervisor],
  },
  {
    title: 'buttons.teamWork',
    value: Reports.TeamWork,
    path: AppRoutes.AnalyticsTeamWorkingPage,
    roles: [RolesValues.Admin, RolesValues.Supervisor],
  },
  {
    title: 'buttons.letters',
    value: Reports.Letters,
    path: AppRoutes.AnalyticsLettersPage,
    roles: [RolesValues.Admin, RolesValues.Supervisor],
  },
  {
    title: 'buttons.exits',
    value: Reports.Exits,
    path: AppRoutes.AnalyticsExitsPage,
    roles: [RolesValues.Admin, RolesValues.Supervisor],
  },
  {
    title: 'buttons.summary',
    value: Reports.Summary,
    path: AppRoutes.AnalyticsSummaryPage,
    roles: [RolesValues.Admin, RolesValues.Supervisor],
  },
  {
    title: 'buttons.requests',
    value: Reports.Requests,
    path: AppRoutes.AnalyticsRequestsPage,
    roles: AllRolesArray,
  },
  {
    title: 'buttons.employee',
    value: Reports.Employee,
    path: AppRoutes.AnalyticsEmployeePage,
    roles: [RolesValues.Admin, RolesValues.Supervisor, RolesValues.Recruiter, RolesValues.Sourcer],
  },
  // TODO: Return back after working on backend side.
  // {
  //   title: 'buttons.duplicatesReport',
  //   value: Reports.Duplicates,
  //   path: AppRoutes.AnalyticsDuplicatesReport,
  //   roles: [RolesValues.Admin, RolesValues.Recruiter, RolesValues.Sourcer],
  // },
];
