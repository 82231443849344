import { DateFormats } from '@constants';
import { format } from 'date-fns';
import {
  ListItemsResponse,
  MyKnownError,
  RequestItem,
  RequestItemResponse,
  RequestsService,
  RequestsCandidateItemResponse,
  RequestLoggerItemResponse,
} from '@innowise-group/core';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { delay } from '@innowise-group/utilities';

export const createRequestThunk = createAsyncThunk<
  RequestItemResponse,
  { data: RequestItem; successCallback?: (requestId: number) => void },
  { rejectValue: MyKnownError }
>('requests/create', async ({ data, successCallback }, { rejectWithValue }) => {
  try {
    const newData = {
      asEmployee: data.asEmployee,
      onProject: data.onProject,
      expectedSalaryMin: data.expectedSalaryMin || null,
      expectedSalaryMax: data.expectedSalaryMax || null,
      structuralUnit: data.structuralUnit,
      currency: data.currency,
      authorId: data.authorId.map((item) => item.value),
      vacancyId: data.vacancyId,
      requiredCount: data.requiredCount,
      cities: data.cities.map((item) => item.value),
      countries: data.countries.map((item) => item.value),
      comment: data.comment,
      offices: data.offices.map((item) => item.value),
      hiringDeadline: data.hiringDeadline
        ? format(new Date(data.hiringDeadline).setHours(23, 59, 59, 999), DateFormats.ISO)
        : '',
      workloads: data.workloads.reduce((accum, item) => {
        if (item.checked) {
          accum.push(item.dictionaryValueId);
        }
        return accum;
      }, []),
      priority: data.priority,
      workFormats: data.workFormats.reduce((accum, item) => {
        if (item.checked) {
          accum.push(item.dictionaryValueId);
        }
        return accum;
      }, []),
      employmentForms: data.employmentForms.reduce((accum, item) => {
        if (item.checked) {
          accum.push(item.dictionaryValueId);
        }
        return accum;
      }, []),
      isCommentSecured: data.isCommentSecured,
    };
    const response = await RequestsService.createRequest(newData);
    successCallback?.(response.data.id);
    return response.data;
  } catch (err) {
    return rejectWithValue({
      errorMessage: err.message,
    } as MyKnownError);
  }
});

export const getRequestByIdThunk = createAsyncThunk<RequestItemResponse, { id: number }, { rejectValue: MyKnownError }>(
  'requests/getItem',
  async ({ id }, { rejectWithValue }) => {
    try {
      const response = await RequestsService.getVacancyRequestById(id);
      return { ...response.data, linkedCandidatesIsLoading: false, linkedCandidatesIds: [], linkedCandidatesItems: {} };
    } catch (err) {
      return rejectWithValue({
        errorMessage: err.message,
      } as MyKnownError);
    }
  },
);

export const deleteRequestThunk = createAsyncThunk<
  void,
  { id: number; successCallback?: () => void },
  { rejectValue: MyKnownError }
>('requests/delete', async ({ id, successCallback }, { rejectWithValue }) => {
  try {
    await RequestsService.deleteRequestById(id);
    successCallback?.();
  } catch (err) {
    return rejectWithValue({
      errorMessage: err.message,
    } as MyKnownError);
  }
});

export const updateRequestThunk = createAsyncThunk<
  RequestItemResponse,
  { data: RequestItem; id: number; successCallback?: (requestId: number) => void },
  { rejectValue: MyKnownError }
>('requests/update', async ({ data, id, successCallback }, { rejectWithValue }) => {
  try {
    const newData = {
      asEmployee: data.asEmployee,
      onProject: data.onProject,
      expectedSalaryMin: data.expectedSalaryMin || null,
      expectedSalaryMax: data.expectedSalaryMax || null,
      structuralUnit: data.structuralUnit,
      currency: data.currency,
      authorId: data.authorId.map((item) => item.value),
      vacancyId: data.vacancyId,
      requiredCount: data.requiredCount,
      cities: data.cities.map((item) => item.value),
      countries: data.countries.map((item) => item.value),
      comment: data.comment,
      offices: data.offices.map((item) => item.value),
      hiringDeadline: data.hiringDeadline
        ? format(new Date(data.hiringDeadline).setHours(23, 59, 59, 999), DateFormats.ISO)
        : '',
      workloads: data.workloads.reduce((accum, item) => {
        if (item.checked) {
          accum.push(item.dictionaryValueId);
        }
        return accum;
      }, []),
      priority: data.priority,
      workFormats: data.workFormats.reduce((accum, item) => {
        if (item.checked) {
          accum.push(item.dictionaryValueId);
        }
        return accum;
      }, []),
      employmentForms: data.employmentForms.reduce((accum, item) => {
        if (item.checked) {
          accum.push(item.dictionaryValueId);
        }
        return accum;
      }, []),
      isCommentSecured: data.isCommentSecured,
    };
    const response = await RequestsService.updateRequest(newData, id);

    if (typeof successCallback === 'function') {
      await delay(500);
      successCallback(response.data.id);
    }

    return response.data;
  } catch (err) {
    return rejectWithValue({
      errorMessage: err.message,
    } as MyKnownError);
  }
});

export const getAllRequestsThunk = createAsyncThunk<
  ListItemsResponse<RequestItemResponse>,
  Parameters<typeof RequestsService.getAllRequests>[0],
  { rejectValue: MyKnownError }
>('requests/getAll', async (data, { rejectWithValue }) => {
  try {
    const response = await RequestsService.getAllRequests(data);
    return {
      ...response.data,
      content: response.data.content.map((item) => {
        return { ...item, linkedCandidatesIsLoading: false, linkedCandidatesIds: [], linkedCandidatesItems: {} };
      }),
    };
  } catch (err) {
    return rejectWithValue({
      errorMessage: err.message,
    } as MyKnownError);
  }
});

export const exportRequestsThunk = createAsyncThunk<
  void,
  Parameters<typeof RequestsService.requestsExport>[0],
  { rejectValue: MyKnownError }
>('requests/export', async (body, { rejectWithValue }) => {
  try {
    const response = await RequestsService.requestsExport(body);
    const data = URL.createObjectURL(response.data);
    const fileUrl = document.createElement('a');
    fileUrl.href = data;
    fileUrl.download = 'export-results.xlsx';
    document.body.appendChild(fileUrl);
    fileUrl.click();
    URL.revokeObjectURL(fileUrl.href);
  } catch (err) {
    return rejectWithValue({
      errorMessage: err.message,
    } as MyKnownError);
  }
});

export const getSelectedCandidatesByRequestThunk = createAsyncThunk<
  RequestsCandidateItemResponse[],
  Parameters<typeof RequestsService.getSelectedCandidates>[0],
  { rejectValue: MyKnownError }
>('requests/getSelectedCandidates', async (data, { rejectWithValue }) => {
  try {
    const response = await RequestsService.getSelectedCandidates(data);
    return response.data;
  } catch (err) {
    return rejectWithValue({
      errorMessage: err.message,
    } as MyKnownError);
  }
});

export const approveSelectedCandidatesFromVacancyResponseThunk = createAsyncThunk<
  void,
  { params: Parameters<typeof RequestsService.approveSelectedCandidatesFromVacancyResponse>[0]; candidateId: string },
  { rejectValue: MyKnownError }
>('requests/approveSelectedCandidatesFromVacancyResponse', async ({ params }, { rejectWithValue }) => {
  try {
    await RequestsService.approveSelectedCandidatesFromVacancyResponse(params);
    return;
  } catch (err) {
    return rejectWithValue({
      errorMessage: err.message,
    } as MyKnownError);
  }
});

export const deleteCandidateFromVacancyResponseThunk = createAsyncThunk<
  string,
  { params: Parameters<typeof RequestsService.deleteCandidateFromVacancyResponse>[0]; responseId: string },
  { rejectValue: MyKnownError }
>('requests/deleteCandidateFromVacancyResponse', async ({ params, responseId }, { rejectWithValue }) => {
  try {
    await RequestsService.deleteCandidateFromVacancyResponse(params);
    return responseId;
  } catch (err) {
    return rejectWithValue({
      errorMessage: err.message,
    } as MyKnownError);
  }
});

export const getRequestLoggerDataThunk = createAsyncThunk<
  ListItemsResponse<RequestLoggerItemResponse>,
  { requestId: number; params: { page: number; size: number } },
  { rejectValue: MyKnownError }
>('requests/getRequestLoggerData', async ({ params, requestId }, { rejectWithValue }) => {
  try {
    const response = await RequestsService.getHistoryByRequest(requestId, params);
    return response.data;
  } catch (err) {
    return rejectWithValue({
      errorMessage: err.message,
    } as MyKnownError);
  }
});
