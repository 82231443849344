import React, { useEffect, useMemo } from 'react';
import * as Styled from './offer-form.styles';
import { Controller, useFormContext } from 'react-hook-form';
import {
  CandidateOfferItem,
  useCandidateOffersAPI,
  useOptionsApi,
  useProfileAPI,
  useUnitsAPI,
  useUnitsAdministrationOptions,
} from '@innowise-group/core';
import { Input, Select } from '@innowise-group/mui-kit';
import { useTranslation } from 'react-i18next';
import { DatePicker } from '@shared-mui-components';
import { RolesValues } from '@constants';

interface OfferFormProps {
  disabled: boolean;
}

const OfferForm: React.FC<OfferFormProps> = ({ disabled }) => {
  const { t } = useTranslation();
  const { profile } = useProfileAPI();
  const { countryOptions, officeOptions, locationOptions, jobTitleOptions, currencyOptions } = useOptionsApi();
  const unitsOptions = useUnitsAdministrationOptions();
  const { offersTypesOptions } = useCandidateOffersAPI();
  const { getActualUnits, isLoading: isUnitsLoading, clearUnitsState } = useUnitsAPI();
  const { control, watch, setValue, trigger } = useFormContext<CandidateOfferItem>();
  const country = watch('country');
  const city = watch('city');
  const office = watch('office');
  const currency = watch('currency');

  const locationCountryOptions = useMemo(() => {
    return countryOptions.filter((item) =>
      locationOptions.find((curr) => curr.parent.toString() === item.value.toString()),
    );
  }, [locationOptions, countryOptions]);

  const locationCityOptions = useMemo(() => {
    return locationOptions.filter((item) => country === item.parent);
  }, [locationOptions, country]);

  const offices = useMemo(() => {
    return officeOptions?.filter((item) => city === item.parent);
  }, [officeOptions, city]);

  useEffect(() => {
    trigger(['salaryGross', 'salaryProbationGross', 'salaryProjectGross']);
  }, [trigger, currency]);

  useEffect(() => {
    if (!locationCityOptions?.find((item) => item.value === city) || !country) {
      setValue('city', '');
    }
  }, [country]);

  useEffect(() => {
    if (!offices?.find((item) => item.value === office) || !city) {
      setValue('office', '');
    }
  }, [city]);

  useEffect(() => {
    getActualUnits();
    return () => {
      clearUnitsState();
    };
  }, []);

  return (
    <Styled.FormContainer>
      <Controller
        name="offerType"
        control={control}
        render={({ field: { value, onChange }, formState: { errors } }) => {
          return (
            <Styled.TwoColumnCell>
              <Select
                value={value}
                onChange={onChange}
                options={offersTypesOptions}
                fullWidth
                disabled={disabled}
                renderInput={(params) => {
                  return (
                    <Input
                      {...params}
                      required
                      label={t('pages.candidates.offer.offerType')}
                      placeholder={t('pages.candidates.offer.offerTypePlaceholder')}
                      helperText={errors?.offerType?.message?.toString()}
                      error={!!errors?.offerType?.message}
                    />
                  );
                }}
              />
            </Styled.TwoColumnCell>
          );
        }}
      />
      <Controller
        name="offerDate"
        control={control}
        render={({ field: { value, onChange }, formState: { errors } }) => {
          return (
            <Styled.TwoColumnCell>
              <DatePicker
                selected={value ? new Date(value) : null}
                onChange={onChange}
                required
                disabled={profile?.role?.name !== RolesValues.Admin || disabled}
                label={t('pages.candidates.offer.offerDate')}
                placeholder={t('pages.candidates.offer.enterTheDate')}
                error={!!errors?.offerDate?.message}
                helperText={errors?.offerDate?.message as string}
              />
            </Styled.TwoColumnCell>
          );
        }}
      />
      <Controller
        name="currency"
        control={control}
        render={({ field: { value, onChange }, formState: { errors } }) => {
          return (
            <Styled.TwoColumnCell>
              <Select
                value={value}
                onChange={onChange}
                options={currencyOptions}
                fullWidth
                disableClearable
                disabled={disabled}
                renderInput={(params) => {
                  return (
                    <Input
                      {...params}
                      required
                      label={t('pages.candidates.candidateCreation.currency')}
                      placeholder={t('pages.candidates.candidateCreation.selectCurrency')}
                      helperText={errors?.currency?.message?.toString()}
                      error={!!errors?.currency?.message}
                    />
                  );
                }}
              />
            </Styled.TwoColumnCell>
          );
        }}
      />
      <Controller
        name="salaryGross"
        control={control}
        render={({ field: { value, onChange }, formState: { errors } }) => {
          return (
            <Styled.TwoColumnCell>
              <Input
                value={value}
                onChange={onChange}
                fullWidth
                disabled={disabled}
                label={t('pages.candidates.offer.salary')}
                placeholder={t('pages.candidates.candidateCreation.enterAValue')}
                error={!!errors?.salaryGross?.message}
                helperText={errors?.salaryGross?.message?.toString()}
              />
            </Styled.TwoColumnCell>
          );
        }}
      />
      <Controller
        name="salaryProbationGross"
        control={control}
        render={({ field: { value, onChange }, formState: { errors } }) => {
          return (
            <Styled.TwoColumnCell>
              <Input
                value={value}
                onChange={onChange}
                fullWidth
                disabled={disabled}
                label={t('pages.candidates.offer.probationSalary')}
                placeholder={t('pages.candidates.candidateCreation.enterAValue')}
                error={!!errors?.salaryProbationGross?.message}
                helperText={errors?.salaryProbationGross?.message?.toString()}
              />
            </Styled.TwoColumnCell>
          );
        }}
      />
      <Controller
        name="salaryProjectGross"
        control={control}
        render={({ field: { value, onChange }, formState: { errors } }) => {
          return (
            <Styled.TwoColumnCell>
              <Input
                value={value}
                onChange={onChange}
                fullWidth
                disabled={disabled}
                label={t('pages.candidates.offer.projectSalary')}
                placeholder={t('pages.candidates.candidateCreation.enterAValue')}
                error={!!errors?.salaryProjectGross?.message}
                helperText={errors?.salaryProjectGross?.message?.toString()}
              />
            </Styled.TwoColumnCell>
          );
        }}
      />
      <Controller
        name="estimatedReleaseDate"
        control={control}
        render={({ field: { value, onChange }, formState: { errors } }) => {
          return (
            <Styled.TwoColumnCell>
              <DatePicker
                selected={value ? new Date(value) : null}
                onChange={onChange}
                disabled={disabled}
                label={t('pages.candidates.offer.entryDate')}
                placeholder={t('pages.candidates.offer.enterTheDate')}
                error={!!errors?.estimatedReleaseDate?.message}
                helperText={errors?.estimatedReleaseDate?.message as string}
              />
            </Styled.TwoColumnCell>
          );
        }}
      />
      <Controller
        name="probationPeriod"
        control={control}
        render={({ field: { value, onChange }, formState: { errors } }) => {
          return (
            <Styled.TwoColumnCell>
              <Input
                value={value}
                onChange={onChange}
                fullWidth
                disabled={disabled}
                label={t('pages.candidates.offer.probationPeriod')}
                placeholder={t('pages.candidates.offer.enterThePeriod')}
                error={!!errors?.probationPeriod?.message}
                helperText={errors?.probationPeriod?.message?.toString()}
              />
            </Styled.TwoColumnCell>
          );
        }}
      />
      <Controller
        name="responseDate"
        control={control}
        render={({ field: { value, onChange }, formState: { errors } }) => {
          return (
            <Styled.TwoColumnCell>
              <DatePicker
                selected={value ? new Date(value) : null}
                onChange={onChange}
                disabled={disabled}
                label={t('pages.candidates.offer.responseDate')}
                placeholder={t('pages.candidates.offer.enterTheDate')}
                error={!!errors?.responseDate?.message}
                helperText={errors?.responseDate?.message as string}
              />
            </Styled.TwoColumnCell>
          );
        }}
      />
      <Controller
        name="structuralUnitId"
        control={control}
        render={({ field: { value, onChange }, formState: { errors } }) => {
          return (
            <Styled.ThreeColumnCell>
              <Select
                value={value}
                onChange={onChange}
                options={unitsOptions}
                loading={isUnitsLoading}
                fullWidth
                disabled={disabled}
                renderInput={(params) => {
                  return (
                    <Input
                      {...params}
                      label={t('pages.candidates.offer.orgUnit')}
                      placeholder={t('pages.candidates.offer.orgUnitPlaceholder')}
                      helperText={errors?.structuralUnitId?.message?.toString()}
                      error={!!errors?.structuralUnitId?.message}
                    />
                  );
                }}
              />
            </Styled.ThreeColumnCell>
          );
        }}
      />
      <Controller
        name="position"
        control={control}
        render={({ field: { value, onChange }, formState: { errors } }) => {
          return (
            <Styled.ThreeColumnCell>
              <Select
                value={value}
                onChange={onChange}
                options={jobTitleOptions}
                disabled={disabled}
                fullWidth
                renderInput={(params) => {
                  return (
                    <Input
                      {...params}
                      required
                      label={t('pages.candidates.offer.position')}
                      placeholder={t('pages.candidates.offer.positionPlaceholder')}
                      helperText={errors?.position?.message?.toString()}
                      error={!!errors?.position?.message}
                    />
                  );
                }}
              />
            </Styled.ThreeColumnCell>
          );
        }}
      />
      <Controller
        name="country"
        control={control}
        render={({ field: { value, onChange }, formState: { errors } }) => {
          return (
            <Styled.ThreeColumnCell>
              <Select
                value={value}
                onChange={onChange}
                options={locationCountryOptions}
                fullWidth
                disabled={disabled}
                renderInput={(params) => {
                  return (
                    <Input
                      {...params}
                      label={t('pages.candidates.candidateCreation.country')}
                      placeholder={t('pages.candidates.candidateCreation.selectCountry')}
                      helperText={errors?.country?.message?.toString()}
                      error={!!errors?.country?.message}
                    />
                  );
                }}
              />
            </Styled.ThreeColumnCell>
          );
        }}
      />
      <Controller
        name="city"
        control={control}
        render={({ field: { value, onChange }, formState: { errors } }) => {
          return (
            <Styled.ThreeColumnCell>
              <Select
                value={value}
                onChange={onChange}
                options={locationCityOptions}
                disabled={!country || disabled}
                fullWidth
                renderInput={(params) => {
                  return (
                    <Input
                      {...params}
                      label={t('pages.candidates.candidateCreation.city')}
                      placeholder={t('pages.candidates.candidateCreation.selectCity')}
                      helperText={errors?.city?.message?.toString()}
                      error={!!errors?.city?.message}
                    />
                  );
                }}
              />
            </Styled.ThreeColumnCell>
          );
        }}
      />
      <Controller
        name="office"
        control={control}
        render={({ field: { value, onChange }, formState: { errors } }) => {
          return (
            <Styled.ThreeColumnCell>
              <Select
                value={value}
                onChange={onChange}
                options={offices}
                disabled={!city || disabled}
                fullWidth
                renderInput={(params) => {
                  return (
                    <Input
                      {...params}
                      label={t('pages.requests.creationModal.office')}
                      placeholder={t('pages.requests.creationModal.selectOffice')}
                      helperText={errors?.office?.message?.toString()}
                      error={!!errors?.office?.message}
                    />
                  );
                }}
              />
            </Styled.ThreeColumnCell>
          );
        }}
      />
      <Controller
        name="project"
        control={control}
        render={({ field: { value, onChange }, formState: { errors } }) => {
          return (
            <Styled.ThreeColumnCell>
              <Input
                value={value}
                onChange={onChange}
                fullWidth
                disabled={disabled}
                label={t('pages.candidates.offer.project')}
                placeholder={t('pages.candidates.offer.enterTheProject')}
                error={!!errors?.project?.message}
                helperText={errors?.project?.message?.toString()}
              />
            </Styled.ThreeColumnCell>
          );
        }}
      />
      <Controller
        name="specialWorkingConditions"
        control={control}
        render={({ field: { value, onChange }, formState: { errors } }) => {
          return (
            <Styled.FullColumnsCell>
              <Input
                value={value}
                onChange={onChange}
                fullWidth
                multiline
                rows={4}
                disabled={disabled}
                label={t('pages.candidates.offer.specialConditions')}
                placeholder={t('pages.candidates.offer.enterTheComment')}
                error={!!errors?.specialWorkingConditions?.message}
                helperText={errors?.specialWorkingConditions?.message?.toString()}
              />
            </Styled.FullColumnsCell>
          );
        }}
      />
    </Styled.FormContainer>
  );
};

export default OfferForm;
