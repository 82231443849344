import { Icon } from '@innowise-group/mui-kit';
import * as Styled from './filter-with-clue.styles';

interface FilterWithClueProps extends React.PropsWithChildren {
  clueText?: string;
  open: boolean;
}

const FilterWithClue: React.FC<FilterWithClueProps> = ({ clueText, open, children }) => {
  return (
    <Styled.Wrapper>
      {open && clueText && (
        <Styled.ClueWrapper>
          <Icon type="u_filled_info" size={20} />
          <span>{clueText}</span>
        </Styled.ClueWrapper>
      )}
      {children}
    </Styled.Wrapper>
  );
};

export default FilterWithClue;
