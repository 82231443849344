import { Drawer as MuiDrawer } from '@mui/material';
import { styled } from '@innowise-group/mui-kit';
import { Link } from 'react-router-dom';

export const Drawer = styled(MuiDrawer)`
  &.MuiDrawer-root.MuiDrawer-modal {
    z-index: 1310;
  }
  & .MuiBackdrop-root.MuiModal-backdrop {
    background-color: transparent;
  }

  & .MuiPaper-root {
    width: 40%;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    max-height: 100%;
    height: 100%;
    border-radius: 8px 0 0 8px;
    background-color: ${({ theme }) => theme.palette.general.white};
    box-shadow: -4px 0px 4px 0px rgba(0, 0, 0, 0.07);
    border: 1px solid ${({ theme }) => theme.palette.general.lightGrey[50]};
  }
`;

export const DrawerHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  width: 100%;
  padding: 0.25rem 1rem 0.125rem 1.5rem;
`;

export const DrawerTitle = styled.p`
  ${({ theme }) => theme.fonts.primary[60]};
  margin-right: auto;
  color: ${({ theme }) => theme.palette.general.darkGrey[100]};
`;

export const DrawerContent = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0;
  padding: 0.5rem 0.75rem;
  overflow-y: auto;
  box-shadow: -7px 0px 7px 0px rgba(0, 0, 0, 0.07);
`;

export const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  grid-column: 1 / -1;
  & .MuiCircularProgress-root {
    margin: 30px auto;
  }
`;

export const CandidateItem = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  padding: 4px 8px;
  gap: 0.75rem;
  min-width: 0;
  overflow-x: hidden;
  overflow-y: visible;
  height: max-content;
`;

export const CandidateInfo = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: start;
  gap: 2px;
  overflow: hidden;
`;

export const CandidateName = styled(Link)`
  ${({ theme }) => theme.fonts.primary[60]};
  color: ${({ theme }) => theme.palette.info.default};
  transition: 0.2s;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow-x: hidden;
  max-width: 100%;
  text-decoration: none;
  cursor: pointer;

  @media (hover: hover) {
    &:hover {
      text-decoration: underline;
      color: ${({ theme }) => theme.palette.info.hover};
    }
  }
`;

export const CandidateContent = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  gap: 0.625rem;
  ${({ theme }) => theme.fonts.primary[40]};
  color: ${({ theme }) => theme.palette.general.darkGrey[90]};
`;

export const CandidateProfession = styled.div`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow-x: hidden;
  max-width: 100%;
`;

export const CandidateIdChip = styled.div`
  border-radius: 1.25rem;
  background-color: ${({ theme }) => theme.palette.general.lightGrey[40]};
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  padding: 2px 8px;
  ${({ theme }) => theme.fonts.primary[20]};
  color: ${({ theme }) => theme.palette.general.darkGrey[90]};
`;
