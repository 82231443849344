import { createReducer } from '@reduxjs/toolkit';
import { preloadedState } from './filters.preloaded-state';
import {
  addAndOpenFilterAction,
  addFilterAction,
  clearFiltersAction,
  closeFilterAction,
  onFilterChangeAction,
  removeFilterAction,
  resetSelectedAction,
  setCandidateFiltersOptionsAction,
  setCurrentTabAction,
  setPagableAction,
  setPageSortAction,
  setRequestFiltersOptionsAction,
  setSelectedAction,
  setVacancyFiltersOptionsAction,
  toggleFilterAction,
} from './fiters.actions';

import {
  candidateStatusFiltersMapper,
  citiesOptionsFilter,
  nestedSelectFilterMapper,
  selectFilterMapper,
} from './filters.mappers';
import { prioritizeCandidatesThunk } from '../candidates';
import { FiltersState } from './filters.types';
import { getInitialCandidatesFilterThunk } from './filters.thunks';
import { logoutAction } from '../common';
import { IntervalFiltersType, defaultCurrency } from '@constants';

export const filtersReducer = createReducer(preloadedState, (builder) =>
  builder
    .addCase(clearFiltersAction, (state, { payload }) => {
      Object.entries(preloadedState).forEach(([key]) => {
        if (!payload?.includes(key as keyof FiltersState)) {
          state[key] = preloadedState[key];
        }
      });
    })

    .addCase(setCandidateFiltersOptionsAction, (state, { payload }) => {
      const { profileId, options: data, reset } = payload;
      // TODO: Remove fix (find the source of empty options)
      const isEmpty = Object.values(data).reduce((acc, val) => {
        if (!val) return true;
        return acc;
      }, false);
      if (isEmpty) return;
      // TODO: Remove fix (find the source of empty options)
      if (reset) {
        state.pagable.currentPage = 1;
      }
      Object.keys(state.candidate).forEach((key) => {
        if (reset) {
          state.pagable.currentPage = 1;
          state.candidate[key] = preloadedState.candidate[key];
        } else {
          state.candidate[key].isOpen = false;
        }
      });
      if (data && profileId) {
        state.candidateInitialOptions = data;
        state.responsible = profileId;
        state.candidateOptions = data;
        state.candidate.statuses = candidateStatusFiltersMapper(state.candidate.statuses, data.statusOptions, reset);
        state.candidate.gender = selectFilterMapper(state.candidate.gender, data.genderOptions, reset);
        state.candidate.domains = selectFilterMapper(state.candidate.domains, data.domainOptions, reset);
        state.candidate.countries = selectFilterMapper(state.candidate.countries, data.countryOptions, reset);
        state.candidate.cities = selectFilterMapper(state.candidate.cities, data.citiesOptions, reset);
        state.candidate.visaTypes = selectFilterMapper(state.candidate.visaTypes, data.visaTypesOptions, reset);
        state.candidate.citizenship = selectFilterMapper(state.candidate.citizenship, data.countryOptions, reset);
        state.candidate.workFormats = selectFilterMapper(
          state.candidate.workFormats,
          [...data.workFormatsOptions, ...data.workloadsOptions, ...data.employmentFormsOptions],
          reset,
        );
        state.candidate.sourceFilter = selectFilterMapper(
          state.candidate.sourceFilter,
          data.candidateSourceOptions,
          reset,
        );
        state.candidate.technologies = selectFilterMapper(state.candidate.technologies, data.technologyOptions, reset);
        state.candidate.relocationCountries = selectFilterMapper(
          state.candidate.relocationCountries,
          data.countryOptions,
          reset,
        );
        state.candidate.professionFilter = nestedSelectFilterMapper(
          state.candidate.professionFilter,
          data.professionOptions,
          data.gradeOptions,
          reset,
        );
        state.candidate.languageFilter = nestedSelectFilterMapper(
          state.candidate.languageFilter,
          data.languageOptions,
          data.languageLevelOptions,
          reset,
        );
      }
    })

    .addCase(setRequestFiltersOptionsAction, (state, { payload }) => {
      const { profileId, options: data, reset } = payload;
      // TODO: Remove fix (find the source of empty options)
      const isEmpty = Object.values(data).reduce((acc, val) => {
        if (!val) return true;
        return acc;
      }, false);
      if (isEmpty) return;
      // TODO: Remove fix (find the source of empty options)
      Object.keys(state.request).forEach((key) => {
        if (reset) {
          state.pagable.currentPage = 1;
          state.request[key] = preloadedState.request[key];
        } else {
          state.request[key].isOpen = false;
        }
      });
      if (data && profileId) {
        state.responsible = profileId;
        state.request.hiringPurpose = selectFilterMapper(
          state.request.hiringPurpose,
          data.vacancyPurposeOptions,
          reset,
        );
        state.request.employmentForms = selectFilterMapper(
          state.request.employmentForms,
          data.employmentFormsOptions,
          reset,
        );
        state.request.structuralUnitId = selectFilterMapper(state.request.structuralUnitId, data.unitsOptions, reset);
        state.request.countries = selectFilterMapper(state.request.countries, data.countryOptions, reset);
        state.request.officeIds = selectFilterMapper(state.request.officeIds, data.officeOptions, reset);
        state.request.statuses = selectFilterMapper(state.request.statuses, data.requestFilterStatusOptions, reset);
        state.request.priority = selectFilterMapper(state.request.priority, data.priorityOptions, reset);
        state.request.isOverdue = selectFilterMapper(state.request.isOverdue, data.overdueOptions, reset);
        state.request.workFormats = selectFilterMapper(state.request.workFormats, data.workFormatsOptions, reset);
        state.request.workload = selectFilterMapper(state.request.workload, data.workloadsOptions, reset);
      }
    })

    .addCase(setVacancyFiltersOptionsAction, (state, { payload }) => {
      const { profileId, options: data, reset } = payload;
      // TODO: Remove fix (find the source of empty options)
      const isEmpty = Object.values(data).reduce((acc, val) => {
        if (!val) return true;
        return acc;
      }, false);
      if (isEmpty) return;
      // TODO: Remove fix (find the source of empty options)
      Object.keys(state.vacancy).forEach((key) => {
        if (reset) {
          state.pagable.currentPage = 1;
          state.vacancy[key] = preloadedState.vacancy[key];
        } else {
          state.vacancy[key].isOpen = false;
        }
      });
      state.responsible = profileId;
      state.vacancy.countries = selectFilterMapper(state.vacancy.countries, data.countryOptions, reset);
      state.vacancy.domainIds = selectFilterMapper(state.vacancy.domainIds, data.domainOptions, reset);
      state.vacancy.languageFilter = nestedSelectFilterMapper(
        state.vacancy.languageFilter,
        data.languageOptions,
        data.languageLevelOptions,
        reset,
      );
      state.vacancy.professionFilter = nestedSelectFilterMapper(
        state.vacancy.professionFilter,
        data.professionOptions,
        data.gradeOptions,
        reset,
      );
      state.vacancy.statusIds = selectFilterMapper(state.vacancy.statusIds, data.vacancyStatus, reset);
      state.vacancy.technologyIds = selectFilterMapper(state.vacancy.technologyIds, data.technologyOptions, reset);
    })

    .addCase(addAndOpenFilterAction, (state, { payload }) => {
      state[payload.entity][payload.filter] = {
        ...preloadedState[payload.entity][payload.filter],
        ...(!state[payload.entity][payload.filter].active && {
          active: true,
          isOpen: true,
        }),
        state: state[payload.entity][payload.filter].state,
      };
    })
    .addCase(addFilterAction, (state, { payload }) => {
      state[payload.entity][payload.filter].active = true;
    })
    .addCase(closeFilterAction, (state, { payload }) => {
      if (payload.filter === 'countries' && payload.entity === 'candidate') {
        state.candidate.cities = citiesOptionsFilter(state);
      }
      state[payload.entity][payload.filter].isOpen = false;
    })
    .addCase(removeFilterAction, (state, { payload }) => {
      state[payload.entity][payload.filter].active = false;
      state[payload.entity][payload.filter].isOpen = false;
      state[payload.entity][payload.filter].state = payload.updatedState;
      if (payload.filter === 'countries' && payload.entity === 'candidate') {
        state.candidate.cities = citiesOptionsFilter(state);
      }
    })
    .addCase(toggleFilterAction, (state, { payload }) => {
      state[payload.entity][payload.filter].isOpen = !state[payload.entity][payload.filter].isOpen;
    })
    .addCase(onFilterChangeAction, (state, { payload }) => {
      state[payload.entity][payload.filter].state = payload.updatedState;
    })

    .addCase(setPagableAction, (state, { payload }) => {
      if (payload?.currentPage) state.pagable.currentPage = payload.currentPage;
      if (payload?.pageSize) state.pagable.pageSize = payload.pageSize;
    })
    .addCase(setSelectedAction, (state, { payload }) => {
      state.selected = state.selected.includes(payload)
        ? state.selected.filter((id) => id !== payload)
        : [...state.selected, payload];
    })
    .addCase(resetSelectedAction, (state) => {
      state.selected = preloadedState.selected;
    })
    .addCase(setCurrentTabAction, (state, { payload }) => {
      state.currentTab = payload;
      state.pagable.currentPage = 1;
    })
    .addCase(setPageSortAction, (state, { payload }) => {
      state.sort[payload.entity] = payload.value;
    })
    .addCase(prioritizeCandidatesThunk.fulfilled, (state) => {
      state.selected = [];
    })

    .addCase(getInitialCandidatesFilterThunk.pending, (state) => {
      state.isLoading = true;
    })
    .addCase(getInitialCandidatesFilterThunk.fulfilled, (state, { payload, meta }) => {
      state.isLoading = false;
      if (meta.arg.intervalType === IntervalFiltersType.Age) {
        if (!state.candidate.age.state.initialDataLoaded) {
          state.candidate.age.state.initialDataLoaded = true;
          state.candidate.age.state.minValue = Number(payload.min);
          state.candidate.age.state.maxValue = Number(payload.max);
          state.candidate.age.state.from = Number(payload.min);
          state.candidate.age.state.to = Number(payload.max);
        }
      }
      if (meta.arg.intervalType === IntervalFiltersType.OverallExperience) {
        if (!state.candidate.overallJobExperience.state.initialDataLoaded) {
          state.candidate.overallJobExperience.state.initialDataLoaded = true;
          state.candidate.overallJobExperience.state.minValue = Math.round(Number(payload.min) / 12);
          state.candidate.overallJobExperience.state.maxValue = Math.ceil(Number(payload.max) / 12);
          state.candidate.overallJobExperience.state.from = Math.round(Number(payload.min) / 12);
          state.candidate.overallJobExperience.state.to = Math.ceil(Number(payload.max) / 12);
        }
      }

      if (meta.arg.intervalType === IntervalFiltersType.ItExperience) {
        if (!state.candidate.itSphereJobExperience.state.initialDataLoaded) {
          state.candidate.itSphereJobExperience.state.initialDataLoaded = true;
          state.candidate.itSphereJobExperience.state.minValue = Math.round(Number(payload.min) / 12);
          state.candidate.itSphereJobExperience.state.maxValue = Math.ceil(Number(payload.max) / 12);
          state.candidate.itSphereJobExperience.state.from = Math.round(Number(payload.min) / 12);
          state.candidate.itSphereJobExperience.state.to = Math.ceil(Number(payload.max) / 12);
        }
      }
      if (meta.arg.intervalType === IntervalFiltersType.BirthDate) {
        state.candidate.birthDate.state.min = payload.min;
        state.candidate.birthDate.state.max = payload.max;
      }
      if (meta.arg.intervalType === IntervalFiltersType.Salary) {
        if (!state.candidate.salary.state.variants[meta.arg.currency].initialDataLoaded) {
          state.candidate.salary.state.variants[meta.arg.currency] = {
            min: Math.floor(parseFloat(payload.min)),
            max: Math.round(parseFloat(payload.max)),
            initialDataLoaded: true,
          };
          if (meta.arg.currency === defaultCurrency) {
            state.candidate.salary.state.currency = meta.arg.currency;
            state.candidate.salary.state.from = Math.floor(parseFloat(payload.min));
            state.candidate.salary.state.to = Math.round(parseFloat(payload.max));
          }
        }
      }
    })
    .addCase(getInitialCandidatesFilterThunk.rejected, (state) => {
      state.isLoading = false;
    })

    .addCase(logoutAction, () => preloadedState),
);
