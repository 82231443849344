import { CommentRowHeight, LifecycleStatuses, MaxCommentRowCount, RolesValues } from '@constants';
import { CommentDeletionModalProps } from '@modules';
import { useCallback, useLayoutEffect, useEffect, useMemo, useRef, useState } from 'react';
import { useProfileAPI } from '../store';

interface useCommentProps {
  commentId: string;
  formCommentId?: string;
  handleFormCommentId?: (value: string) => boolean;
  isLoading?: boolean;
  deleteComment?: (commentId: number, parentId?: number) => void;
  restoreComment?: (commentId: number, parentId?: number) => void;
  deletedIds?: number[];
  deletionModalData?: ((data?: CommentDeletionModalProps) => void)[];
  parentId?: number;
  authorId: number;
  isProtected: boolean;
  eventId?: number;
  lifecycleStatus?: LifecycleStatuses;
  isStatus?: boolean;
  isVisible?: boolean;
  resetPageNum?: () => void;
}

const useComment = (props: useCommentProps) => {
  const {
    commentId,
    formCommentId = '0',
    handleFormCommentId,
    isLoading = false,
    deleteComment,
    restoreComment,
    deletedIds = [],
    deletionModalData = [],
    parentId,
    isProtected,
    authorId,
    eventId,
    isStatus = false,
    lifecycleStatus,
    isVisible = false,
    resetPageNum,
  } = props;
  const [showCommentDeleteModal, hideCommentDeleteModal] = deletionModalData;
  const [isOpenComment, setIsOpenComment] = useState<boolean | null>(null);

  const [isMenuOpen, setIsMenuOpen] = useState<null | HTMLElement>(null);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [isNewComment, setIsNewComment] = useState<boolean>(false);
  const [isThreadOpen, setIsThreadOpen] = useState<boolean>(false);
  const commentRef = useRef<HTMLDivElement | null>(null);

  const { profile } = useProfileAPI();

  const isDeleted = useMemo(
    () =>
      deletedIds.some((curr) => (eventId ? eventId === curr : commentId === curr.toString())) ||
      (lifecycleStatus === LifecycleStatuses.Deleted && !isVisible),
    [deletedIds, commentId, lifecycleStatus],
  );
  const isViewed = useMemo(
    () => !isProtected || (profile.id === authorId && isProtected) || profile?.role?.name === RolesValues.Admin,
    [profile.id, profile?.role?.name, isProtected, authorId],
  );

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setIsMenuOpen(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setIsMenuOpen(null);
  };

  const showDeleteModal = () => {
    setIsMenuOpen(null);
    showCommentDeleteModal({ handleDelete, isLoading, isStatus });
  };

  const handleDelete = () => {
    setIsOpenComment(null);
    deleteComment?.(eventId ? Number(eventId) : Number(commentId), parentId);
    hideCommentDeleteModal();
  };

  const handleRestore = async () => {
    setIsOpenComment(null);
    await restoreComment?.(eventId ? Number(eventId) : Number(commentId), parentId);
    resetPageNum?.();
  };

  const handleOpenEditMode = () => {
    setIsMenuOpen(null);
    setIsEdit(true);
    setIsNewComment(false);
    setIsThreadOpen(true);
    handleFormCommentId?.(commentId);
  };

  const handleOpenComment = () => {
    if (isOpenComment !== null) {
      setIsOpenComment((prev) => !prev);
    }
  };

  const handleResetComment = () => {
    setIsOpenComment(null);
  };

  const handleOpenThread = () => {
    setIsThreadOpen((prev) => {
      if (isOpenComment !== null && !prev) {
        setIsOpenComment(true);
      }
      return !prev;
    });
  };

  const handleOpenCreationComment = () => {
    setIsMenuOpen(null);
    const isNext = handleFormCommentId ? handleFormCommentId(commentId) : true;
    if (isNext) {
      setIsNewComment(true);
      if (isOpenComment !== null) setIsOpenComment(true);
      setIsThreadOpen(true);
    }
  };

  const resizeHandler = useCallback(() => {
    if (isOpenComment === null) {
      if (commentRef.current?.getBoundingClientRect().height > MaxCommentRowCount * CommentRowHeight + 4) {
        setIsOpenComment(false);
      }
    }
  }, [commentRef, isOpenComment]);

  useLayoutEffect(() => {
    window.addEventListener('resize', resizeHandler);
    resizeHandler();
    return () => {
      window.removeEventListener('resize', resizeHandler);
    };
  }, [resizeHandler]);

  useEffect(() => {
    if (formCommentId !== commentId) {
      if (isEdit) setIsEdit(false);
      if (isNewComment) setIsNewComment(false);
    }
  }, [formCommentId, isEdit, commentId]);

  return {
    commentRef,
    isOpenComment,
    handleOpenComment,
    handleResetComment,
    isMenuOpen,
    handleOpenMenu,
    handleCloseMenu,
    showDeleteModal,
    handleRestore,
    handleOpenEditMode,
    isEdit,
    setIsEdit,
    isDeleted,
    isThreadOpen,
    handleOpenThread,
    handleOpenCreationComment,
    isNewComment,
    setIsNewComment,
    isViewed,
  };
};

export default useComment;
