import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { RequestItem } from './requests.types';
import { localizedNameObject } from '@innowise-group/utilities';
import { candidateDefaultEmploymentForms, candidateDefaultWorkFormats, candidateDefaultWorkloads } from '@constants';

export const getRequestsState = (state: RootState) => state.requests;

export const getRequestsLoggerData = createSelector(getRequestsState, (state) => {
  const items = state.logger.ids.reduce((acc, val) => {
    acc = {
      ...acc,
      [val]: {
        ...state.logger.items[val],
        candidate: { ...state.logger.items[val].candidate, ...localizedNameObject(state.logger.items[val].candidate) },
        createdBy: { ...state.logger.items[val].createdBy, ...localizedNameObject(state.logger.items[val].createdBy) },
        innerItems: state.logger.items[val].innerItems.map((curr) => {
          return { ...curr, createdBy: { ...curr.createdBy, ...localizedNameObject(curr.createdBy) } };
        }),
      },
    };
    return acc;
  }, {});
  return { ...state.logger, items };
});

export const getRequestsItemsState = createSelector(getRequestsState, (state) => {
  const items = state.requests.ids.reduce((accum, curr) => {
    accum[curr] = {
      ...state.requests.items[curr],
      linkedCandidatesItems:
        state.requests.items[curr].linkedCandidatesIds?.reduce((accum, idx) => {
          accum[idx] = {
            ...state.requests.items[curr].linkedCandidatesItems[idx],
            vacancyClaimApprovals: state.requests.items[curr].linkedCandidatesItems[idx].vacancyClaimApprovals.map(
              (item) => {
                return {
                  ...item,
                  createdBy: item.createdBy ? { ...item.createdBy, ...localizedNameObject(item.createdBy) } : null,
                };
              },
            ),
            candidate: {
              ...state.requests.items[curr].linkedCandidatesItems[idx].candidate,
              responsibleEmployee: {
                ...state.requests.items[curr].linkedCandidatesItems[idx].candidate.responsibleEmployee,
                ...localizedNameObject(
                  state.requests.items[curr].linkedCandidatesItems[idx].candidate.responsibleEmployee,
                ),
              },
              ...localizedNameObject(state.requests.items[curr].linkedCandidatesItems[idx].candidate),
            },
          };
          return accum;
        }, {}) || [],
      authors: state.requests.items[curr].authors.map((item) => ({
        ...item,
        ...localizedNameObject(item),
      })),
    };
    return accum;
  }, {});
  const currentRequest = state.requests.currentRequest
    ? {
        ...state.requests.currentRequest,
        authors: state.requests.currentRequest.authors.map((item) => ({
          ...item,
          ...localizedNameObject(item),
        })),
      }
    : null;
  return { ...state.requests, items, currentRequest };
});

export const getCurrentRequestById = createSelector(getRequestsItemsState, (state) => {
  return state.currentRequest
    ? {
        ...state.currentRequest,
        linkedCandidatesItems: state.currentRequest.linkedCandidatesIds
          ? state.currentRequest.linkedCandidatesIds.reduce((accum, idx) => {
              accum[idx] = {
                ...state.currentRequest.linkedCandidatesItems[idx],
                vacancyClaimApprovals: state.currentRequest.linkedCandidatesItems[idx].vacancyClaimApprovals.map(
                  (item) => {
                    return {
                      ...item,
                      createdBy: item.createdBy ? { ...item.createdBy, ...localizedNameObject(item.createdBy) } : null,
                    };
                  },
                ),
                candidate: {
                  ...state.currentRequest.linkedCandidatesItems[idx].candidate,
                  responsibleEmployee: {
                    ...state.currentRequest.linkedCandidatesItems[idx].candidate.responsibleEmployee,
                    ...localizedNameObject(
                      state.currentRequest.linkedCandidatesItems[idx].candidate.responsibleEmployee,
                    ),
                  },
                  ...localizedNameObject(state.currentRequest.linkedCandidatesItems[idx].candidate),
                },
              };
              return accum;
            }, {})
          : [],
      }
    : null;
});

export const getDefaultRequestById = createSelector(getCurrentRequestById, (request) => {
  if (!request) return null;
  const newRequest: RequestItem = {
    currency: request.currency,
    asEmployee: request.asEmployee,
    onProject: request.onProject,
    expectedSalaryMin: request.expectedSalaryMin ? request.expectedSalaryMin.toString() : '',
    expectedSalaryMax: request.expectedSalaryMax ? request.expectedSalaryMax.toString() : '',
    structuralUnit: request.structuralUnit?.id?.toString(),
    authorId: request.authors.map((item) => ({ value: item.employeeId.toString(), title: item.localizedFullName })),
    vacancyId: request.vacancy.id.toString(),
    priority: request.priority || '',
    requiredCount: request.requiredCount || 0,
    comment: request.comment || '',
    cities: request.cities.map((item) => ({
      title: item.name,
      value: item.id.toString(),
      parent: item.countryId.toString(),
    })),
    countries: request.countries.map((item) => ({ title: item.name, value: item.id.toString() })),
    offices:
      request.offices.map((item) => ({ title: item.translation, value: item.valueId, parent: item.parentId })) || [],
    workFormats: candidateDefaultWorkFormats.map((item) => {
      return {
        dictionaryValueId: item.dictionaryValueId,
        checked: request.workFormats.includes(item.dictionaryValueId),
      };
    }),
    employmentForms: candidateDefaultEmploymentForms.map((item) => {
      return {
        dictionaryValueId: item.dictionaryValueId,
        checked: request.employmentForms.includes(item.dictionaryValueId),
      };
    }),
    workloads: candidateDefaultWorkloads.map((item) => {
      return {
        dictionaryValueId: item.dictionaryValueId,
        checked: request.workloads.includes(item.dictionaryValueId),
      };
    }),
    hiringDeadline: request.hiringDeadline || null,
    isCommentSecured: request.isCommentSecured,
  };
  return newRequest;
});
