import { RequestPriorityEnum, RequestStatusEnum } from './options-values.constants';

export const deniedVacancyRequestStatuses: string[] = [RequestStatusEnum.Closed];

// TODO: Remove after updated dictionary request
export const inStaffCandidateStatusesIds = [
  '2a5630ad-ba6d-4a0f-8b5b-f88b595e5283',
  '9169a5f5-b835-488a-93a0-5711f9741347',
  'd29a229b-6c37-4787-af9d-e8a6f93ae473',
];

export const offerStatusId = '19bc9914-fedc-40f3-a51c-e9229c7f5e00';
export const preOfferStatusId = '2be81f4a-9d78-425e-a3d8-9cb1412ed9fe';

export const ProposalStatuses = [offerStatusId, preOfferStatusId];

export const OpenVacanciesStatusId = '05abc565-985a-4939-8b9a-12a474d56bc5';
export const ClosedVacanciesStatusId = 'd1f3000f-93ce-4cbd-bbb9-39bb82e72d4c';
export const PausedVacanciesStatusId = '05cdb8b2-73ee-4678-b27e-e91f8c820e7d';
export const DraftVacanciesStatusId = '8974b1b6-5cae-4c5e-bfeb-060609654e9b';

export const DefaultCandidateGeneralStatusId = '0e36e30b-9594-40c2-9a6d-73135c6ad6dd';
export const CandidateSourceEmailId = '287ba9b3-40d0-4898-8cde-6adc4e1144be';

export const CandidateSourceNeedClarificationIds = [
  'f8c8733e-a39f-452b-9e94-b8792cd9214b',
  'c1ea2ca5-4d1a-474f-8de4-7ed0e6b50d03',
  '92afc15b-e015-40ad-ab85-a72786a5a24c',
  '7bff31bc-5896-40be-bd0a-2f564d7ae11f',
];
export const CandidateSourceNeedReferralIds = '029b3509-c7a8-49d1-9b55-f6061fddd5cd';

// TODO: Remove after updated dictionary request
export const deniedOfficesIds = ['c0febf0e-4325-4041-af67-7fbf99896cb6', '42f08df5-ef5a-4ef9-8fea-f58925c5f7f5'];

export const allowedVacancyPriorityStatuses = [
  RequestPriorityEnum.HighPriority,
  RequestPriorityEnum.MediumPriority,
  RequestPriorityEnum.LowPriority,
];

export const allowedVacancyRequestStatuses = [RequestStatusEnum.Open];

export const VacancyStatusesWithoutRequests = [
  ClosedVacanciesStatusId,
  PausedVacanciesStatusId,
  DraftVacanciesStatusId,
];
