import { SelectOption } from '@innowise-group/core';
import {
  CandidateFilterOptions,
  FiltersEntity,
  FiltersState,
  MoneyFilterType,
  NestedSelectFilterType,
  SelectFilterType,
  Tabs,
} from './filters.types';
import { CandidateStatusListItem } from '@innowise-group/core';
import { AxiosResponse } from 'axios';
import { CandidateLifecycleStatuses, LifecycleStatuses } from '@constants';

export const statusesMapper = ({
  data,
  ...response
}: AxiosResponse<CandidateStatusListItem[]>): AxiosResponse<SelectOption[]> => ({
  ...response,
  data: data.map(({ id: value, localizedName: title }) => ({ value, title })),
});

export const citiesOptionsFilter = (state: FiltersState) => {
  const selectedCountriesInFilter = Object.values(state.candidate.countries.state).reduce((acc, { active, value }) => {
    return active ? [...acc, value] : acc;
  }, []);
  const filteredCitiesOptions = state.candidateInitialOptions.citiesOptions?.filter((el) =>
    selectedCountriesInFilter.length ? selectedCountriesInFilter.some((countryId) => countryId === el.parent) : true,
  );
  return selectFilterMapper(state.candidate.cities, filteredCitiesOptions, false);
};

export const selectFilterMapper = (initialData: SelectFilterType, options: SelectOption[], reset?: boolean) => {
  return {
    ...initialData,
    state: {
      ...(Array.isArray(options) &&
        Object.entries(options).reduce((acc, [, val]) => {
          return {
            ...acc,
            [val.value]: {
              ...val,
              ...initialData.state?.[val.value],
              ...(reset && { active: !reset }),
            },
          };
        }, {})),
    },
  };
};

export const candidateStatusFiltersMapper = (
  initialData: NestedSelectFilterType,
  options: CandidateFilterOptions['statusOptions'],
  reset?: boolean,
) => {
  return {
    ...initialData,
    state: {
      ...initialData.state,
      ...Object.entries(options).reduce((acc, [key, val]) => {
        return {
          ...acc,
          [key]: {
            ...(initialData.state[key]
              ? {
                  ...val,
                  ...initialData.state[key],
                  ...(reset && { active: !reset }),
                  nested: initialData.state[key].nested
                    ? {
                        ...Object.entries(val.nested).reduce((ac, [k, v]) => {
                          return {
                            ...ac,
                            [k]: {
                              ...v,
                              ...initialData.state[key].nested[k],
                              ...(reset && { active: !reset }),
                            },
                          };
                        }, {}),
                      }
                    : val.nested,
                }
              : {
                  ...val,
                  active: false,
                }),
          },
        };
      }, {}),
    },
  };
};

export const nestedSelectFilterMapper = (
  initialData: NestedSelectFilterType,
  mainOptions: SelectOption[],
  nestedOptions: SelectOption[],
  reset?: boolean,
) => {
  return {
    ...initialData,
    state: {
      ...initialData.state,
      ...mainOptions.reduce(
        (acc, el) => ({
          ...acc,
          [el.value]: {
            ...el,
            ...initialData.state?.[el.value],
            ...(reset && { active: !reset }),
            ...initialData.state?.[el.value]?.nested,
            nested: {
              ...nestedOptions.reduce(
                (acc, el) => ({
                  ...acc,
                  [el.value]: {
                    ...el,
                    ...initialData.state?.[el.value]?.nested?.[el.value],
                    ...(reset && { active: !reset }),
                  },
                }),
                {},
              ),
            },
          },
        }),
        {},
      ),
    },
  };
};

export const moneyFilterMapper = (initialData: MoneyFilterType, options: SelectOption[], reset?: boolean) => {
  return {
    ...initialData,
    state: {
      ...initialData.state,
      ...(options &&
        options?.reduce(
          (acc, el, index) => ({
            ...acc,
            currencies: [...acc.currencies, el],
            ...(reset && index === 0 && { currency: el.value }),
          }),
          { currencies: [], currency: initialData.state.currency || options[0].value },
        )),
    },
  };
};

export const serializeSelectData = (key: string, filters: FiltersEntity[keyof FiltersEntity]) => {
  return (
    filters?.[key]?.active && {
      [key]: Object.entries<{ active: boolean }>(filters?.[key]?.state)?.reduce((acc, [key, { active }]) => {
        return active ? [...acc, key] : acc;
      }, []),
    }
  );
};

export const serializeCandidateWorkFormatsData = (
  key: string,
  filters: FiltersEntity[keyof FiltersEntity],
  options: CandidateFilterOptions,
) => {
  return (
    filters?.[key]?.active && {
      workFormats: Object.entries<{ active: boolean }>(filters?.[key]?.state)?.reduce((acc, [key, { active }]) => {
        const isWorkFormatsOption = options.workFormatsOptions.some((el) => el.value === key);
        return active && isWorkFormatsOption ? [...acc, key] : acc;
      }, []),
      workLoads: Object.entries<{ active: boolean }>(filters?.[key]?.state)?.reduce((acc, [key, { active }]) => {
        const isWorkLoadsOption = options.workloadsOptions.some((el) => el.value === key);
        return active && isWorkLoadsOption ? [...acc, key] : acc;
      }, []),
      employmentForms: Object.entries<{ active: boolean }>(filters?.[key]?.state)?.reduce((acc, [key, { active }]) => {
        const isEmploymentFormsOption = options.employmentFormsOptions.some((el) => el.value === key);
        return active && isEmploymentFormsOption ? [...acc, key] : acc;
      }, []),
    }
  );
};

export const serializeSourse = (key: string, filters: FiltersEntity[keyof FiltersEntity]) => {
  return (
    filters?.[key]?.active && {
      [key]: Object.entries<{ active: boolean; comment?: string }>(filters?.[key]?.state)?.reduce(
        (acc, [key, { active, comment }]) => {
          return active
            ? [
                ...acc,
                {
                  source: key,
                  sourceDetails: comment || '',
                },
              ]
            : acc;
        },
        [],
      ),
    }
  );
};

export const serializerBooleanData = (key: string, filters: FiltersEntity[keyof FiltersEntity]) => {
  return filters?.[key]?.active && { [key]: filters?.[key]?.state?.checked };
};

export const candidateStringDataSerializer = (
  key: string,
  field: string,
  filters: FiltersEntity[keyof FiltersEntity],
) => {
  return filters?.[key] && { [field]: filters?.[key]?.state?.value };
};

export const lifecycleStatusSerializer = (key: string, tab: FiltersState['currentTab']) => {
  if (tab === Tabs.Archive) return { [key]: LifecycleStatuses.Deleted };
  return { [key]: LifecycleStatuses.Actual };
};

export const candidateLifecycleStatusSerializer = (key: string, tab: FiltersState['currentTab']) => {
  if (tab === Tabs.Archive) return { [key]: [CandidateLifecycleStatuses.Deleted, CandidateLifecycleStatuses.Merged] };
  return { [key]: [LifecycleStatuses.Actual] };
};

export const candidateResponsibleEmployeeDataSerializer = (
  key: string,
  responsible: FiltersState['responsible'],
  tab: FiltersState['currentTab'],
) => {
  if (tab === Tabs.My) {
    return { [key]: [responsible] };
  }
  return { [key]: [] };
};

export const serializerDateRangeData = (
  min: string,
  max: string,
  key: string,
  filters: FiltersEntity[keyof FiltersEntity],
) => {
  return {
    ...(filters?.[key]?.active && {
      [min]: filters?.[key]?.state?.from,
      [max]: filters?.[key]?.state?.to,
    }),
  };
};

export const serializerNumberRangeData = (
  min: string,
  max: string,
  key: string,
  filters: FiltersEntity[keyof FiltersEntity],
) => {
  return {
    ...(filters?.[key]?.active && {
      [min]: filters?.[key]?.state?.from * 12,
      [max]: filters?.[key]?.state?.to * 12,
    }),
  };
};

export const serializerAgeRangeData = (
  min: string,
  max: string,
  key: string,
  filters: FiltersEntity[keyof FiltersEntity],
) => {
  return {
    ...(filters?.[key]?.active && {
      [min]: filters?.[key]?.state?.from,
      [max]: filters?.[key]?.state?.to,
    }),
  };
};

export const serializeNestedSelectData = (
  key: string,
  parentEntityName: string,
  childEntityName: string,
  filters: FiltersEntity[keyof FiltersEntity],
) => {
  const filterValues = filters?.[key] && {
    [key]: Object.entries<SelectOption & { active: boolean; nested: SelectOption & { active: boolean } }>(
      filters?.[key]?.state,
    )?.reduce((acc, [, { active, value, nested }]) => {
      return active
        ? [
            ...acc,
            {
              [parentEntityName]: value,
              [childEntityName]: nested
                ? Object.entries(nested)?.reduce((acc, [, { active, value }]) => {
                    return active ? [...acc, value] : acc;
                  }, [])
                : [],
            },
          ]
        : acc;
    }, []),
  };
  if (filterValues?.[key]?.length) return filterValues;
};

export const serializerMoneyData = (
  key: string,
  min: string,
  max: string,
  filters: FiltersEntity[keyof FiltersEntity],
) => {
  return (
    filters?.[key]?.active && {
      salaryFilter: {
        [min]: filters?.[key]?.state?.from,
        [max]: filters?.[key]?.state?.to,
        currency: filters?.[key]?.state?.currency,
      },
    }
  );
};

export const serializerPlaceDataFilter = (key: string, filters: FiltersEntity[keyof FiltersEntity]) => {
  return {
    ...(filters?.[key]?.active && {
      [key]: {
        place: filters?.[key]?.state?.place,
        profession: filters?.[key]?.state?.profession,
        from: filters?.[key]?.state?.from,
        to: filters?.[key]?.state?.to,
      },
    }),
  };
};

export const serializeRequestWorkLoads = (key: string, filters: FiltersEntity[keyof FiltersEntity]) => {
  return (
    serializeSelectData(key, filters)?.[key] && {
      [key]: serializeSelectData(key, filters)?.[key],
    }
  );
};

export const serializeSingleSelectOptionUnit = (key: string, filters: FiltersEntity[keyof FiltersEntity]) => {
  return (
    filters?.[key]?.active && {
      [key]: Object.entries<{ active: boolean }>(filters?.[key]?.state)?.reduce((acc, [key, { active }]) => {
        return active ? key : acc;
      }, ''),
    }
  );
};

export const serializeOverdueData = (key: string, filters: FiltersEntity[keyof FiltersEntity]) => {
  const isSame = !!filters?.[key]?.state?.OVERDUE?.active === !!filters?.[key]?.state?.VALID?.active;
  const value = isSame ? null : !!filters?.[key]?.state?.OVERDUE?.active;

  return filters?.[key]?.active && { isOverdue: value };
};

export const serializeRequestDate = (key: string, filters: FiltersEntity[keyof FiltersEntity]) => {
  return (
    filters?.[key]?.active && {
      hiringDeadline: filters?.[key]?.state?.date,
      intervalType: filters?.[key]?.state?.currentSegment,
    }
  );
};

export const serializeRequestAuthorEmploye = (
  key: string,
  targetFieldName: string,
  filters: FiltersEntity[keyof FiltersEntity],
) => {
  if (filters?.[key]?.state?.length) {
    return {
      [targetFieldName]: filters?.[key]?.state,
    };
  }
};
