import { RequestPriorityEnum } from '@constants';
import { useOptionsApi } from '@innowise-group/core';
import { useTheme } from '@innowise-group/mui-kit';
import * as d3 from 'd3';
import { useMemo } from 'react';

export const useReportColors = () => {
  const theme = useTheme();
  const { statusAuthorsOptions: employees } = useOptionsApi();

  const requestStatusesColors = useMemo(() => {
    return {
      // [RequestPriorityEnum.Closed]: theme.palette.state.error.default,
      // [RequestPriorityEnum.Overdue]: theme.palette.state.error.backgroundSecondary,
      [RequestPriorityEnum.HighPriority]: theme.palette.general.darkGrey[80],
      [RequestPriorityEnum.MediumPriority]: theme.palette.general.darkGrey[40],
      [RequestPriorityEnum.LowPriority]: theme.palette.general.lightGrey[60],
    };
  }, [theme]);

  const colors = useMemo(
    () =>
      d3
        .scaleOrdinal()
        .domain(employees?.map((d) => d.value) || [])
        .range(colorsData)
        .unknown('#ccc'),
    [employees],
  );

  return { colors, requestStatusesColors };
};

export const colorsData = [
  '#8b6cef',
  '#c8f49a',
  '#6879ee',
  '#3a51e9',
  '#a4f075',
  '#ae30e8',
  '#8cf2f2',
  '#c7e727',
  '#9bee68',
  '#c5e61e',
  '#f288e0',
  '#ee637c',
  '#f28c96',
  '#ee9d68',
  '#f19b83',
  '#ecd751',
  '#cd5aed',
  '#d791f3',
  '#6cef91',
  '#7af0bb',
  '#ea3ecd',
  '#83f1d9',
  '#e85530',
  '#3ae9a9',
  '#e93a91',
  '#c1ef6c',
  '#8471ef',
  '#f391c8',
  '#3ae830',
  '#75ddf0',
  '#dbee63',
  '#482ce8',
  '#abe830',
  '#54e93a',
  '#ec9951',
  '#e61e3f',
  '#bdef6c',
  '#f5f3a3',
  '#eb4770',
  '#c2f391',
  '#e9b935',
  '#dbec55',
  '#ef6ce2',
  '#f07a95',
  '#2ce8c8',
  '#ef6cb8',
  '#f49fd1',
  '#776cef',
  '#75f0b7',
  '#f391b3',
  '#f395d4',
  '#f5a3ee',
  '#3562e9',
  '#eb4cd9',
  '#88a0f2',
  '#b2e93a',
  '#2ce8bf',
  '#8ce1f2',
  '#7f63ee',
  '#eb47b1',
  '#f07582',
  '#ea3e86',
  '#bf47eb',
  '#35e9d4',
  '#e93a9a',
  '#f4f19f',
  '#7a92f0',
  '#eff49a',
  '#b25eed',
  '#8b47eb',
  '#e9bf35',
  '#ecf49a',
  '#27a7e7',
  '#f17ecd',
  '#f29b88',
  '#9ae727',
  '#2ce883',
  '#f2e288',
  '#8e47eb',
  '#5153ec',
  '#ecd555',
  '#ea434b',
  '#2c38e8',
  '#ec55c9',
  '#f3ca91',
  '#f0e47a',
  '#c7f288',
  '#f391e6',
  '#ed5e95',
  '#358fe9',
  '#e79823',
  '#e723b2',
  '#ef9a6c',
  '#35e9cb',
  '#e935a4',
  '#c89af4',
  '#197fe6',
  '#71ec55',
  '#b730e8',
  '#35e82c',
];
