import {
  candidatesExportDataGetProgressJob,
  candidatesExportDownloadFileJob,
  candidatesExportRunJobWithDelay,
  ExitsStatisticResponse,
  HorizontalTeamWorkingItemResponse,
  ListItemsResponse,
  MyKnownError,
  OverallStatusesResponse,
  OverallTeamWorkingResponse,
  PersonalOverallItemResponse,
  RequestStatisticResponse,
  ShortCandidateItemResponse,
  StatisticService,
  StatusesByVacanciesItemResponse,
  SummaryStatisticResponse,
  VerticalTeamWorkingItemResponse,
} from '@innowise-group/core';
import { ReportTypeUnion, TinyState } from '@modules';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { statisticBodyMapper } from './statistic.mappers';
import i18n from '@localization';
import { notificationsManager } from '@innowise-group/mui-kit';

// Pause in msec between requests
const EXPORT_CANDIDATES_REFRESH_ATTEMPT_DELAY = 1000;

// Do not expect response if server silent for 10 minutes
const EXPORT_CANDIDATES_MAX_REFRESH_ATTEMPTS = Math.ceil((1000 * 60 * 10) / EXPORT_CANDIDATES_REFRESH_ATTEMPT_DELAY);

export const getOverallStatusesThunk = createAsyncThunk<
  OverallStatusesResponse,
  {
    args: Partial<TinyState>;
  },
  { rejectValue: MyKnownError }
>('statistics/overallStatuses', async ({ args }, { rejectWithValue }) => {
  try {
    const response = await StatisticService.getOverallStatuses(statisticBodyMapper(args));
    return response.data;
  } catch (err) {
    return rejectWithValue({
      errorMessage: err.message,
    } as MyKnownError);
  }
});

export const exportStatusesThunk = createAsyncThunk<
  void,
  {
    args: Partial<TinyState>;
  },
  { rejectValue: MyKnownError }
>('statistics/exportStatuses', async ({ args }, { rejectWithValue }) => {
  try {
    const response = await StatisticService.exportOverallStatuses(statisticBodyMapper(args));
    const data = URL.createObjectURL(response.data);
    const fileUrl = document.createElement('a');
    fileUrl.href = data;
    fileUrl.download = 'export-statuses.xlsx';
    document.body.appendChild(fileUrl);
    fileUrl.click();
    URL.revokeObjectURL(fileUrl.href);
    return;
  } catch (err) {
    return rejectWithValue({
      errorMessage: err.message,
    } as MyKnownError);
  }
});

export const exportTeamWorkingThunk = createAsyncThunk<
  void,
  {
    args: Partial<TinyState>;
    tableType: ReportTypeUnion;
  },
  { rejectValue: MyKnownError }
>('statistics/exportTeamWorking', async ({ args, tableType }, { rejectWithValue }) => {
  try {
    const response = await StatisticService.exportTeamWorking(statisticBodyMapper(args), tableType);
    const data = URL.createObjectURL(response.data);
    const fileUrl = document.createElement('a');
    fileUrl.href = data;
    fileUrl.download = 'export-team-working.xlsx';
    document.body.appendChild(fileUrl);
    fileUrl.click();
    URL.revokeObjectURL(fileUrl.href);
    return;
  } catch (err) {
    return rejectWithValue({
      errorMessage: err.message,
    } as MyKnownError);
  }
});

export const exportRequestsDataThunk = createAsyncThunk<
  void,
  {
    args: Partial<TinyState>;
  },
  { rejectValue: MyKnownError }
>('statistics/exportRequestsData', async ({ args }, { rejectWithValue }) => {
  try {
    const response = await StatisticService.exportRequestsData(statisticBodyMapper(args));
    const data = URL.createObjectURL(response.data);
    const fileUrl = document.createElement('a');
    fileUrl.href = data;
    fileUrl.download = 'export-requests.xlsx';
    document.body.appendChild(fileUrl);
    fileUrl.click();
    URL.revokeObjectURL(fileUrl.href);
    return;
  } catch (err) {
    return rejectWithValue({
      errorMessage: err.message,
    } as MyKnownError);
  }
});

export const getStatusesByVacanciesThunk = createAsyncThunk<
  ListItemsResponse<StatusesByVacanciesItemResponse>,
  {
    args: Partial<TinyState>;
    pageNum?: number;
  },
  { rejectValue: MyKnownError }
>('statistics/statusesByVacancies', async ({ args, pageNum }, { rejectWithValue }) => {
  try {
    const response = await StatisticService.getStatusesByVacancies(statisticBodyMapper(args), pageNum);
    return response.data;
  } catch (err) {
    return rejectWithValue({
      errorMessage: err.message,
    } as MyKnownError);
  }
});

export const getOverallTeamStatisticThunk = createAsyncThunk<
  OverallTeamWorkingResponse,
  {
    args: Partial<TinyState>;
  },
  { rejectValue: MyKnownError }
>('statistics/getOverallTeamStatistic', async ({ args }, { rejectWithValue }) => {
  try {
    const response = await StatisticService.getOverallTeamStatistic(statisticBodyMapper(args));
    return response.data;
  } catch (err) {
    return rejectWithValue({
      errorMessage: err.message,
    } as MyKnownError);
  }
});

export const getHorizontalTeamStatisticByVacancyThunk = createAsyncThunk<
  ListItemsResponse<HorizontalTeamWorkingItemResponse>,
  {
    args: Partial<TinyState>;
    pageNum?: number;
  },
  { rejectValue: MyKnownError }
>('statistics/getHorizontalTeamStatisticByVacancy', async ({ args, pageNum }, { rejectWithValue }) => {
  try {
    const response = await StatisticService.getHorizontalTeamStatisticByVacancy(statisticBodyMapper(args), pageNum);
    return response.data;
  } catch (err) {
    return rejectWithValue({
      errorMessage: err.message,
    } as MyKnownError);
  }
});

export const getVerticalTeamStatisticByVacancyThunk = createAsyncThunk<
  ListItemsResponse<VerticalTeamWorkingItemResponse>,
  {
    args: Partial<TinyState>;
    pageNum?: number;
  },
  { rejectValue: MyKnownError }
>('statistics/getVerticalTeamStatisticByVacancy', async ({ args, pageNum }, { rejectWithValue }) => {
  try {
    const response = await StatisticService.getVerticalTeamStatisticByVacancy(statisticBodyMapper(args), pageNum);
    return response.data;
  } catch (err) {
    return rejectWithValue({
      errorMessage: err.message,
    } as MyKnownError);
  }
});

export const getPersonalStatisticByVacancyThunk = createAsyncThunk<
  ListItemsResponse<StatusesByVacanciesItemResponse>,
  {
    args: Partial<TinyState>;
    pageNum?: number;
  },
  { rejectValue: MyKnownError }
>('statistics/getPersonalStatistic', async ({ args, pageNum }, { rejectWithValue }) => {
  try {
    const response = await StatisticService.getPersonalStatistic(statisticBodyMapper(args), pageNum);
    return response.data;
  } catch (err) {
    return rejectWithValue({
      errorMessage: err.message,
    } as MyKnownError);
  }
});

export const getPersonalOverallStatisticThunk = createAsyncThunk<
  PersonalOverallItemResponse[],
  {
    args: Partial<TinyState>;
  },
  { rejectValue: MyKnownError }
>('statistics/getPersonalOverallStatistic', async ({ args }, { rejectWithValue }) => {
  try {
    const response = await StatisticService.getPersonalOverallStatistic(statisticBodyMapper(args));
    return response.data;
  } catch (err) {
    return rejectWithValue({
      errorMessage: err.message,
    } as MyKnownError);
  }
});

export const getRequestStatisticThunk = createAsyncThunk<
  RequestStatisticResponse,
  {
    args: Partial<TinyState>;
  },
  { rejectValue: MyKnownError }
>('statistics/getRequestStatistic', async ({ args }, { rejectWithValue }) => {
  try {
    const response = await StatisticService.getRequestStatistics(statisticBodyMapper(args));
    return response.data;
  } catch (err) {
    return rejectWithValue({
      errorMessage: err.message,
    } as MyKnownError);
  }
});

export const getSummaryStatisticThunk = createAsyncThunk<
  SummaryStatisticResponse,
  {
    args: Partial<TinyState>;
  },
  { rejectValue: MyKnownError }
>('statistics/getSummaryStatistic', async ({ args }, { rejectWithValue }) => {
  try {
    const response = await StatisticService.getSummaryStatistic(statisticBodyMapper(args));
    return response.data;
  } catch (err) {
    return rejectWithValue({
      errorMessage: err.message,
    } as MyKnownError);
  }
});

export const getExitsStatisticThunk = createAsyncThunk<
  ListItemsResponse<ExitsStatisticResponse>,
  {
    args: Partial<TinyState>;
    pageNum?: number;
  },
  { rejectValue: MyKnownError }
>('statistics/getExitsStatistic', async ({ args, pageNum }, { rejectWithValue }) => {
  try {
    const response = await StatisticService.getExitsStatistic(statisticBodyMapper(args), pageNum);
    return response.data;
  } catch (err) {
    return rejectWithValue({
      errorMessage: err.message,
    } as MyKnownError);
  }
});

export const exportSummaryReportThunk = createAsyncThunk<
  void,
  {
    args: Partial<TinyState>;
  },
  { rejectValue: MyKnownError }
>('statistics/exportSummaryData', async ({ args }, { rejectWithValue }) => {
  try {
    const response = await StatisticService.exportSummaryData(statisticBodyMapper(args));
    const data = URL.createObjectURL(response.data);
    const fileUrl = document.createElement('a');
    fileUrl.href = data;
    fileUrl.download = 'export-summary.xlsx';
    document.body.appendChild(fileUrl);
    fileUrl.click();
    URL.revokeObjectURL(fileUrl.href);
    return;
  } catch (err) {
    return rejectWithValue({
      errorMessage: err.message,
    } as MyKnownError);
  }
});

export const exportExitsReportThunk = createAsyncThunk<
  void,
  {
    args: Partial<TinyState>;
  },
  { rejectValue: MyKnownError }
>('statistics/exportExitsData', async ({ args }, { rejectWithValue }) => {
  try {
    const response = await StatisticService.exportExitsData(statisticBodyMapper(args));
    const data = URL.createObjectURL(response.data);
    const fileUrl = document.createElement('a');
    fileUrl.href = data;
    fileUrl.download = 'export-exits.xlsx';
    document.body.appendChild(fileUrl);
    fileUrl.click();
    URL.revokeObjectURL(fileUrl.href);
    return;
  } catch (err) {
    return rejectWithValue({
      errorMessage: err.message,
    } as MyKnownError);
  }
});

export const exportCandidatesInReportThunk = createAsyncThunk<
  void,
  {
    args: Partial<TinyState>;
    statusId: string;
    vacancyId?: string;
  },
  { rejectValue: MyKnownError }
>('statistics/exportCandidatesInReport', async ({ args, statusId, vacancyId }, { rejectWithValue }) => {
  try {
    const {
      data: { id: jobId, status: jobStatus, fileId: initialFileId },
    } = await StatisticService.exportCandidatesInReport(statisticBodyMapper(args), statusId, vacancyId);
    if (jobStatus === 'SUCCESS') {
      await candidatesExportDownloadFileJob(initialFileId);
    } else {
      const jobStatusRequestor = async () => await candidatesExportDataGetProgressJob(jobId);
      let exportStatusEarlySettled = false;

      for (let attemptNumber = 0; attemptNumber < EXPORT_CANDIDATES_MAX_REFRESH_ATTEMPTS; attemptNumber += 1) {
        try {
          const newFileId = await candidatesExportRunJobWithDelay(
            jobStatusRequestor,
            EXPORT_CANDIDATES_REFRESH_ATTEMPT_DELAY,
          );

          if (newFileId) {
            await candidatesExportDownloadFileJob(newFileId);
            exportStatusEarlySettled = true;
            break;
          }
        } catch (error) {
          notificationsManager.error({ title: i18n.t('pages.candidates.export.commonError') });
          exportStatusEarlySettled = true;
          break;
        }
      }

      if (!exportStatusEarlySettled) {
        notificationsManager.error({
          title: i18n.t('pages.candidates.export.commonError'),
          subtitle: i18n.t('pages.candidates.export.timeoutError'),
        });
      }
    }

    // const data = URL.createObjectURL(response.data);
    // const fileUrl = document.createElement('a');
    // fileUrl.href = data;
    // fileUrl.download = 'export-candidates.xlsx';
    // document.body.appendChild(fileUrl);
    // fileUrl.click();
    // URL.revokeObjectURL(fileUrl.href);
    return;
  } catch (err) {
    return rejectWithValue({
      errorMessage: err.message,
    } as MyKnownError);
  }
});

export const getCandidatesInStatusAndVacancyThunk = createAsyncThunk<
  ListItemsResponse<ShortCandidateItemResponse>,
  {
    args: Partial<TinyState>;
    pageNum: number;
    statusId: string;
    vacancyId?: string;
  },
  { rejectValue: MyKnownError }
>('statistics/getCandidatesInStatusAndVacancy', async ({ args, pageNum, statusId, vacancyId }, { rejectWithValue }) => {
  try {
    const response = await StatisticService.getCandidatesStatisticByVacancyAndStatus(
      statisticBodyMapper(args),
      pageNum,
      statusId,
      vacancyId,
    );
    return response.data;
  } catch (err) {
    return rejectWithValue({
      errorMessage: err.message,
    } as MyKnownError);
  }
});
