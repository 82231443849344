import { RequestPriorityEnum, RequestStatusEnum } from './options-values.constants';

export interface RequestPriorityColors {
  backgroundColor: string;
  backgroundSecondaryColor?: string;
  color: string;
}

export const RequestPriorityColors: { [key: string]: RequestPriorityColors } = {
  [RequestPriorityEnum.HighPriority]: {
    backgroundColor: '#EAF2FC',
    color: '#3179E3',
  },
  [RequestPriorityEnum.MediumPriority]: {
    backgroundColor: '#FEF2EC',
    color: '#DA5922',
  },
  [RequestPriorityEnum.LowPriority]: {
    backgroundColor: '#EBEBEB',
    color: '#767676',
  },
};

export const RequestStatusColors: { [key: string]: RequestPriorityColors } = {
  [RequestStatusEnum.Open]: {
    backgroundColor: '#EAF2FC',
    backgroundSecondaryColor: '#F5F8FE',
    color: '#3179E3',
  },
  [RequestStatusEnum.Closed]: {
    backgroundColor: '#FEF2EC',
    backgroundSecondaryColor: '#FFF9F5',
    color: '#DA5922',
  },
};

export const RequestOverdueColors: RequestPriorityColors = {
  backgroundColor: '#FCECF1',
  backgroundSecondaryColor: '#FDF6F8',
  color: '#C2315C',
};
