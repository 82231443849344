import { SelectOption } from '@innowise-group/core';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import useDebounce from './use-debounce.hook';
import {
  LifecycleStatuses,
  SortingValue,
  allowedVacancyPriorityStatuses,
  allowedVacancyRequestStatuses,
} from '@constants';
import { RequestsService, VacanciesService } from '../services';
import { getArrayIds } from '@innowise-group/utilities';
import { useRecruiterCandidatesAPI } from '../store';

const useSearchVacancyRequest = (
  vacancyIdFieldName: string,
  vacancyNameFieldName: string,
  requestNameFieldName: string,
  vacancyIds?: string[],
) => {
  const [isBusyByFetchRequestOptionById, setBusyByFetchRequestOptionById] = useState(false);
  const [isBusyByHandleRequestChange, setBusyByHandleRequestChange] = useState(false);
  const [isBusyByFetchRequestsOptions, setBusyByFetchRequestsOptions] = useState(false);

  const isBusy = isBusyByFetchRequestOptionById || isBusyByFetchRequestsOptions || isBusyByHandleRequestChange;

  const { recruiterVacancyId } = useRecruiterCandidatesAPI();
  const [requestOptions, setRequestOptions] = useState<{ ids: number[]; items: SelectOption[] }>({
    ids: [],
    items: null,
  });
  const [requestValue, setRequestValue] = useState<string>('');
  const [debouncedRequestValue] = useDebounce<string>(requestValue, 500);

  const prevVacancyId = useRef(null);

  const { watch, setValue, trigger } = useFormContext();

  const currentVacancyId = watch(vacancyIdFieldName);
  const currentRequestId = watch(requestNameFieldName);

  const itemSet = useMemo(() => {
    return requestOptions.ids.reduce(
      (acc, id) => (requestOptions.items[id] ? [...acc, requestOptions.items[id]] : acc),
      [],
    );
  }, [requestOptions]);

  const transformValue = (value: string) => requestOptions.items?.[value]?.title;

  const fetchRequestOptionById = async (id: number) => {
    setBusyByFetchRequestOptionById(true);

    const { data } = await RequestsService.getVacancyRequestById(id).finally(() =>
      setBusyByFetchRequestOptionById(false),
    );

    setRequestOptions((prev) => ({
      ...prev,
      ids: [...new Set([...prev.ids, data.id])],
      items: {
        ...prev.items,
        [data.id]: {
          title: data.id.toString(),
          value: data.id.toString(),
        },
      },
    }));
  };

  const handleRequestChange = useCallback(
    (onChange: (value: string) => void) => async (value: string) => {
      onChange(value);
      if (value && requestOptions.items?.[value]) {
        const parentVacancyId = requestOptions.items[value].parent;
        setBusyByHandleRequestChange(true);

        const response = await VacanciesService.getVacancyById(parentVacancyId).finally(() =>
          setBusyByHandleRequestChange(false),
        );

        if (response?.data) {
          setValue(vacancyIdFieldName, response.data.id.toString());
          setValue(vacancyNameFieldName, response.data.name);
          trigger(vacancyIdFieldName);
        }
      }
    },
    [requestOptions.items, vacancyIdFieldName, vacancyNameFieldName],
  );

  const handleSearchRequests = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setRequestValue(event.target.value);
  }, []);

  const onCloseRequestSelect = useCallback(() => {
    setRequestValue('');
    if (!currentVacancyId) {
      if (!(recruiterVacancyId && prevVacancyId.current === null)) {
        fetchRequestsOptions('', '');
      }
    } else {
      fetchRequestsOptions('', currentVacancyId);
    }
  }, [currentVacancyId, recruiterVacancyId]);

  const fetchRequestsOptions = useCallback(
    async (searchValue: string, vacancyId?: string) => {
      const body = {
        searchBarValue: searchValue,
        lifecycleStatus: LifecycleStatuses.Actual,
        // TODO: Remove after updated dictionary request
        statuses: allowedVacancyRequestStatuses,
        priority: allowedVacancyPriorityStatuses,
        ...(vacancyId ? { vacancyIds: [vacancyId] } : vacancyIds?.length ? { vacancyIds } : {}),
      };
      const params = { currentPage: 1, pageSize: 10, sort: SortingValue.IdASC };

      setBusyByFetchRequestsOptions(true);
      const response = await RequestsService.getAllRequests({ params, body }).finally(() =>
        setBusyByFetchRequestsOptions(false),
      );

      const ids = getArrayIds(response.data.content);

      setRequestOptions((prev) => ({
        ...prev,
        ids: [...ids, ...(!ids.includes(Number(currentRequestId)) ? [Number(currentRequestId)] : [])],
        items: {
          ...prev.items,
          ...response.data.content.reduce(
            (acc, { id, vacancy }) => ({
              ...acc,
              [id]: {
                value: id.toString(),
                title: id.toString(),
                parent: vacancy.id,
              },
            }),
            {},
          ),
        },
      }));
    },
    [currentRequestId],
  );

  useEffect(() => {
    if (!currentRequestId) {
      onCloseRequestSelect();
    }
  }, [onCloseRequestSelect, currentRequestId]);

  useEffect(() => {
    if (prevVacancyId.current && prevVacancyId.current.toString() !== currentVacancyId.toString()) {
      if (!recruiterVacancyId) {
        setValue(requestNameFieldName, '');
        fetchRequestsOptions('', currentVacancyId);
      }
    }
    if (!currentVacancyId) {
      setValue(requestNameFieldName, '');
    }
    prevVacancyId.current = currentVacancyId;
  }, [setValue, currentVacancyId, recruiterVacancyId]);

  useEffect(() => {
    if (debouncedRequestValue || currentVacancyId) {
      fetchRequestsOptions(debouncedRequestValue || '', currentVacancyId);
    }
  }, [debouncedRequestValue, fetchRequestsOptions, currentVacancyId]);

  return {
    transformValue,
    handleRequestChange,
    onCloseRequestSelect,
    handleSearchRequests,
    fetchRequestOptionById,
    requestOptions: itemSet,
    isBusy,
  };
};

export default useSearchVacancyRequest;
