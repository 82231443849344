import {
  EmployeesService,
  RequestsService,
  VacanciesService,
  useAllStatusesOptions,
  useCandidateStatusAPI,
  useCompressedStateInQuery,
  useOptionsApi,
  useProfileAPI,
  useSelectOptionsApi,
} from '@innowise-group/core';
import { SelectFilterProps } from './components';
import {
  AppRoutes,
  CandidateStatusGroupEnum,
  LettersStatusesIds,
  LifecycleStatuses,
  ManagerRolesArray,
  RolesValues,
  SortingValue,
} from '@constants';
import { useTranslation } from 'react-i18next';
import { localizedNameObject } from '@innowise-group/utilities';
import { type DatePickerProps } from '@shared-mui-components';
import { ReportFormValues, TinyState } from './report-manager.types';
import { useMemo } from 'react';
import { FieldValues, UseFormReset } from 'react-hook-form';
import { makeStateTiny } from './report-manager.component';
import { SelectOption } from '@innowise-group/core';

export enum FiltersUnion {
  Select = 'SELECT',
  Value = 'VALUE',
  DateRange = 'DATE_RANGE',
}

interface FilterBase<L extends object> {
  type: FiltersUnion;
  name: keyof L;
  clueText?: string;
}

export interface SelectFilter<K extends object>
  extends FilterBase<K>,
    Pick<SelectFilterProps, 'onClose' | 'onSearch' | 'options' | 'placeholder' | 'disableMultiple'> {}

interface DateRangeFilter<N extends object>
  extends Omit<FilterBase<N>, 'name'>,
    Omit<DatePickerProps<never, boolean>, 'name'> {
  minName: keyof N;
  maxName: keyof N;
  clueText?: string;
}

export type FiltersConfig<TFormValues extends object> = {
  [key in keyof Omit<TFormValues, 'report' | 'reportType'>]: SelectFilter<TFormValues>;
} & {
  dateRange: DateRangeFilter<TFormValues>;
};

export const useReportManager = <FormValues extends object>(
  initialState?: Partial<TinyState>,
): {
  config: FiltersConfig<FormValues>;
  selectedOptionsLoaded?: boolean;
} => {
  const { t } = useTranslation();
  const { profile } = useProfileAPI();
  const {
    statusAuthorsOptions: employees,
    employeesLoading,
    candidateSourceOptions,
    vacancyPurposeOptions,
  } = useOptionsApi();
  const { statusOptions } = useAllStatusesOptions();
  const { items: allStatuses } = useCandidateStatusAPI();

  const disabled = useMemo(() => {
    if (ManagerRolesArray.includes(profile?.role?.name)) {
      return true;
    }
    if (
      (profile?.role?.name === RolesValues.Recruiter || profile?.role?.name === RolesValues.Sourcer) &&
      !window.location.pathname.includes(AppRoutes.AnalyticsEmployeePage)
    ) {
      return true;
    }
    return false;
  }, [profile, window.location.pathname]);

  const isStatusesFiltersDisabled = useMemo(() => {
    return window.location.pathname.includes(AppRoutes.AnalyticsRequestsPage);
  }, [window.location.pathname]);

  const isPurposeFiltersDisabled = useMemo(() => {
    return !(
      window.location.pathname.includes(AppRoutes.AnalyticsRequestsPage) ||
      window.location.pathname.includes(AppRoutes.AnalyticsStatusesPage) ||
      window.location.pathname.includes(AppRoutes.AnalyticsStatusPage)
    );
  }, [window.location.pathname]);

  const isRequestsFiltersDisabled = useMemo(() => {
    return (
      window.location.pathname.includes(AppRoutes.AnalyticsSummaryPage) ||
      window.location.pathname.includes(AppRoutes.AnalyticsExitsPage)
    );
  }, [window.location.pathname]);

  const getVacancyByName = async (searchBarRequest: string) => {
    const { data } = await VacanciesService.searchAllVacancies({
      params: { currentPage: 1, pageSize: 10, sort: SortingValue.VacancyNameASC },
      body: {
        searchBarRequest,
        lifecycleStatus: LifecycleStatuses.Actual,
      },
    });
    return data.content.map((vac) => ({
      title: vac.name,
      value: vac.id,
    }));
  };

  const getVacancyById = async (id: string) => {
    const { data } = await VacanciesService.getVacancyById(id);
    return {
      title: data.name,
      value: data.id,
    };
  };

  const getVacancyRequestByName = async (searchBarValue: string) => {
    const { data } = await RequestsService.getAllRequests({
      params: { currentPage: 1, pageSize: 10, sort: SortingValue.IdASC },
      body: {
        searchBarValue,
        lifecycleStatus: LifecycleStatuses.Actual,
      },
    });
    return data.content.map(({ id }) => ({
      title: id.toString(),
      value: id.toString(),
    }));
  };

  const getVacancyRequestById = async (id: string) => {
    const { data } = await RequestsService.getVacancyRequestById(Number(id));
    return {
      title: data.id.toString(),
      value: data.id.toString(),
    };
  };

  const getEmployeeById = async (id: string) => {
    const { data } = await EmployeesService.getEmployeeById(Number(id));
    const { localizedFullName } = localizedNameObject(data);
    return {
      title: localizedFullName,
      value: data.id.toString(),
    };
  };

  const getInitialEmployeeOptions = async (partOfName: string) => {
    const { data } = await EmployeesService.searchEmployees({
      partOfName,
      page: 1,
      size: 10,
    });

    return data.content.map(({ employeeId, ...data }) => {
      return {
        title: localizedNameObject(data).localizedFullName,
        value: employeeId.toString(),
      };
    });
  };

  const {
    selectedOptionsLoaded: vacanciesSelectedOptionsLoaded,
    selectOptions: vacanciesOptions,
    searchOptions: searchVacanciesOptions,
  } = useSelectOptionsApi({
    getOptionsByName: getVacancyByName,
    getOptionsById: getVacancyById,
    selectedOptions: initialState?.vacancies,
    initialOptions: [{ title: t('pages.candidates.comments.jobOpening'), value: 'null' }],
  });

  const {
    selectedOptionsLoaded: requestSelectedOptionsLoaded,
    selectOptions: requestOptions,
    searchOptions: searchRequestOptions,
  } = useSelectOptionsApi({
    getOptionsByName: getVacancyRequestByName,
    getOptionsById: getVacancyRequestById,
    selectedOptions: initialState?.vacancyClaims,
  });

  const {
    selectedOptionsLoaded: requestAuthorSelectedOptionsLoaded,
    selectOptions: requestAuthorOptions,
    searchOptions: searchRequestAuthorOptions,
  } = useSelectOptionsApi({
    getOptionsByName: getInitialEmployeeOptions,
    getOptionsById: getEmployeeById,
    selectedOptions: initialState?.vacancyClaimAuthors,
  });

  const getStatusOptionDisabled = (option: SelectOption) => {
    if (window.location.pathname.includes(AppRoutes.AnalyticsLettersPage)) {
      return !LettersStatusesIds.includes(option.value);
    }
    if (window.location.pathname.includes(AppRoutes.AnalyticsExitsPage)) {
      return allStatuses[option.value].group !== CandidateStatusGroupEnum.JOB_ENTRY;
    }
    return false;
  };

  const selectedOptionsLoaded =
    requestAuthorSelectedOptionsLoaded && requestSelectedOptionsLoaded && vacanciesSelectedOptionsLoaded;

  const config = useMemo(
    () =>
      ({
        dateRange: {
          minName: 'dateMin' as keyof FormValues,
          maxName: 'dateMax' as keyof FormValues,
          type: FiltersUnion.DateRange,
          label: t('pages.candidates.candidateCreation.period'),
          placeholder: t('pages.candidates.candidateCreation.period'),
          selectsRange: false,
          clueText: t('filters.clues.period'),
          disabled,
        },
        statuses: {
          name: 'statuses' as keyof FormValues,
          type: FiltersUnion.Select,
          options: statusOptions,
          label: t('filters.union.statuses'),
          placeholder: t('filters.union.statuses'),
          clueText: t('filters.clues.status'),
          disabled: disabled || isStatusesFiltersDisabled || isRequestsFiltersDisabled,
          getOptionDisabled: getStatusOptionDisabled,
        },
        vacancies: {
          name: 'vacancies' as keyof FormValues,
          type: FiltersUnion.Select,
          options: vacanciesOptions,
          label: t('filters.union.searchVacancyPlaceholder'),
          placeholder: t('filters.union.searchVacancyPlaceholder'),
          onSearch: searchVacanciesOptions,
          clueText: t('filters.clues.vacancy'),
          disabled: disabled || isRequestsFiltersDisabled,
          disableSortByAlphabet: false,
        },
        sources: {
          name: 'sources' as keyof FormValues,
          type: FiltersUnion.Select,
          options: candidateSourceOptions,
          label: t('filters.union.sourceFilter'),
          placeholder: t('filters.union.sourceFilter'),
          clueText: t('filters.clues.source'),
          disabled: disabled || isStatusesFiltersDisabled,
          disableSortByAlphabet: false,
        },
        vacancyClaims: {
          name: 'vacancyClaims' as keyof FormValues,
          type: FiltersUnion.Select,
          options: requestOptions,
          label: t('pages.candidates.allCandidatesTable.request'),
          placeholder: t('pages.candidates.allCandidatesTable.request'),
          onSearch: searchRequestOptions,
          disabled: disabled || isRequestsFiltersDisabled,
          disableSortByAlphabet: false,
        },
        vacancyClaimAuthors: {
          name: 'vacancyClaimAuthors' as keyof FormValues,
          type: FiltersUnion.Select,
          options: requestAuthorOptions,
          label: t('pages.requests.allRequestsTable.author'),
          placeholder: t('pages.requests.allRequestsTable.author'),
          onSearch: searchRequestAuthorOptions,
          disabled: disabled || isRequestsFiltersDisabled,
          disableSortByAlphabet: false,
        },
        statusAuthors: {
          name: 'statusAuthors' as keyof FormValues,
          type: FiltersUnion.Select,
          options: employees,
          label: t('filters.union.statusAuthor'),
          placeholder: t('filters.union.statusAuthor'),
          disabled: disabled || isStatusesFiltersDisabled,
          loading: employeesLoading,
          clueText: t('filters.clues.statusAuthor'),
          disableSortByAlphabet: false,
        },
        candidateResponsible: {
          name: 'candidateResponsible' as keyof FormValues,
          type: FiltersUnion.Select,
          options: employees,
          loading: employeesLoading,
          label: t('filters.union.responsible'),
          placeholder: t('filters.union.responsible'),
          clueText: t('filters.clues.responsible'),
          disabled: disabled || isStatusesFiltersDisabled,
          disableSortByAlphabet: false,
        },
        hiringPurpose: {
          name: 'hiringPurpose' as keyof FormValues,
          type: FiltersUnion.Value,
          options: vacancyPurposeOptions,
          label: t('pages.vacancies.creationModal.employmentPurpose'),
          placeholder: t('pages.vacancies.creationModal.selectEmploymentPurpose'),
          disabled: disabled || isPurposeFiltersDisabled,
          disableSortByAlphabet: false,
          disableMultiple: true,
          clueText: t('filters.clues.purpose'),
        },
      } as FiltersConfig<FormValues>),
    [
      isStatusesFiltersDisabled,
      isRequestsFiltersDisabled,
      isPurposeFiltersDisabled,
      disabled,
      employeesLoading,
      employees,
      statusOptions,
      candidateSourceOptions,
      selectedOptionsLoaded,
      requestOptions,
      vacanciesOptions,
      vacancyPurposeOptions,
      requestAuthorOptions,
      searchRequestOptions,
      searchVacanciesOptions,
      searchRequestAuthorOptions,
      t,
    ],
  );

  return { config, selectedOptionsLoaded };
};

export const useReportManagerState = (reset: UseFormReset<FieldValues>) => {
  const [, setState] = useCompressedStateInQuery<TinyState>();

  const updateGlobalState = (field: keyof ReportFormValues, value: ReportFormValues[keyof ReportFormValues]) => {
    reset((prev) => {
      const newState = {
        ...prev,
        [field]: value,
      };
      const tinyState = makeStateTiny(newState);
      setState(tinyState);
      return newState;
    });
  };

  return { updateGlobalState };
};
