import {
  MyKnownError,
  VacanciesService,
  VacancyItem,
  VacancyItemResponse,
  RequestItemResponse,
  RequestsService,
  ListItemsResponse,
  AppReducerType,
} from '@innowise-group/core';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { mapVacancyItemsResponseToSelectsOption } from './vacancies.mappers';
import { PurposeEnum } from '@constants';

export const getVacancyItemByIdThunk = createAsyncThunk<
  VacancyItemResponse,
  {
    id: string;
  },
  { rejectValue: MyKnownError }
>('vacancies/itemById', async ({ id }, { rejectWithValue }) => {
  try {
    const response = await VacanciesService.getVacancyById(id);
    return response.data;
  } catch (err) {
    return rejectWithValue({
      errorMessage: err.message,
    } as MyKnownError);
  }
});

export const createVacancyThunk = createAsyncThunk<
  void,
  {
    data: VacancyItem;
    successCallback?: (vacancyId: string, locationId?: string) => void;
  },
  { rejectValue: MyKnownError }
>('vacancies/create', async ({ data, successCallback }, { rejectWithValue }) => {
  try {
    const newData = {
      vacancyStatusId: data.vacancyStatusId,
      name: data.name,
      comment: data.comment,
      structuralUnit: data.structuralUnit || '',
      vacancyDomains: data.vacancyDomains.map((item) => {
        return { dictionaryValueId: item.value };
      }),
      vacancyTechnologies: data.vacancyTechnologies.map((item) => {
        return { technologyId: item.value };
      }),
      cities: data.cities.map((item) => item.value),
      countries: data.countries.map((item) => item.value),
      hiringPurpose: data.hiringPurpose,
      ...(data.hiringPurpose === PurposeEnum.Trainee ? { published: data.published } : {}),
      vacancyLanguages: data.vacancyLanguages.reduce((accum, item) => {
        if (item.language) {
          accum.push({ language: item.language, ...(item.languageLevel && { languageLevel: item.languageLevel }) });
        }
        return accum;
      }, []),
      vacancyProfessions: data.vacancyProfessions.reduce((accum, item) => {
        if (item.profession) {
          accum.push({ professionId: item.profession, ...(item.grade && { grade: item.grade }) });
        }
        return accum;
      }, []),
    };
    const response = await VacanciesService.createVacancy(newData);
    successCallback?.(response.data.id.toString(), response.data.location?.countryId);
  } catch (err) {
    return rejectWithValue({
      errorMessage: err.message,
    } as MyKnownError);
  }
});

export const getAllVacanciesThunk = createAsyncThunk<
  ListItemsResponse<VacancyItemResponse>,
  undefined,
  { rejectValue: MyKnownError }
>('vacancies/getAll', async (data, { rejectWithValue }) => {
  try {
    const response = await VacanciesService.getAllVacancies();
    return response.data;
  } catch (err) {
    return rejectWithValue({
      errorMessage: err.message,
    } as MyKnownError);
  }
});

export const searchAllVacanciesThunk = createAsyncThunk<
  ListItemsResponse<VacancyItemResponse>,
  Parameters<typeof VacanciesService.searchAllVacancies>[0],
  { rejectValue: MyKnownError }
>('vacancies/search', async (data, { rejectWithValue }) => {
  try {
    const response = await VacanciesService.searchAllVacancies(data);
    return response.data;
  } catch (err) {
    return rejectWithValue({
      errorMessage: err.message,
    } as MyKnownError);
  }
});

export const exportVacanciesThunk = createAsyncThunk<
  void,
  Parameters<typeof VacanciesService.exportVacancies>[0],
  { rejectValue: MyKnownError }
>('vacancies/export', async (body, { rejectWithValue }) => {
  try {
    const response = await VacanciesService.exportVacancies(body);
    const data = URL.createObjectURL(response.data);
    const fileUrl = document.createElement('a');
    fileUrl.href = data;
    fileUrl.download = 'export-results.xlsx';
    document.body.appendChild(fileUrl);
    fileUrl.click();
    URL.revokeObjectURL(fileUrl.href);
  } catch (err) {
    return rejectWithValue({
      errorMessage: err.message,
    } as MyKnownError);
  }
});

export const deleteVacancyThunk = createAsyncThunk<
  void,
  { id: number; successCallback?: () => void },
  { rejectValue: MyKnownError }
>('vacancies/delete', async ({ id, successCallback }, { rejectWithValue }) => {
  try {
    await VacanciesService.deleteVacancyById(id);
    successCallback?.();
  } catch (err) {
    return rejectWithValue({
      errorMessage: err.message,
    } as MyKnownError);
  }
});

export const updateVacancyThunk = createAsyncThunk<
  void,
  {
    data: VacancyItem;
    id: number;
    successCallback?: () => void;
  },
  { rejectValue: MyKnownError }
>('vacancies/update', async ({ data, id, successCallback }, { rejectWithValue, getState }) => {
  try {
    const state = getState() as AppReducerType;
    const vacancyDomains = state.vacancies.vacancies.currentVacancy.vacancyDomains;
    const vacancyTechnologies = state.vacancies.vacancies.currentVacancy.vacancyTechnologies;
    const newData = {
      vacancyStatusId: data.vacancyStatusId,
      name: data.name,
      comment: data.comment,
      structuralUnit: data.structuralUnit || '',
      vacancyDomains: data.vacancyDomains.map((item) => {
        const id = vacancyDomains.find((curr) => curr.dictionaryValue.valueId === item.value)?.id;
        return { dictionaryValueId: item.value, ...(id && { id }) };
      }),
      vacancyTechnologies: data.vacancyTechnologies.map((item) => {
        const id = vacancyTechnologies.find((curr) => curr.technology.valueId === item.value)?.id;
        return { technologyId: item.value, ...(id && { id }) };
      }),
      cities: data.cities.map((item) => item.value),
      countries: data.countries.map((item) => item.value),
      hiringPurpose: data.hiringPurpose,
      ...(data.hiringPurpose === PurposeEnum.Trainee ? { published: data.published } : {}),
      vacancyLanguages: data.vacancyLanguages.reduce((accum, item) => {
        if (item.language) {
          accum.push({
            language: item.language,
            ...(item.languageLevel && { languageLevel: item.languageLevel }),
            ...(item.id && { id: item.id }),
          });
        }
        return accum;
      }, []),
      vacancyProfessions: data.vacancyProfessions.reduce((accum, item) => {
        if (item.profession) {
          accum.push({
            professionId: item.profession,
            ...(item.grade && { grade: item.grade }),
            ...(item.id && { id: item.id }),
          });
        }
        return accum;
      }, []),
    };
    await VacanciesService.updateVacancy(newData, id);
    successCallback?.();
  } catch (err) {
    return rejectWithValue({
      errorMessage: err.message,
    } as MyKnownError);
  }
});

export const getVacancyRequestsThunk = createAsyncThunk<
  { items: RequestItemResponse[]; totalPages: number },
  { id: number; page: number },
  { rejectValue: MyKnownError }
>('vacancies/getRequests', async ({ id, page }, { rejectWithValue }) => {
  try {
    const response = await RequestsService.getVacancyRequests(id, page);
    return {
      items: response.data.content.map((item) => {
        return { ...item, linkedCandidatesIsLoading: false, linkedCandidatesIds: [], linkedCandidatesItems: {} };
      }),
      totalPages: response.data.totalPages,
    };
  } catch (err) {
    return rejectWithValue({
      errorMessage: err.message,
    } as MyKnownError);
  }
});

export const getCandidateLinkedVacanciesThunk = createAsyncThunk<
  ListItemsResponse<VacancyItemResponse>,
  Parameters<typeof VacanciesService.getCandidateLinkedVacancies>[0],
  { rejectValue: MyKnownError }
>('vacancies/getLinkedToCandidate', async (data, { rejectWithValue }) => {
  try {
    const response = await VacanciesService.getCandidateLinkedVacancies(data);
    return response.data;
  } catch (err) {
    return rejectWithValue({
      errorMessage: err.message,
    } as MyKnownError);
  }
});

export const getCandidateVacanciesThunk = createAsyncThunk<
  ListItemsResponse<VacancyItemResponse>,
  Parameters<typeof VacanciesService.getCandidateVacancies>[0],
  { rejectValue: MyKnownError }
>('vacancies/getCandidateVacancies', async (args, { rejectWithValue }) => {
  try {
    const { data } = await VacanciesService.getCandidateVacancies(args);
    return data;
  } catch (err) {
    return rejectWithValue({
      errorMessage: err.message,
    } as MyKnownError);
  }
});

export const getVacanciesByIdsThunk = createAsyncThunk<
  { items: VacancyItemResponse[]; options: ReturnType<typeof mapVacancyItemsResponseToSelectsOption> },
  Parameters<typeof VacanciesService.getVacancyByIds>[0],
  { rejectValue: MyKnownError }
>('vacancies/getVacanciesByIds', async (args, { rejectWithValue }) => {
  try {
    const { data } = await VacanciesService.getVacancyByIds(args);
    return { items: data, options: mapVacancyItemsResponseToSelectsOption(data) };
  } catch (err) {
    return rejectWithValue({
      errorMessage: err.message,
    } as MyKnownError);
  }
});

export const searchVacancyOptionsThunk = createAsyncThunk<
  {
    items: VacancyItemResponse[];
    options: ReturnType<typeof mapVacancyItemsResponseToSelectsOption>;
    empty: boolean;
    totalPages: number;
  },
  Parameters<typeof VacanciesService.searchAllVacancies>[0],
  { rejectValue: MyKnownError }
>('vacancies/searchVacancyOptions', async (args, { rejectWithValue }) => {
  try {
    const { data } = await VacanciesService.searchAllVacancies(args);
    return {
      items: data.content,
      options: mapVacancyItemsResponseToSelectsOption(data.content),
      empty: data.empty,
      totalPages: data.totalPages,
    };
  } catch (err) {
    return rejectWithValue({
      errorMessage: err.message,
    } as MyKnownError);
  }
});
