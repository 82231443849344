import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { RequestPriorityEnum } from '@constants';
import { useResizeObserver, useStatisticsAPI } from '@innowise-group/core';
import * as d3 from 'd3';
import * as Styled from './request-statistic-pie-diagram.styles';
import { calculateWordDeclension } from '@innowise-group/utilities';
import { useReportColors } from '@modules';
import { useTheme } from '@innowise-group/mui-kit';

const RequestStatisticPieDiagram: React.FC = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isDesktopView = useResizeObserver(theme.breakpoints.values.lg);
  const { requestStatisticData: statistic } = useStatisticsAPI();
  const { requestStatusesColors: colors } = useReportColors();

  useEffect(() => {
    const vacancySvg = d3.select('svg.vacancy_statistic').attr('height', 100).attr('width', 100);
    const requestSvg = d3
      .select('svg.requests_statistic')
      .attr('height', isDesktopView ? 200 : 160)
      .attr('width', isDesktopView ? 200 : 160);
    const tooltip = d3.select('.requests_statistic_tooltip');

    const vacancyContainer = vacancySvg.append('g').attr('transform', `translate(50, 50)`);
    const requestContainer = requestSvg
      .append('g')
      .attr('transform', isDesktopView ? 'translate(100, 100)' : 'translate(80, 80)');

    const requestPie = d3.pie();
    const vacancyPie = d3.pie();

    const requestPieData = statistic?.data?.length
      ? requestPie.value((d) => d.claimsCount)(statistic.data)
      : requestPie.value((d) => d.claimsCount)([{ claimsCount: 1, status: '' }]);
    const vacancyPieData = statistic?.data?.length
      ? vacancyPie.value((d) => d.claimsCount)([
          { status: RequestPriorityEnum.MediumPriority, claimsCount: statistic.totalVacanciesCount },
        ])
      : vacancyPie.value((d) => d.claimsCount)([{ status: RequestPriorityEnum.MediumPriority, claimsCount: 1 }]);

    const requestSegments = isDesktopView
      ? d3.arc().innerRadius(65).outerRadius(85).padAngle(0.5).padRadius(10).cornerRadius(10)
      : d3.arc().innerRadius(58).outerRadius(75).padAngle(0.5).padRadius(12).cornerRadius(12);
    const vacancySegments = isDesktopView
      ? d3.arc().innerRadius(40).outerRadius(50).padAngle(0.5).padRadius(10).cornerRadius(10)
      : d3.arc().innerRadius(40).outerRadius(50).padAngle(0.5).padRadius(10).cornerRadius(10);

    let requestSections = requestContainer.selectAll('path').data(requestPieData, (d) => d.data.status);
    requestSections.exit().remove();
    let vacancySections = vacancyContainer.selectAll('path').data(vacancyPieData, (d) => d.data.status);
    vacancySections.exit().remove();

    const requestSectionsEnter = requestSections
      .enter()
      .append('path')
      .attr('d', requestSegments)
      .attr('fill', (d) => colors[d.data.status]);
    requestSections = requestSectionsEnter.merge(requestSections);

    const vacancySectionsEnter = vacancySections
      .enter()
      .append('path')
      .attr('d', vacancySegments)
      .attr('fill', (d) => colors[d.data.status]);
    vacancySections = vacancySectionsEnter.merge(vacancySections);

    if (statistic?.data?.length) {
      requestSections
        .on('mousemove', function (event, d) {
          tooltip
            .html(`${t(`options.requestStatusOptions_${d.data.status}`)} (${d.data.claimsCount})`)
            .style('left', event.pageX + 20 + 'px')
            .style('top', event.pageY - 20 + 'px');
        })
        .on('mouseover', function () {
          tooltip.style('opacity', 1).style('display', 'block');
        })
        .on('mouseleave', function () {
          tooltip.style('opacity', 0).style('display', 'none');
        });

      const handleMouseMove = function (this: Document, event: MouseEvent) {
        tooltip.style('left', event.pageX + 20 + 'px').style('top', event.pageY - 20 + 'px');
      };
      document.addEventListener('mousemove', handleMouseMove);
    }
    return () => {
      d3.select('svg.vacancy_statistic g').remove();
      d3.select('svg.requests_statistic g').remove();
    };
  }, [statistic, isDesktopView]);

  return (
    <Styled.StatisticContainer>
      <Styled.RequestStatisticContainer>
        <svg className="requests_statistic" />
        <Styled.Tooltip className="requests_statistic_tooltip" />
        <Styled.RequestTotalCountContainer isDesktopView={isDesktopView}>
          <Styled.RequestTotalCount>{statistic.totalClaimsCount}</Styled.RequestTotalCount>
          <span>
            {t(`pages.profile.profileDetails.${calculateWordDeclension('request', statistic.totalClaimsCount)}`)}
          </span>
        </Styled.RequestTotalCountContainer>
      </Styled.RequestStatisticContainer>
      <Styled.VacancyStatisticContainer>
        <svg className="vacancy_statistic" />
        <Styled.VacancyTotalCountContainer>
          <Styled.VacancyTotalCount>{statistic.totalVacanciesCount}</Styled.VacancyTotalCount>
          <span>
            {t(`pages.profile.profileDetails.${calculateWordDeclension('vacancy', statistic.totalVacanciesCount)}`)}
          </span>
        </Styled.VacancyTotalCountContainer>
      </Styled.VacancyStatisticContainer>
    </Styled.StatisticContainer>
  );
};

export default RequestStatisticPieDiagram;
